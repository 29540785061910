import { getRadarData } from "@/api/mapOther";
const state = {
  selected: {
    type: null, //气象类型  radar:雷达;cloud:云图;
    checked: false,
    data: null,
    change: null,
  },
};
const mutations = {
  SET_SELECTED: (state, param) => {
    setTimeout(() => {
      for (let v in state.selected) {
        if (param[v] !== undefined) state.selected[v] = param[v];
        else state.selected[v] = null;
      }
      state.selected["change"] = !state.selected["change"];
    });
  },
};
const actions = {
  setSelected(context, param) {
    context.commit("SET_SELECTED", param);
  },
  getRadarData(key, reqData) {
    return new Promise((resolve, reject) => {
      getRadarData(reqData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          console.log(key);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
