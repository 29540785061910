<template>
  <div>
    <map-base ref="mapCom" :map="map" />
  </div>
</template>
<script>
export default {
  name: "TyphoonEvt",
  props: ["map"],
  components: {},
  watch: {
    "mapTyphoon.change": {
      handler() {
        let selected = this.mapTyphoon.selected;
        switch (selected.type) {
          case -1:
            this.clearAllTyphoon();
            break;
          case 0:
            this.clearTyphoon(selected);
            break;
          case 1:
            this.addTyphoon(selected.data);
            break;
          case 2:
            this.clearTyphoon(selected);
            this.addTyphoon(selected.data);
            break;
          case 3:
            this.listShow();
            break;
          case 4:
            this.singleClick(selected.data);
            break;
          case 5:
            this.touchOnly(selected.data);
            break;
        }
      },
    },
  },
  data() {
    return {
      mapTyphoon: this.$store.getters.mapTyphoon,
      moveFeature: [], //浮动的要素
    };
  },
  mounted() {},
  methods: {
    // 缩放结束事件
    moveEndEvt() {},
    // 鼠标滑动事件
    touchOnly(data) {
      if (!this.$isMobile()) this.$refs.mapCom.closeInfoWinOver();
      this.addTyphoonInfoWin(data);
    },
    // 台风弹窗
    addTyphoonInfoWin(data) {
      let feature = data.feature;
      let layerId = data.layerId;
      let showData, title, centerX, centerY;
      if (layerId.indexOf("typhoonCenter") > -1) {
        this.$refs.mapCom.closeInfoWinOver();
        centerY =
          this.$formatDegree(parseFloat(feature.get("centerY"))) +
          (parseFloat(feature.get("centerY")) < 0 ? "S" : "N");
        centerX =
          this.$formatDegree(parseFloat(feature.get("centerX"))) +
          (parseFloat(feature.get("centerX")) < 0 ? "W" : "E");
        showData = {
          英文名称: feature.get("engName"),
          台风时间: feature.get("time"),
          中心位置: centerY + "/" + centerX,
          气压: feature.get("PA"),
          风速: feature.get("speed"),
          移动方向: feature.get("moveDirect") ? feature.get("moveDirect") : "-",
          七级: feature.get("sevenLevel"),
          十级: feature.get("tenLevel"),
          十二级: feature.get("twelveLevel"),
        };
        title = feature.get("TYPHOON_ID") + "(" + feature.get("name") + ")";
        //  信息弹窗
        this.$refs.mapCom.initInfoWinContainerOver(
          feature.getGeometry().flatCoordinates,
          [showData],
          title,
          [],
          () => {}
        );
        this.$emit("typhoonCircle", feature.getProperties());
      } else if (layerId.indexOf("typhoonForecastCenter") > -1) {
        this.$refs.mapCom.closeInfoWinOver();
        centerY =
          this.$formatDegree(parseFloat(feature.get("centerY"))) +
          (parseFloat(feature.get("centerY")) < 0 ? "S" : "N");
        centerX =
          this.$formatDegree(parseFloat(feature.get("centerX"))) +
          (parseFloat(feature.get("centerX")) < 0 ? "W" : "E");
        showData = {
          英文名称: feature.get("engName"),
          台风时间: feature.get("time"),
          预报台: feature.get("countryName"),
          中心位置: centerY + "/" + centerX,
          气压: feature.get("PA"),
          风速: feature.get("speed"),
        };
        title = feature.get("TYPHOON_ID") + "(" + feature.get("name") + ")";
        //  信息弹窗
        this.$refs.mapCom.initInfoWinContainerOver(
          feature.getGeometry().flatCoordinates,
          [showData],
          title,
          [],
          () => {}
        );
      }
    },
    // 地图单击事件
    singleClick(evt) {
      this.addTyphoonInfoWin(evt);
    },
    // 添加台风
    addTyphoon(typhoonData) {
      if (!typhoonData) return;
      this.$emit("drawTyphoon", typhoonData);
    },
    // 移除台风
    clearTyphoon(data) {
      if (!data) return;
      this.$refs.mapCom.closeInfoWinOver();
      let id = data.TYPHOON_ID ? data.TYPHOON_ID : "";
      this.$emit("clearTyphoon", id);
    },
    clearAllTyphoon() {
      this.$emit("clearAllTyphoon");
      this.$refs.mapCom.closeInfoWinOver();
    },
    listShow() {
      this.$emit("listShow");
    },
  },
  beforeDestroy() {},
};
</script>
<style scoped></style>
