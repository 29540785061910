<template>
  <div>
    <div class="green_situation">
      <el-form ref="form" :model="form">
        <el-form-item label="开始时间：">
          <el-date-picker
            v-model="startTime"
            type="datetime"
            value-format="timestamp"
            placeholder="选择开始时间"
            style="width: 100%"
            @change="setTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：">
          <el-date-picker
            v-model="endTime"
            value-format="timestamp"
            type="datetime"
            placeholder="选择结束时间"
            style="width: 100%"
            @change="setTime"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "TimeSelect",
  props: ["st", "et"],
  components: {},
  data() {
    return {
      form: {
        startTime: "",
        endTime: "",
        type: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "当天",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().toLocaleDateString());
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              // end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
              // end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              // console.log(picker);
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              // end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate = minDate.getTime();
          if (maxDate) {
            this.choiceDate = "";
          }
        },
        disabledDate: () => {
          // const self = this;
          // if (!!self.choiceDate) {
          //   const startDay =
          //     (new Date(self.choiceDate).getDate() - 1) * 24 * 3600 * 1000;
          //   const endDay =
          //     (new Date(
          //       new Date(self.choiceDate).getFullYear(),
          //       new Date(self.choiceDate).getMonth() + 1,
          //       0
          //     ).getDate() -
          //       new Date(self.choiceDate).getDate()) *
          //     24 *
          //     3600 *
          //     1000;
          //   let minTime = self.choiceDate - startDay;
          //   let maxTime = self.choiceDate + endDay;
          //   return (
          //     time.getTime() < minTime ||
          //     time.getTime() > maxTime ||
          //     time.getTime() > new Date().getTime()
          //   );
          // } else {
          //   return time.getTime() > new Date().getTime();
          // }
        },
      },
      startTime: this.st,
      endTime: this.et,
    };
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  methods: {
    setDefaultDate() {
      //当前设定的日期时间
      let d = new Date();
      let year1, month1, day1, hour1, minutes1, seconds1;
      [year1, month1, day1, hour1, minutes1, seconds1] = [
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        d.getMinutes(),
        d.getSeconds(),
      ];
      let date1 = new Date(year1, month1, day1, hour1, minutes1, seconds1);
      // this.pickerValue.push(date1);
      this.endTime = Date.parse(date1);
      //前一天设定的日期时间
      let year2, month2, day2, hour2, minutes2, seconds2;
      d.setTime(d.getTime() - 24 * 60 * 60 * 1000 * 1);
      [year2, month2, day2, hour2, minutes2, seconds2] = [
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        d.getMinutes(),
        d.getSeconds(),
      ];
      let date2 = new Date(year2, month2, day2, hour2, minutes2, seconds2);
      this.startTime = Date.parse(date2);
    },
    setTime() {
      if (this.startTime <= this.endTime) {
        this.$emit("timeSelect", {
          startTime: this.startTime,
          endTime: this.endTime,
        });
      } else {
        this.$message.error(this.$mapValue.keys.endTime_to_sartTime);
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.green_situation {
  width: 100%;
  height: 100%;
}
.history_ComPanel {
  width: 560px;
  height: auto;
}
.el-button {
  width: 5rem;
  height: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00a8ff;
}
.el-form--inline .el-form-item {
  display: flex;
  margin-right: 0px;
  vertical-align: initial;
}
.el-form-item {
  margin-bottom: 0.8rem;
  display: flex;
  ::v-deep {
    .el-form-item__label {
      font-size: 16px;
      color: #000;
      padding: 0;
    }
    .el-form-item__content {
      flex: 1;
      .el-input__inner {
        background-color: rgba(64, 158, 255, 0.2);
        font-size: 16px;
        color: #000;
        border: 0;
        padding: 0.5rem 0.8rem;
      }
      .el-input__prefix {
        display: none;
      }
    }
  }
}
.PannelContainer {
  width: auto !important;
  height: auto !important;
  z-index: 999 !important;
}
</style>
