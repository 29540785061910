<template>
  <div />
</template>
<script>
import ComMapInit from "@/components/map/infoWin/index.js";
import ComMapInitTouch from "@/components/map/infoWin/indexTouch.js";
import ComMapInit_weather from "@/components/map/infoWin/index_weather.js";
import "../infoWin/index.css";
export default {
  name: "MapCom",
  props: ["map"],
  inject: [
    "setStartMoveLis",
    "setDrawToolMoveLis",
    "setDragInfoMoveLis",
    "getStartMoveLis",
    "getDrawToolMoveLis",
    "getDragInfoMoveLis",
  ],
  data() {
    return {
      selectedFeatureArray: [], // 单击的图层
    };
  },
  mounted() {
    if (!this.$mapValue.infoWinPosition.infoNormalObj) {
      this.initInfoNormalObj();
    }
  },
  methods: {
    //--------------------------------------------------图层相关start---------------------------------------------------------------------------------------------------------------
    /**
     * 添加高亮图层
     */
    addLightMarkerLayer(geometry, image, fill, stroke, text) {
      this.removeLightMarkerLayer();
      let feature = new this.mapApi.Feature({
        geometry: geometry,
      });
      feature.setStyle(
        new this.mapApi.Style({
          image: image,
          fill: fill,
          stroke: stroke,
          text: text,
        })
      );
      // this.addLayer("lightMarkerLayer", feature);
      let baseLayerLength = parseInt(this.getbaseMapLayer().length);
      this.insertAtLayer(
        baseLayerLength,
        this.createLayer("lightMarkerLayer", feature)
      );
    },
    /**
     * 清空高亮图层
     */
    removeLightMarkerLayer() {
      this.removeLayer("lightMarkerLayer");
    },
    /**
     * 84坐标转火星坐标
     */
    wgs84togcj02(wgs_lon, wgs_lat) {
      let ee = 0.00669342162296594323,
        a = 6378245.0;
      const transformlon = (lon, lat) => {
        var ret =
          300.0 +
          lon +
          2.0 * lat +
          0.1 * lon * lon +
          0.1 * lon * lat +
          0.1 * Math.sqrt(Math.abs(lon));
        ret +=
          ((20.0 * Math.sin(6.0 * lon * Math.PI) +
            20.0 * Math.sin(2.0 * lon * Math.PI)) *
            2.0) /
          3.0;
        ret +=
          ((20.0 * Math.sin(lon * Math.PI) +
            40.0 * Math.sin((lon / 3.0) * Math.PI)) *
            2.0) /
          3.0;
        ret +=
          ((150.0 * Math.sin((lon / 12.0) * Math.PI) +
            300.0 * Math.sin((lon / 30.0) * Math.PI)) *
            2.0) /
          3.0;
        return ret;
      };

      const transformlat = (lon, lat) => {
        var ret =
          -100.0 +
          2.0 * lon +
          3.0 * lat +
          0.2 * lat * lat +
          0.1 * lon * lat +
          0.2 * Math.sqrt(Math.abs(lon));
        ret +=
          ((20.0 * Math.sin(6.0 * lon * Math.PI) +
            20.0 * Math.sin(2.0 * lon * Math.PI)) *
            2.0) /
          3.0;
        ret +=
          ((20.0 * Math.sin(lat * Math.PI) +
            40.0 * Math.sin((lat / 3.0) * Math.PI)) *
            2.0) /
          3.0;
        ret +=
          ((160.0 * Math.sin((lat / 12.0) * Math.PI) +
            320 * Math.sin((lat * Math.PI) / 30.0)) *
            2.0) /
          3.0;
        return ret;
      };
      let dwgs_lon = transformlon(wgs_lon - 105.0, wgs_lat - 35.0);
      let dwgs_lat = transformlat(wgs_lon - 105.0, wgs_lat - 35.0);
      let radwgs_lat = (wgs_lat / 180.0) * Math.PI;
      let magic = Math.sin(radwgs_lat);
      magic = 1 - ee * magic * magic;
      let sqrtmagic = Math.sqrt(magic);
      dwgs_lon =
        (dwgs_lon * 180.0) / ((a / sqrtmagic) * Math.cos(radwgs_lat) * Math.PI);
      dwgs_lat =
        (dwgs_lat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * Math.PI);
      let gcj_lon = parseFloat(wgs_lon) + dwgs_lon;
      let gcj_lat = parseFloat(wgs_lat) + dwgs_lat;
      let gcj = [gcj_lon, gcj_lat];
      return gcj;
    },
    /***
     * 获取feature
     */
    getFeature(data) {
      // let textColor = null;
      // switch (status) {
      //   case "selected":
      //     textColor = "#FF9000";
      //     break;
      //   default:
      //     textColor = "#00dd53";
      //     break;
      // }
      // let pos = this.wgs84togcj02(data.longitude, data.latitude);
      let pos = [data.longitude, data.latitude];
      let feature = new this.mapApi.Feature({
        geometry: new this.mapApi.Point(pos),
      });
      // if (!data.icon) {
      // }
      let image = data.icon
        ? new this.mapApi.Icon({
            // anchor: [0.5, 0.5],
            src: data.icon,
            scale: data.scale ? data.scale : 1,
            rotation: this.getRadius(data.angle),
            // anchorYUnits: 'pixels'
          })
        : new this.mapApi.Circle({
            radius: 8,
            fill: new this.mapApi.Fill({
              color: data.fillColor,
            }),
            stroke: new this.mapApi.Stroke({
              color: data.strokeColor,
              size: 1,
            }),
          });
      feature.setStyle(
        new this.mapApi.Style({
          image: image,
          // text: new this.mapApi.Text({
          //   font: "bold 14px 微软雅黑",
          //   offsetX: 0,
          //   offsetY: 50,
          //   scale: 1,
          //   text: data.text,
          //   fill: new this.mapApi.Fill({ color: textColor }),
          //   textBaseline: "bottom",
          //   stroke: new this.mapApi.Stroke({
          //     // 线样式
          //     color: "#000",
          //     width: 2
          //   })
          // })
          text: new this.mapApi.Text({
            font: "bold 12px sans-serif",
            offsetX: 20,
            // offsetY: 0,
            rotation: this.getRadius(360),
            textAlign: "left",
            placement: "point",
            maxAngle: Math.PI / 4,
            overflow: true,
            scale: 1,
            text: data.text,
            fill: new this.mapApi.Fill({ color: "blue" }),
            stroke: new this.mapApi.Stroke({
              // 线样式
              color: "#fff",
              width: 1,
            }),
          }),
        })
      );
      feature.setProperties(data);
      return feature;
    },
    /**
     * 移除单图层
     */
    removeLayer(layerId, isFullLayer) {
      let array = this.map.getLayers().array_.map((v) => {
        return v;
      });
      array.forEach((layer) => {
        if (isFullLayer) {
          if (layer && layer.get("id") && layer.get("id") === layerId) {
            if (layer.getSource) layer.getSource().clear();
            this.map.removeLayer(layer);
          }
        } else {
          if (
            layer &&
            layer.get("id") &&
            layer.get("id").indexOf(layerId) > -1 &&
            layer.get("id").indexOf(this.$mapValue.layer[6].layerId) === -1 &&
            layer.get("id").indexOf(this.$mapValue.layer[7].layerId) === -1
          ) {
            if (layer.getSource) layer.getSource().clear();
            this.map.removeLayer(layer);
          }
        }
      });
    },
    /**
     * 获取图层(判断图层是否存在)
     */
    getLayer(layerId, isFullLayer) {
      let layer = null;
      this.map.getLayers().array_.map((v) => {
        if (isFullLayer) {
          if (v && v.get("id") && v.get("id") === layerId) {
            layer = v;
          }
        } else {
          if (v && v.get("id") && v.get("id").indexOf(layerId) > -1) {
            layer = v;
          }
        }
      });
      return layer;
    },
    /**
     * 获取基础底图图层
     */
    getbaseMapLayer() {
      let layerArray = [];
      this.map.getLayers().array_.map((v) => {
        if (v.get("type") === "baseMap") {
          layerArray.push(v);
        }
      });
      return layerArray;
    },
    /**
     * 获取所有矢量图层
     */
    getAllVectorLayer() {
      let layerArray = [];
      this.map.getLayers().array_.map((v) => {
        if (
          v.get("id") &&
          (!v.get("type") ||
            (v.get("type") !== "tileLayer" && v.get("type") !== "baseMap"))
        ) {
          layerArray.push(v);
        }
      });
      return layerArray;
    },
    /**
     * 获取所有服务图层
     */
    getAllServiceLayer() {
      let layerArray = [];
      this.map.getLayers().array_.map((v) => {
        if (
          v.get("id") &&
          (!v.get("type") ||
            (v.get("type") === "tileLayer" && v.get("type") !== "baseMap"))
        ) {
          layerArray.push(v);
        }
      });
      return layerArray;
    },
    /**
     * 获取所有瓦片图层
     */
    getAllTileLayer() {
      let layerArray = [];
      this.map.getLayers().array_.map((v) => {
        if (
          v.get("id") &&
          (!v.get("type") ||
            v.get("type") === "tileLayer" ||
            v.get("type") === "baseMap")
        ) {
          layerArray.push(v);
        }
      });
      return layerArray;
    },
    /**
     * 添加图层
     */
    addLayer(layerId, feature) {
      let source = new this.mapApi.VectorSource();
      let layer = new this.mapApi.VectorLayer({
        id: layerId,
        name: layerId,
        source: source,
        visible: true,
        type: layerId,
      });
      if (feature) source.addFeatures(feature);
      this.map.addLayer(layer);
    },
    /**
     * 创建图层
     */
    createLayer(layerId, feature, layerType) {
      let source = new this.mapApi.VectorSource();
      let layer = new this.mapApi.VectorLayer({
        id: layerId,
        name: layerId,
        source: source,
        visible: true,
        type: layerType,
      });
      if (feature) source.addFeatures(feature);
      return layer;
    },
    /**
     * 指定位置添加图层
     */
    insertAtLayer(index, layer) {
      let layersArray = this.map.getLayers();
      layersArray.insertAt(index, layer);
    },
    /**
     * 添加地图dom对象
     */
    addOverlay(overlayId, position, html, positioning, offset) {
      let container = document.createElement("div");
      container.className = overlayId;
      container.innerHTML = html;
      container.style.color = "#124866";
      container.style.fontWeight = "bold";
      let markerOverlay = new this.mapApi.Overlay({
        id: overlayId,
        element: container,
        // autoPan: true,
        // offset: [0, 0],
        offset: offset ? offset : [0, 0],
        // autoPanAnimation: {
        //   duration: 250,
        // },
        position: position,
        positioning: positioning,
      });
      this.map.addOverlay(markerOverlay);
      return markerOverlay;
    },
    /**
     * 清空所有图层
     */
    removeAllLayer() {
      //清空图层
      let array = this.map.getLayers().array_.map((v) => {
        return v;
      });
      array.forEach((layer) => {
        this.map.removeLayer(layer);
      });
    },
    /**
     * 清空所有底图
     */
    removeAllBaseLayer() {
      //清空图层
      let array = this.map.getLayers().array_.map((v) => {
        return v;
      });
      array.forEach((layer) => {
        if (layer.get("type") === "baseMap") {
          this.map.removeLayer(layer);
        }
      });
    },
    /**
     * 清空所有矢量图层
     */
    removeAllVectorLayer() {
      //清空图层
      let array = this.map.getLayers().array_.map((v) => {
        return v;
      });
      array.forEach((layer) => {
        if (
          !layer.get("type") ||
          (layer.get("type") !== "tileLayer" && layer.get("type") !== "baseMap")
        ) {
          this.map.removeLayer(layer);
        }
      });
    },
    /**
     * 清空所有服务图层
     */
    removeAllServiceLayer() {
      let array = this.map.getLayers().array_.map((v) => {
        return v;
      });
      array.forEach((layer) => {
        if (layer.get("type") === "tileLayer") {
          this.map.removeLayer(layer);
        }
      });
    },
    /**
     * 清空所有瓦片图层
     */
    removeAllTiledLayer() {
      let array = this.map.getLayers().array_.map((v) => {
        return v;
      });
      array.forEach((layer) => {
        if (
          layer.get("type") === "tileLayer" ||
          layer.get("type") === "baseMap"
        ) {
          this.map.removeLayer(layer);
        }
      });
    },
    /**
     * 移除单地图dom对象
     */
    removeOverlay(overlayId) {
      let array = this.map.getOverlays().array_.map((v) => {
        return v;
      });
      array.forEach((overlay) => {
        if (overlay.getId() && overlay.getId().indexOf(overlayId) > -1) {
          this.map.removeOverlay(overlay);
        }
      });
    },
    /**
     * 移除所有地图dom对象
     */
    removeAllOverlay() {
      let array = this.map.getOverlays().array_.map((v) => {
        return v;
      });
      array.forEach((overlay) => {
        if (overlay.getId().indexOf("measureTool") === -1)
          this.map.removeOverlay(overlay);
      });
    },
    /**
     * 获取overlay
     */
    getOverlay(overlayId) {
      let array = this.map.getOverlays().array_.map((v) => {
        return v;
      });
      let overl = null;
      array.forEach((overlay) => {
        if (overlay.getId() && overlay.getId() === overlayId) {
          overl = overlay;
        }
      });
      return overl;
    },
    //--------------------------------------------------图层相关end----------------------------------------------------------------------------------------------------------------------
    //--------------------------------------------------信息弹窗start---------------------------------------------------------------------------------------------------------------
    addDrag(pos, dragId, dragCollapse, dragEle, dragEleTar, initPixel) {
      let left,
        top,
        pixel,
        newPos,
        newPixel,
        clientPixel,
        ele,
        layer,
        refreshPos;
      // collapseStatus;
      const setNewLine = () => {
        if (!newPixel) return;
        pixel = this.map.getPixelFromCoordinate(pos); //动态获取最新坐标对应的屏幕位置
        newPos = this.map.getCoordinateFromPixel(newPixel);
        let line = new this.mapApi.LineString([pos, newPos]);
        if (refreshPos) line = new this.mapApi.LineString([refreshPos, newPos]);
        addLayer(line);

        //地图刷新时隐藏（减少内存消耗，不卡顿）
        dragEle.style.display = "none";
        //地图刷新时实时更新位置（实时位置，但数量过多会卡顿）
        // dragEle.style.left = newPixel[0] - pixel[0] + "px";
        // dragEle.style.top = newPixel[1] - pixel[1] + "px";
      };
      const prEvt = () => {
        setNewLine();
      };
      const meEvt = () => {
        this.map.un("postrender", prEvt);
        setNewLine();
        //显示并更新位置
        if (!newPixel) return;
        dragEle.style.display = "block";
        dragEle.style.left = newPixel[0] - pixel[0] + "px";
        dragEle.style.top = newPixel[1] - pixel[1] + "px";
        dragEle.setAttribute("pixel", newPixel);
        // if (!collapseStatus) dragCollapse(1);
      };
      const msEvt = () => {
        this.map.on("postrender", prEvt);
        if (
          !this.getLayer(dragId, 1) ||
          !dragEle ||
          (dragEle && !dragEle.style)
        ) {
          this.map.un("postrender", prEvt);
          this.map.un("moveend", meEvt);
          this.map.un("movestart", msEvt);
          dragEle.remove();
          return;
        }
      };
      const getClientPixel = (event) => {
        let clientX = event.clientX
          ? event.clientX
          : event.touches && event.touches[0].clientX
          ? event.touches && event.touches[0].clientX
          : event.changedTouches[0].clientX;
        let clientY = event.clientY
          ? event.clientY
          : event.touches && event.touches[0].clientY
          ? event.touches && event.touches[0].clientY
          : event.changedTouches[0].clientY;
        return [clientX, clientY];
      };
      const addDragMapEvent = () => {
        this.map.un("postrender", prEvt);
        this.map.un("moveend", meEvt);
        this.map.un("movestart", msEvt);

        this.map.on("moveend", meEvt);
        this.map.on("movestart", msEvt);
      };
      const removeEvent = () => {
        this.map.un("postrender", prEvt);
        this.map.un("moveend", meEvt);
        this.map.un("movestart", msEvt);
        dragEle.remove();
      };
      const removeDragPixel = () => {
        let pixel = dragEle.getAttribute("pixel");
        if (pixel) this.setInfoWinNormalPos(pixel);
      };
      const changeBackground = (backgroundColor) => {
        if (dragEleTar)
          dragEleTar.parentNode.style.backgroundColor = backgroundColor;
        else if (dragEle)
          dragEleTar.children[0].style.backgroundColor = backgroundColor;
      };
      const initEvt = () => {
        // ele = dragEleTar ? dragEleTar : dragEle;
        ele = dragEle;
        if (
          !this.getLayer(dragId, 1) ||
          !dragEle ||
          (dragEle && !dragEle.style)
        ) {
          this.map.un("postrender", prEvt);
          this.map.un("moveend", meEvt);
          this.map.un("movestart", msEvt);
          dragEle.remove();
          return;
        }
        pixel = this.map.getPixelFromCoordinate(pos); //动态获取最新坐标对应的屏幕位置
        dragEle.parentNode.style.zIndex = document.querySelectorAll(
          ".map_info_container"
        ).length;
        dragEle.style.cursor = "pointer";

        dragEle.setStartPos = (position) => {
          refreshPos = position;
        };

        if (initPixel) {
          newPixel = initPixel;
          dragEle.setAttribute("pixel", newPixel);
          addDragMapEvent();
        }

        if (this.$isMobile()) {
          ele.ontouchstart = (event) => {
            // collapseStatus = dragCollapse(3);
            // if (collapseStatus) dragCollapse(1);

            let cp = getClientPixel(event);
            left = cp[0] - dragEle.offsetLeft;
            top = cp[1] - dragEle.offsetTop;
            pixel = this.map.getPixelFromCoordinate(pos); //动态获取最新坐标对应的屏幕位置

            document.ontouchmove = (event) => {
              addDragMapEvent();
              this.setDragInfoMoveLis(false);
              clientPixel = getClientPixel(event);
              var x = clientPixel[0];
              var y = clientPixel[1];
              // 弹窗位置
              dragEle.style.left = x - left + "px";
              dragEle.style.top = y - top + "px";
              dragEle.style.cursor = "move";

              newPos = this.map.getCoordinateFromPixel([
                pixel[0] - (x - left),
                pixel[1] - (y - top),
              ]);
              newPos = [pos[0] * 2 - newPos[0], pos[1] * 2 - newPos[1]];
              newPixel = this.map.getPixelFromCoordinate(newPos);
              let line = new this.mapApi.LineString([pos, newPos]);
              if (refreshPos)
                line = new this.mapApi.LineString([refreshPos, newPos]);
              addLayer(line);
            };
          };
          ele.ontouchend = () => {
            this.setDragInfoMoveLis(true);
            document.ontouchmove = null;
            dragEle.style.cursor = "pointer";

            // if (collapseStatus) dragCollapse(2);
            removeDragPixel();
          };
          this.map.getViewport().onmouseleave = () => {
            this.setDragInfoMoveLis(true);
            document.onmousemove = null;
            if (dragEle) dragEle.style.cursor = "pointer";
          };
        } else {
          ele.onmousedown = (event) => {
            addDragMapEvent();
            // collapseStatus = dragCollapse(3);

            let cp = getClientPixel(event);
            left = cp[0] - dragEle.offsetLeft;
            top = cp[1] - dragEle.offsetTop;
            pixel = this.map.getPixelFromCoordinate(pos); //动态获取最新坐标对应的屏幕位置

            document.onmousemove = (event) => {
              this.setDragInfoMoveLis(false);
              changeBackground("#FFEE79");
              clientPixel = getClientPixel(event);
              var x = clientPixel[0];
              var y = clientPixel[1];
              // 弹窗位置
              dragEle.style.left = x - left + "px";
              dragEle.style.top = y - top + "px";
              dragEle.style.cursor = "move";

              newPos = this.map.getCoordinateFromPixel([
                pixel[0] - (x - left),
                pixel[1] - (y - top),
              ]);
              newPos = [pos[0] * 2 - newPos[0], pos[1] * 2 - newPos[1]];
              newPixel = this.map.getPixelFromCoordinate(newPos);
              let line = new this.mapApi.LineString([pos, newPos]);
              if (refreshPos)
                line = new this.mapApi.LineString([refreshPos, newPos]);
              addLayer(line);
            };
          };
          ele.onmouseup = () => {
            this.setDragInfoMoveLis(true);
            changeBackground("#EDEDED");
            document.onmousemove = null;
            dragEle.style.cursor = "pointer";
            // if (collapseStatus) dragCollapse(2);
            removeDragPixel();
          };
          this.map.getViewport().onmouseleave = () => {
            this.setDragInfoMoveLis(true);
            document.onmousemove = null;
            if (dragEle) dragEle.style.cursor = "pointer";
          };
        }
      };
      const addLayer = (geo) => {
        if (layer) {
          layer.getSource().clear();
          this.removeLayer(dragId, 1);
        }
        layer = this.createLayer(dragId);
        this.insertAtLayer(9999, layer);

        if (geo) {
          let feature = new this.mapApi.Feature({ geometry: geo });
          feature.setStyle(
            new this.mapApi.Style({
              stroke: new this.mapApi.Stroke({
                color: "red",
                width: "1",
                lineDash: [1, 5, 7],
              }),
            })
          );
          layer.getSource().addFeature(feature);
        }
      };
      addLayer();
      initEvt();
      dragEle.removeEvt = removeEvent;
      dragEle.removeDragPixel = removeDragPixel;
    },
    initInfoNormalObj() {
      this.$mapValue.infoWinPosition.infoNormalObj = [];
      const countY = this.$mapValue.infoWinPosition.countY;
      const countX = this.$mapValue.infoWinPosition.countX;
      for (let m = 0, x = 0, y = 0; m < countY * countX; m++) {
        y = m + 1 - x * countY;
        this.$mapValue.infoWinPosition.infoNormalObj.push([x, y]);
        if (Math.floor((m + 1) / countY) !== x) x++;
      }
      // console.log("标准infoWin", this.$mapValue.infoWinPosition.infoNormalObj);
    },
    setInfoWinNormalPos(initPixel) {
      if (this.$isMobile()) return null;
      let obj = this.$mapValue.infoWinPosition.infoNormalObj;
      const width = this.$mapValue.infoWinPosition.width;
      const height = this.$mapValue.infoWinPosition.height;
      const right = this.$mapValue.infoWinPosition.pixel[2];
      const left = this.$mapValue.infoWinPosition.pixel[1];
      const countX = this.$mapValue.infoWinPosition.countX;

      if (!initPixel) {
        if (obj.length > 0) {
          const x = obj[0][0];
          const y = obj[0][1];
          let pixelX, pixelY;
          //  initGrid
          if (x === 0) pixelX = right[0];
          else if (x === countX - 1) pixelX = left[0];
          else pixelX = right[0] - x * width;
          pixelY = y * height;
          initPixel = [pixelX, pixelY];
          // initGrid = [x, y];

          obj.splice(0, 1);
          obj.sort();

          // console.log("分辨率,格网点,格网总", initPixel, initGrid, obj);
        } else {
          this.$message.success(this.$mapValue.keys.maxInfoCount);
        }
        return initPixel;
      } else {
        initPixel = initPixel.split(",");
        let x, initGrid;
        const y = Number(initPixel[1]) / height;
        if (Number(initPixel[0]) === right[0]) x = 0;
        else if (Number(initPixel[0]) === left[0]) x = countX - 1;
        else x = (right[0] - Number(initPixel[0])) / width;
        initGrid = [x, y];

        if (
          initGrid[0].toString().indexOf(".") === -1 &&
          initGrid[1].toString().indexOf(".") === -1 &&
          !obj.find((v) => {
            return v[0] === initGrid[0] && v[1] === initGrid[1];
          })
        ) {
          obj.push(initGrid);
          obj.sort();
        }

        // console.log("分辨率,格网点,格网总", initPixel, initGrid, obj);
        return initPixel;
      }
    },
    /**
     * 信息弹窗
     * position 位置
     * data 要素属性（data.get("字段名称")）
     * title 标题
     * buttonOption 自定义事件按钮
     * callback 自定义事件回调
     * moduleId 模块Id
     * type 是否参与弹窗数目限制以及控制弹窗收缩 1：不参与数目限制并且不收缩按钮 2：不参与并收缩按钮  3：参与并收缩按钮 其余:参与并不收缩;
     */
    initInfoWinContainer(
      position,
      data,
      title,
      buttonOption,
      callback,
      moduleId
      // type
    ) {
      let dragPre = this.$mapValue.layer[7].layerId;
      let uniqueId =
        data instanceof Array && data[0]["dragId"] ? data[0]["dragId"] : "";
      uniqueId = uniqueId.replace(/ +/g, "");
      let dragId = dragPre + (moduleId ? moduleId : "moduleId") + uniqueId;

      if (position && this.isValue(position[0]) && this.isValue(position[1])) {
        if (document.querySelector("." + dragId)) this.closeInfoWin(dragId);

        //限制最大边界显示数目
        let initPixel = "";
        // if (!type || type === 3) initPixel = this.setInfoWinNormalPos();

        this.addOverlay(
          dragPre + " " + dragId,
          position,
          ComMapInit.CreateInfoWindow(
            data,
            title,
            buttonOption,
            dragId,
            (item, element) => {
              callback(item, element);
            }
          ),
          "center-center"
        );
        let interval = setInterval(() => {
          let ele = document.querySelector("." + dragId);
          if (ele) {
            let eleTar = ele.querySelector(".map_headdiv");
            let collapseEle = ele.querySelector(".collapseEle");
            const dragCollapse = (status) => {
              if (!status) {
                if (
                  collapseEle.style.display === "block" ||
                  !collapseEle.style.display
                )
                  collapseEle.style.display = "none";
                else collapseEle.style.display = "block";
              } else if (status === 1) collapseEle.style.display = "none";
              else if (status === 2) collapseEle.style.display = "block";
              else if (status === 3) {
                if (
                  collapseEle.style.display === "block" ||
                  !collapseEle.style.display
                )
                  return 1;
                else return 0;
              }
              //标题修改
              if (data[0].aliasTitle) {
                if (collapseEle.style.display === "block") {
                  eleTar.querySelector(".map_headtitle").innerHTML = title;
                } else {
                  eleTar.querySelector(".map_headtitle").innerHTML =
                    data[0].aliasTitle;
                }
              }
            };
            // if (type === 2 || type === 3) dragCollapse(false);
            clearInterval(interval);
            this.addDrag(
              position,
              dragId,
              dragCollapse,
              ele,
              eleTar,
              initPixel
            );
            document
              .querySelector(".closeInfoWin" + dragId)
              .addEventListener("click", () => {
                this.closeInfoWin(dragId);
                this.removeLightMarkerLayer();
                this.removeLayer(dragId, 1);
              });
            document
              .querySelector(".collapseInfoWin" + dragId)
              .addEventListener("click", () => {
                dragCollapse();
              });
          }
        });
      }
    },
    initInfoWinContainerRefresh(
      position,
      data,
      title,
      buttonOption,
      callback,
      moduleId
      // type
    ) {
      let dragPre = this.$mapValue.layer[7].layerId;
      let uniqueId =
        data instanceof Array && data[0]["dragId"] ? data[0]["dragId"] : "";
      uniqueId = uniqueId.replace(/ +/g, "");
      let dragId = dragPre + (moduleId ? moduleId : "moduleId") + uniqueId;

      if (position && this.isValue(position[0]) && this.isValue(position[1])) {
        let dragEle = document.querySelector("." + dragId);
        const setEvent = (eleTar, collapseEle) => {
          const dragCollapse = (status) => {
            if (!status) {
              if (
                collapseEle.style.display === "block" ||
                !collapseEle.style.display
              )
                collapseEle.style.display = "none";
              else collapseEle.style.display = "block";
            } else if (status === 1) collapseEle.style.display = "none";
            else if (status === 2) collapseEle.style.display = "block";
            else if (status === 3) {
              if (
                collapseEle.style.display === "block" ||
                !collapseEle.style.display
              )
                return 1;
              else return 0;
            }
            //标题修改
            if (data[0].aliasTitle) {
              if (collapseEle.style.display === "block") {
                eleTar.querySelector(".map_headtitle").innerHTML = title;
              } else {
                eleTar.querySelector(".map_headtitle").innerHTML =
                  data[0].aliasTitle;
              }
            }
          };
          document
            .querySelector(".closeInfoWin" + dragId)
            .addEventListener("click", () => {
              this.closeInfoWin(dragId);
              this.removeLightMarkerLayer();
              this.removeLayer(dragId, 1);
            });
          document
            .querySelector(".collapseInfoWin" + dragId)
            .addEventListener("click", () => {
              dragCollapse();
            });
          return {
            dragCollapse: dragCollapse,
          };
        };
        if (dragEle) {
          let overlayId = dragPre + " " + dragId;
          let overlay = this.getOverlay(overlayId);
          let html = ComMapInit.CreateInfoWindow(
            data,
            title,
            buttonOption,
            dragId,
            (item, element) => {
              callback(item, element);
            }
          );
          overlay.getElement().innerHTML = html;
          if (this.getLayer(dragId, 1)) {
            let feature = this.getLayer(dragId, 1).getSource().getFeatures();
            if (feature.length > 0) {
              dragEle.setStartPos(position);
              feature = feature[0];
              let coords = feature.getGeometry().getCoordinates();
              let endPosition = coords[1];
              feature.getGeometry().setCoordinates([position, endPosition]);
            }
          }
          let eleTar = dragEle.querySelector(".map_headdiv");
          let collapseEle = dragEle.querySelector(".collapseEle");
          setEvent(eleTar, collapseEle);
          // return;
        } else {
          this.addOverlay(
            dragPre + " " + dragId,
            position,
            ComMapInit.CreateInfoWindow(
              data,
              title,
              buttonOption,
              dragId,
              (item, element) => {
                callback(item, element);
              }
            ),
            "center-center"
          );
          let interval = setInterval(() => {
            let ele = document.querySelector("." + dragId);
            if (ele) {
              let eleTar = ele.querySelector(".map_headdiv");
              let collapseEle = ele.querySelector(".collapseEle");
              clearInterval(interval);
              let event = setEvent(eleTar, collapseEle);
              //限制最大边界显示数目
              // let initPixel = "";
              // if (!type || type === 3) initPixel = this.setInfoWinNormalPos();
              let initPixel = this.map.getPixelFromCoordinate(position);
              this.addDrag(
                position,
                dragId,
                event.dragCollapse,
                ele,
                eleTar,
                initPixel
              );
              setEvent(eleTar);
            }
          });
        }
      }
    },
    initInfoWinContainerOver(
      position,
      data,
      title,
      buttonOption,
      callback,
      moduleId
    ) {
      // //悬浮去重信息判断（同信息不参与悬浮显示）
      // let dragPreEqual = this.$mapValue.layer[7].layerId;
      // let uniqueIdEqual =
      //   data instanceof Array && data[0]["dragId"] ? data[0]["dragId"] : "";
      // uniqueId = uniqueId.replace(/ +/g, "");
      // let dragIdEqual =
      //   dragPreEqual + (moduleId ? moduleId : "moduleId") + uniqueIdEqual;
      // if (document.querySelector("." + dragIdEqual)) return;

      let dragPre = this.$mapValue.layer[7].layerId + "_over";
      let uniqueId =
        data instanceof Array && data[0]["dragId"] ? data[0]["dragId"] : "";
      uniqueId = uniqueId.replace(/ +/g, "");
      let dragId = dragPre + (moduleId ? moduleId : "moduleId") + uniqueId;
      // let dragId = dragPre + this.getNormalUniqueId().uniqueId;

      if (position && this.isValue(position[0]) && this.isValue(position[1])) {
        this.addOverlay(
          dragPre + " " + dragId + " " + "touchOnlyInfoWin",
          position,
          ComMapInitTouch.CreateInfoWindow(
            data,
            title,
            buttonOption,
            dragId,
            (element) => {
              callback(buttonOption, element);
            }
          ),
          "center-center",
          [10, 10]
        );
        let interval = setInterval(() => {
          let ele = document.querySelector("." + dragId);
          if (ele) {
            clearInterval(interval);
            document
              .querySelector(".closeInfoWin" + dragId)
              .addEventListener("click", () => {
                this.closeInfoWinOver();
              });
          }
        });
      }
    },
    addSeaWeatherInfo(position, data, title, callback) {
      let dragPre = this.$mapValue.layer[6].layerId;
      let uniqueId = "";
      let dragId = dragPre + uniqueId;
      this.removeSeaWeatherInfo();
      this.addOverlay(
        dragId,
        position,
        ComMapInit_weather.CreateInfoWindow(
          data,
          title,
          dragId,
          (item, element) => {
            callback(item, element);
          }
        ),
        "center-center"
      );
      let interval = setInterval(() => {
        let ele = document.querySelector("." + dragId);
        let eleTar = ele.querySelector(".map_headdiv");
        if (ele) {
          let collapseEle = ele.querySelector(".collapseEle");
          const dragCollapse = (status) => {
            if (!status) {
              if (
                collapseEle.style.display === "block" ||
                !collapseEle.style.display
              )
                collapseEle.style.display = "none";
              else collapseEle.style.display = "block";
            } else if (status === 1) collapseEle.style.display = "none";
            else if (status === 2) collapseEle.style.display = "block";
            else if (status === 3) {
              if (
                collapseEle.style.display === "block" ||
                !collapseEle.style.display
              )
                return 1;
              else return 0;
            }
          };
          clearInterval(interval);
          this.addDrag(position, dragId, dragCollapse, ele, eleTar);
          document
            .querySelector(".closeInfoWin" + dragId)
            .addEventListener("click", () => {
              this.removeSeaWeatherInfo();
            });
          document
            .querySelector(".weatherChart")
            .addEventListener("click", (e) => {
              callback(e);
            });
          document
            .querySelector(".collapseInfoWin" + dragId)
            .addEventListener("click", () => {
              dragCollapse();
            });
        }
      });
    },
    removeSeaWeatherInfo() {
      let dragId = this.$mapValue.layer[6].layerId;
      if (document.querySelector("." + dragId))
        document.querySelector("." + dragId).remove();
      this.removeLayer(dragId, 1);
    },
    /**
     * 添加闪烁标识
     */
    addFlashMarker(coordinate) {
      this.removeFlashMarker();
      setTimeout(() => {
        let html =
          "<img src=" +
          require("@/assets/image/map/flash.gif") +
          " style='position:absolute;left:-20px;top:-20px'>";
        this.addOverlay("flashMarker", coordinate, html, "center-center");
      });
    },
    /**
     * 移除闪烁标识
     */
    removeFlashMarker() {
      this.removeOverlay("flashMarker");
    },
    /**
     * 关闭弹窗
     */
    closeInfoWin(id, pageType) {
      let dragPre = this.$mapValue.layer[7].layerId;
      if (id) {
        const aliasId = dragPre + id;
        //按照元素id
        let ele = document.querySelector("." + id);
        let eleAlias = document.querySelector("." + aliasId);
        if (ele) {
          this.removeLayer(id, 1);
          if (ele && ele.removeEvt) ele.removeEvt();
          if (ele && ele.removeDragPixel) ele.removeDragPixel();
          if (ele && ele.remove) ele.remove();
          this.removeFlashMarker();
        } else if (eleAlias) {
          this.removeLayer(aliasId, 1);
          if (eleAlias && eleAlias.removeEvt) eleAlias.removeEvt();
          if (eleAlias && eleAlias.removeDragPixel) eleAlias.removeDragPixel();
          if (eleAlias && eleAlias.remove) eleAlias.remove();
          this.removeFlashMarker();
        }
      } else if (pageType) {
        //按照元素所在图层
        let ele = document.querySelectorAll("." + dragPre);
        if (ele) {
          this.removeFlashMarker();
          for (let i = ele.length - 1; i >= 0; i--) {
            if (
              ele[i].className.indexOf(pageType) > -1 &&
              !this.getLayer(pageType, 1)
            ) {
              this.removeLayer(ele[i].className.split(" ")[1], 1);
              if (ele[i].removeEvt) ele[i].removeEvt();
              if (ele[i].removeDragPixel) ele[i].removeDragPixel();
              if (ele[i] && ele[i].remove) ele[i].remove();
            }
          }
        }
      } else {
        //所有图层信息窗口
        let ele = document.querySelectorAll("." + dragPre);
        if (ele) {
          this.removeFlashMarker();
          for (let i = ele.length - 1; i >= 0; i--) {
            this.removeLayer(ele[i].className.split(" ")[1], 1);
            if (ele[i].remove) ele[i].remove();
          }
        }
      }
    },
    closeInfoWinOver() {
      let dragPre = this.$mapValue.layer[7].layerId + "_over";
      let ele = document.querySelector("." + dragPre);
      if (ele) {
        this.removeFlashMarker();
        if (ele.remove) ele.remove();
      }
    },
    //--------------------------------------------------信息弹窗end----------------------------------------------------------------------------------------------------------------------
    //--------------------------------------------------地图事件封装交互start----------------------------------------------------------------------------------------------------------------------
    /**
     * 地图单击事件
     * @param {*} evt 事件
     * @param {*} callback 回调函数
     */
    doIdentify(evt, callback) {
      if (evt.frameState) {
        // const features = this.map.getFeaturesAtPixel(evt.pixel)
        // console.log(1111,features)
        // 通过地图对象方式获取要素（存在问题是要素点位置相同时会获取当前位置点的所有要素,最后一个遍历的是最底层要素）
        this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
          // 通过此方式获取最底层要素以及对应图层
          if (this.selectedFeatureArray.length === 0) {
            this.selectedFeatureArray.push(layer);
            // console.log("单击的要素", feature);
            // console.log("单击的图层", layer.get("id"));
            // console.log("地图方式获取单击的要素");
          }
        });
        // 纯地图单击执行操作,与要素无关
        if (this.selectedFeatureArray.length == 0) {
          // console.log("地图单击执行操作");
          callback("mapClick", { coordinate: evt.coordinate });
        }
      }
      // 纯要素单击（存在问题是要素点位置相同时默认获取最底层要素）
      else {
        if (evt.selected[0].length !== 0) {
          // 阻止单击位置要素点自动渲染
          this.$parent.selectSingleClick.getFeatures().clear();
          let feature = evt.selected[0];
          let layer = this.selectedFeatureArray[0];
          if (!layer) return;
          let layerId = layer.get("id");
          callback("featureClick", {
            feature: feature,
            layer: layer,
            layerId: layerId,
          });
          this.selectedFeatureArray = [];
        }
      }
    },
    //--------------------------------------------------地图事件封装交互end----------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------地图工具类start---------------------------------------------------------------------------------------------------------------------
    /**
     * 自适应范围
     */
    getExtent(ex, expand) {
      let extent = [
        ex[0] - expand,
        ex[1] - expand,
        ex[2] + expand,
        ex[3] + expand,
      ];
      return extent;
    },
    /**
     * 定位
     */
    positionTo(position, zoom, degree) {
      this.removeFlashMarker();
      if (!zoom) zoom = this.map.getView().getZoom();
      if (!degree) degree = 360;
      this.map.getView().animate({
        center: position,
        duration: 2000,
        zoom: zoom,
        rotation:
          ((this.map.getView().getRotation() + Math.PI * 2) / 360) * degree,
      });
      // this.addFlashMarker(position);
    },
    /**
     * 获取弧度
     */
    getRadius(degree) {
      if (!degree) degree = 360;
      if (degree < 0) degree = degree + 360;
      return ((this.map.getView().getRotation() + Math.PI * 2) * degree) / 360;
    },
    /**
     * 获取角度
     */
    getDegree(radius) {
      if (!radius) radius = 0;
      var degree =
        (radius * 360) / (this.map.getView().getRotation() + Math.PI * 2);
      if (degree < 0) degree = degree + 360;
      return degree;
    },
    isValue(value) {
      return value != null && value != undefined && value != 0 && value != "";
    },
    fitExtent(data, callback) {
      let source = new this.mapApi.VectorSource();
      let features = [],
        feature = null;

      data.map((v) => {
        if (this.isValue(v.longitude) && this.isValue(v.latitude)) {
          feature = new this.mapApi.Feature({
            geometry: new this.mapApi.Point([v.longitude, v.latitude]),
          });
          feature.setProperties(v);
          features.push(feature);
        }
      });
      source.addFeatures(features);
      if (features.length >= 2) {
        this.map.getView().fit(this.getExtent(source.getExtent(), 1), {
          duration: 2000,
          callback: callback(features),
        });
      } else {
        let duration = 2000;
        let center = [];
        if (features.length === 0) center = this.map.getView().getCenter();
        else center = features[0].getGeometry().getCoordinates();
        this.map
          .getView()
          .animate({ center: center, zoom: 12, duration: duration });
        callback(features);
        // setTimeout(() => {
        //   callback(features);
        //   this.map.render();
        // }, duration / 2);
      }
    },
    getNormalUniqueId(data, type) {
      if (!data) data = {};
      data.uniqueId =
        type + new Date().getTime() + Math.random().toString(36).substr(2);
      return data;
    },
    // setMapActive(status) {
    //   for (let key in interactions) {
    //     if (
    //       interactions[key] instanceof this.mapApi.InteractionDragPan ||
    //       interactions[key] instanceof this.mapApi.InteractionMouseWheelZoom
    //     )
    //       interactions[key].setActive(status);
    //   }
    // },
    //-------------------------------------------------地图工具类end---------------------------------------------------------------------------------------------------------------------
  },
};
</script>
<style></style>
