<template>
  <div>
    <van-popup v-model="$store.state['map/mapMenu'].isVisible" position="bottom" @close="close"
      :style="{ height: '30%' }">
      <slot></slot>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from "vant";
export default {
  components: {
    [Popup.name]: Popup,
  },
  name: "Popup",
  // props: {
  //   show: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  // position: {
  //   type: String,
  //   default: 'bottom',
  // },
  data() {
    return {};
  },
  // computed: {
  //   visible: {
  //     get() {
  //       return this.show;
  //     },
  //     set() {
  //       this.$emit("closePop");
  //     },
  //   },
  // },
  // created(){
  //   console.log(1212121212,this.$store.state['map/mapMenu'].isVisible)
  // },
  methods: {
    close() {
      // this.$emit("closePop");
      // this.$store.dispatch("map/mapMenu/setVisible")
    },
  },
};
</script>
<style scoped lang="less">
/deep/.van-popup {
  z-index: 99999;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
</style>
