<template>
  <div class="timerContainer">
    <!-- 时间间隔 -->

    <div class="time_container">
      <div class="c—content">
        当前时间：<span>{{ timeShowBlock }}</span>
      </div>
      <div class="c—content">
        时间间隔：
        <el-select
          v-model="queryInterval"
          placeholder="请选择时间间隔"
          @change="changeQueryInterval"
          :style="{ flex: 1 }"
        >
          <el-option
            v-for="(item, index) in timeIntervalList"
            :key="index"
            :label="item.key"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="timebar">
      <span v-if="pageOption.prev" class="prevPage" @click="prevPage">
        <i class="el-icon-arrow-left"
      /></span>
      <span v-if="pageOption.next" class="nextPage" @click="nextPage">
        <i class="el-icon-arrow-right"
      /></span>
      <div ref="progress" class="progress">
        <span
          ref="timeShowBlock"
          class="timeShowBlock"
          @mousedown="DragTimeBlock($event)"
        ></span>
        <div ref="prgressfill" class="prgressfill" />
        <div ref="dateTimeBlock" class="dateTimeBlock">
          <div
            v-for="(item, index) in dateArr"
            :key="index"
            ref="dateTimeBlockChild"
            class="dateTimeBlockChild"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="buttons">
      <span class="playerBtn" @click="playerClicked"
        ><i :class="playing ? 'el-icon-video-pause' : 'el-icon-video-play'" />{{
          playing ? "暂停" : "播放"
        }}</span
      >
      <span class="playerBtn" @click="playerReset"
        ><i class="el-icon-refresh" />刷新</span
      >
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    dateArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    repeat: {
      type: Boolean,
      default: false,
    },
    ispage: {
      type: Boolean,
      default: false,
    },
    // 自动播放
    autoPlay: {
      type: Boolean,
      default: true,
    },
    // 显示到小时
    showHour: {
      type: Boolean,
      default: false,
    },
    // 初始小时
    initHour: {
      type: Number,
      default: 0,
    },
    // 初始分钟
    initMinute: {
      type: Number,
      default: 0,
    },
    // 每次变化间隔时间，单位s
    perSecond: {
      type: Number,
      default: 5,
    },
    // 时间间隔筛选
    queryIntervalIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      timeShowBlock: "",
      time24: 0,
      timeValue: 0,
      dateWidth: 0,
      dateindex: 0,
      compareTime: "",
      dateArrPage: [],
      dateArrPageIndex: 0,
      // dateArr: ['2020-07-05', '2020-07-06', '2020-07-07'],
      playing: false,
      dx: "",
      mx: "",
      drapis: false,
      intervalCom: null,
      initimeEvt: null,
      timeLine: {},
      pageOption: {
        prev: false,
        next: false,
        pasize: 15,
      },
      // queryInterval: 1 / 6,
      queryInterval: 1,
      minuteValue: 0,
      timeIntervalList: [
        {
          key: "10分钟/秒",
          value: 1 / 6,
        },
        {
          key: "1小时/秒",
          value: 1,
        },
        {
          key: "3小时/秒",
          value: 3,
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.queryInterval = this.timeIntervalList[this.queryIntervalIndex].value;
    this.init();
  },
  created() {},
  activated() {},
  deactivated() {},
  destroyed() {
    if (this.intervalCom) {
      clearInterval(this.intervalCom);
      this.intervalCom = null;
    }
  },
  methods: {
    changeQueryInterval() {
      var index = -1;
      this.timeIntervalList.map((v, i) => {
        if (v.value === this.queryInterval && index < 0) {
          index = i;
          return;
        }
      });
      this.$emit("changeParam", {
        queryIntervalIndex: index,
      });
      // this.init();
    },
    init() {
      this.$nextTick(() => {
        // 启动分页
        if (this.ispage) {
          this.pageStart();
        }

        this.$nextTick(() => {
          this.minuteValue =
            this.initMinute < 10 ? "0" + this.initMinute : this.initMinute;
          this.initialTime = new Date(
            (
              this.dateArr[0] +
              " " +
              this.initHour +
              ":" +
              this.minuteValue.replace(/-/g, "/")
            ).replace(/-/g, "/")
          ).getTime();
          // this.timeShowBlock = this.dateArr[0] + " " + this.initHour + ":00";
          this.timeShowBlock =
            this.dateArr[0] + " " + this.initHour + ":" + this.minuteValue;
        });

        this.DateTimeBlockInit();

        this.initimeEvt = setTimeout(() => {
          this.$refs.timeShowBlock.style.left = this.startPosition() + "px";
          this.$refs.prgressfill.style.width = 0 + "px";
          this.RequestCom();
          if (this.autoPlay) {
            this.playing = false;
            this.playerClicked();
          }
        }, 200);
      });
    },
    // 将时间块进行分组用于分页
    pageStart() {
      this.pageOption.prev = true;
      this.pageOption.next = true;
      for (var i = 0; i < this.dateArr.length; i += this.pageOption.pasize) {
        this.dateArrPage.push(
          this.dateArr.slice(i, i + this.pageOption.pasize)
        );
      }
      this.dateArr = this.dateArrPage[this.dateArrPageIndex];
    },
    timelineReset() {
      this.dateArr = this.dateArrPage[this.dateArrPageIndex];
      this.DateTimeBlockInit();
      this.$refs.timeShowBlock.style.left = 0 + "px";
      this.$refs.prgressfill.style.width = 0 + "px";
    },
    prevPage() {
      if (this.dateArrPageIndex === 0) {
        return false;
      }
      this.dateArrPageIndex--;
      this.timelineReset();
    },
    nextPage() {
      if (this.dateArrPageIndex === this.dateArrPage.length - 1) {
        return false;
      }
      this.dateArrPageIndex++;
      this.timelineReset();
    },
    // 播放按钮绑定事件
    playerClicked() {
      this.playing = !this.playing;
      this.playing ? this.startPlayTime() : this.stopPlayTime();
    },
    playerReset() {
      this.$store.dispatch("map/mapRoute/fitExtent");
    },
    RequestAndSetValue(v) {
      var datestr = this.dateArr[this.dateindex % this.dateArr.length];
      if (this.showHour) {
        this.timeShowBlock =
          (datestr === undefined ? this.dateArr[this.dateindex - 1] : datestr) +
          " " +
          v;
        if (
          (this.compareTime !== v && this.autoPlay) ||
          (this.compareTime !== v && this.compareTime && !this.autoPlay)
        ) {
          if (
            new Date(this.timeShowBlock.replace(/-/g, "/")).getTime() >=
            this.initialTime
          )
            this.$emit("timeToDo", { name: this.timeShowBlock });
        }
      }
      this.compareTime = v;
    },
    MapClear() {
      this.stopPlayTime();
    },
    RequestCom() {
      var width = Number(this.$refs.prgressfill.style.width.split("px")[0]);
      // 通过计算展示24小时
      // this.dateindex = parseInt(width / this.dateWidth);
      this.dateindex = parseInt(
        Math.round(width / this.time24) /
          Math.round(this.dateWidth / this.time24)
      );
      var fillwidth = width - this.dateWidth * this.dateindex;
      this.timeValue = Math.round(fillwidth / this.time24);
      // 小时处理
      if (Math.floor(this.queryInterval) >= 1) {
        this.timeValue = this.timeValue * this.queryInterval + this.initHour;
        if (this.timeValue >= 24) this.timeValue = this.timeValue - 24;
        if (this.timeValue >= 0 && this.timeValue < this.initHour)
          this.dateindex += 1;

        if (
          (this.timeValue - this.initHour) % Math.floor(this.queryInterval) ===
            0 ||
          (this.timeValue === this.initHour && width === 0)
        ) {
          this.timeValue =
            this.timeValue < 10 ? "0" + this.timeValue : this.timeValue;
          this.RequestAndSetValue(this.timeValue + ":00");
        }
      }
      // 分钟处理
      else {
        var hour = Math.floor(this.timeValue / 6);
        var minute = this.timeValue - hour * 6;
        minute = Number(this.minuteValue) + minute * 60 * this.queryInterval;
        minute = minute < 10 ? "0" + minute : minute;
        if (minute >= 60) {
          minute -= 60;
          hour += 1;
        }

        this.timeValue = hour;
        this.timeValue = this.timeValue + this.initHour;
        if (this.timeValue >= 24) {
          this.timeValue = this.timeValue - 24;
          this.dateindex += 1;
        }
        // if (this.timeValue >= 0 && this.timeValue <= this.initHour)
        //   this.dateindex += 1;
        this.timeValue =
          this.timeValue < 10 ? "0" + this.timeValue : this.timeValue;
        this.RequestAndSetValue(this.timeValue + ":" + minute);
      }
    },
    startPosition() {
      return this.$refs.progress.offsetLeft;
    },
    endPosition() {
      return this.$refs.progress.offsetLeft + this.$refs.progress.clientWidth;
    },
    StopBarBeyond() {
      if (
        Number(this.$refs.timeShowBlock.style.left.replace("px", "")) >
        this.endPosition()
      ) {
        if (this.repeat) {
          this.$refs.timeShowBlock.style.left = this.startPosition() + "px";
          this.$refs.prgressfill.style.width = "0";
        } else {
          this.drapis = false;
          this.stopPlayTime();
          this.$refs.timeShowBlock.style.left = this.endPosition() + "px";
          this.$refs.prgressfill.style.width =
            this.$refs.progress.clientWidth + "px";
        }
      }
      if (
        Number(this.$refs.timeShowBlock.style.left.replace("px", "")) <
        this.startPosition()
      ) {
        this.$refs.timeShowBlock.style.left = this.startPosition() + "px";
        this.$refs.prgressfill.style.width = "0";
        this.drapis = false;
      }
    },
    playTimeProgress() {
      var addparm = this.time24;
      this.$refs.timeShowBlock.style.left =
        Number(this.$refs.timeShowBlock.style.left.replace("px", "")) +
        addparm +
        "px";
      this.$refs.prgressfill.style.width =
        Number(this.$refs.prgressfill.style.width.replace("px", "")) +
        addparm +
        "px";
      // 解决当鼠标快速移动时，会溢出的bug
      this.StopBarBeyond();
      this.RequestCom();
    },
    startPlayTime() {
      var interval = 1000;
      this.intervalCom = setInterval(() => {
        this.playTimeProgress();
      }, interval);
    },
    stopPlayTime() {
      this.playing = false;
      clearInterval(this.intervalCom);
    },
    DragTimeBlock(e) {
      // 清空图层
      this.MapClear();
      e = e || window.event;
      this.mx = e.pageX; // 点击时鼠标X坐标
      this.dx = this.$refs.timeShowBlock.offsetLeft;
      this.drapis = true;
      document.onmousemove = (e) => {
        e = e || window.event;
        var x = e.pageX; // 移动时鼠标X坐标
        var moveX = this.dx + x - this.mx + 15; // 移动后对话框新的left值
        if (this.drapis) {
          var width = moveX - this.$refs.prgressfill.offsetLeft;
          this.$refs.timeShowBlock.style.left = moveX + "px";
          this.$refs.prgressfill.style.width = width + "px";
          // // 解决当鼠标快速移动时，会溢出的bug
          // this.StopBarBeyond();
          // this.RequestCom();
          if (width >= this.$refs.progress.clientWidth) {
            this.$refs.prgressfill.style.width =
              this.$refs.timeShowBlock.style.left =
                this.$refs.progress.clientWidth + "px";
            document.onmousemove = null;
          }
        }
      };
      document.onmouseup = () => {
        // 第二时间块，拖拽到第一时间块时，距离判断到不了0:00不触发第一时间块到请求
        // 加上鼠标离开后可以规避这一问题
        this.$emit("timeToDo", { name: this.timeShowBlock });
        this.drapis = false;
        document.onmouseup = null;
        this.stopPlayTime();

        // 解决当鼠标快速移动时，会溢出的bug
        this.StopBarBeyond();
        this.RequestCom();
      };
    },
    DateTimeBlockInit() {
      this.$nextTick(() => {
        var widthc =
          this.$refs.progress.clientWidth / (this.dateArr.length - 1);
        // this.$refs.dateTimeBlockChild.forEach((child) => {
        //   child.style.width = widthc + "px";
        // });
        this.dateWidth = widthc;
        this.time24 = (widthc / 24) * this.queryInterval;
      });
    },
  },
  beforeDestroy() {
    this.stopPlayTime();
    clearTimeout(this.initimeEvt);
  },
};
</script>

<style lang="scss" scoped>
.timerContainer {
  width: 100%;
  height: 100%;
  // margin-top: 1.5rem;
  // color: white;

  .buttons {
    .playerBtn {
      color: #fff;
      font-size: 0.5rem;
      margin: 0 0.5rem 0 0;
      background-color: rgba(64, 158, 255, 0.9);
      border-radius: 4px;
      cursor: pointer;
      padding: 0.2rem;
    }
  }

  .time_container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .c—content {
      display: flex;
      align-items: center;
      width: 45%;
      margin-top: 0.5rem;
      font-size: 0.5rem;
    }

    span {
      flex: 1;
      background: rgba(64, 158, 255, 0.2);
      border-radius: 4px;
      padding: 0 0.5rem 0 0.5rem;
    }
  }
}
::v-deep {
  .el-icon-arrow-up:before {
    content: "";
  }
  .el-input__inner {
    height: auto;
    line-height: unset;
  }
}

.prevPage,
.nextPage {
  cursor: pointer;
  font-size: 0.3rem;
  color: #fff;
}

.prevPage {
  position: absolute;
  left: 0.7rem;
}

.nextPage {
  position: absolute;
  right: 0.7rem;
}

.timebar {
  font-size: 0.16rem;
  width: 100%;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  .progress {
    width: 100%;
    height: 1rem;
    border-radius: 1rem;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    box-shadow: 0 0 0.03rem rgba(0, 0, 0, 0.3);
  }

  .prgressfill {
    width: 0px;
    background: #ed9d02;
    height: 100%;
    border-radius: 1rem;
  }

  .timeShowBlock {
    position: absolute;
    top: 2.3rem;
    background: #f6a512;
    color: #fff;
    padding: 0.2rem 0.3rem;
    margin-left: -0.15rem;
    height: 1rem;
    border-radius: 0.03rem;
    line-height: 1rem;
    font-size: 1rem;
    user-select: none;
    cursor: -webkit-grab;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);

    &::before {
      content: "";
      position: absolute;
      border: 0.05rem solid transparent;
      border-top-color: #f6a512;
      bottom: -0.1rem;
      margin-left: 0.06rem;
    }
  }

  .dateTimeBlock {
    display: none;
    width: 100%;
  }

  .dateTimeBlockChild {
    float: left;
    text-align: center;
    border-right: 0.02rem solid rgba(111, 111, 111, 0.8);
    font-size: 1rem;
    font-weight: 400;
    color: white;
    cursor: pointer;
    text-shadow: black 0 0 0.03rem, black 0 0 0.03rem;
    line-height: 2.5rem;
    user-select: none;

    &:last-child {
      border-right: 0;
    }
  }
}
</style>
