const seaWeather = function (options) {
  let mapApi = options.mapApi;
  let mapTar = options.map;
  // let featureArray = options.featureArray
  // let upCanvas = options.upCanvas
  function _inheritsLoose(subClass, superClass) {
    subClass.prototype = Object.create(superClass.prototype);
    subClass.prototype.constructor = subClass;
    subClass.__proto__ = superClass;
  }

  let canvasWeather = null;
  let cacheCanvasEnd = null;
  let Weather = function Weather(params) {
    if (!params.projection) params.projection = "EPSG:4326";
    let MIN_VELOCITY_INTENSITY = params.param.minValue || 0.1;
    let MAX_VELOCITY_INTENSITY = params.param.maxValue || 30;
    let NULL_WEATHER_VECTOR = [NaN, NaN, null];
    let builder;
    let grid;
    let gridData = params.data;
    let λ0, φ0, Δλ, Δφ, ni, nj, maxLon, minLat;

    let bilinearInterpolateVector = function bilinearInterpolateVector(
      x,
      y,
      g00,
      g10,
      g01,
      g11
    ) {
      let rx = 1 - x;
      let ry = 1 - y;
      return g00 * rx * ry + g10 * x * ry + g01 * rx * y + g11 * x * y;
    };

    let createWeatherBuilder = function createWeatherBuilder(res) {
      const computeWindUV = (u, v) => {
        var value = Math.sqrt(u * u + v * v);
        var angle = Math.acos(u / value);
        if (v < 0) {
          angle = -angle;
        }
        angle = Math.round((angle / Math.PI) * 180 + 90);
        if (angle < 0) {
          angle = angle + 360;
        }
        angle = Math.round(360 - angle);
        value = value.toFixed(2);
        return {
          value: parseFloat(value),
          angle: angle,
        };
      };
      let _data, _data1, _data2;
      if (res.length > 1) {
        _data1 = res[0]; //res.dataJson[0]//res[0];
        _data2 = res[1]; //res.dataJson[1];
        _data = _data1.data.map(function (v, i) {
          // 向量中有 负值，需取绝对值
          // return (Math.abs(v) + Math.abs(_data2.data[i])) / 2;
          return computeWindUV(v, _data2.data[i]).value;
        });
        // _data = computeWindUV(_data1.data[i], _data2.data[i]);
      } else {
        _data1 = res[0]; //res.dataJson[0];
        _data = _data1.data.map(function (v) {
          // 向量中有 负值，需取绝对值
          return Math.abs(v);
        });
      }
      return {
        header: res[0].header,
        data: function data(i) {
          return _data[i];
        },
        interpolate: bilinearInterpolateVector,
      };
    };
    let buildGrid = function buildGrid(data, callback) {
      builder = createWeatherBuilder(data);
      let header = builder.header;
      λ0 = header.lo1;
      φ0 = Math.max(header.la2, header.la1);
      Δλ = header.dx;
      Δφ = header.dy;
      ni = header.nx;
      nj = header.ny;
      maxLon = header.maxLon;
      minLat = header.minLat;
      // feature = header.feature;
      grid = [];
      let p = 0;
      let isContinuous = Math.floor(ni * Δλ) >= 360;
      for (let j = 0; j < nj; j++) {
        let row = [];
        for (let i = 0; i < ni; i++, p++) {
          row[i] = builder.data(p);
        }
        if (isContinuous) {
          row.push(row[0]);
        }
        grid[j] = row;
      }
      callback({
        interpolate: interpolate,
      });
    };

    let interpolate = function interpolate(λ, φ) {
      if (!grid) return null;
      // let flag = feature.getGeometry().intersectsCoordinate([λ, φ]);

      //20211027
      let flag = true;
      // let flag = true
      // for (let m = 0; m < featureArray.length; m++) {
      //   if (featureArray[m].intersectsCoordinate([λ, φ])) {
      //     flag = false
      //     m = featureArray.length
      //   }
      // }
      if (λ < λ0 || φ0 < φ || λ > maxLon || φ < minLat || !flag) {
        // if (λ < λ0 || φ0 < φ || λ > maxLon || φ < minLat) {
        return -1;
      }
      // let i = floorMod(λ - λ0, 360) / Δλ;
      let i = (λ - λ0) / Δλ;
      let j = (φ0 - φ) / Δφ;
      let fi = Math.floor(i),
        ci = fi + 1;
      let fj = Math.floor(j),
        cj = fj + 1;
      let row = grid[fj];

      if (row) {
        // console.log(row)
        let g00 = row[fi];
        let g10 = row[ci];
        if (ci === row.length) g10 = row[row.length - 1];
        row = grid[cj];
        if (cj === grid.length) row = grid[grid.length - 1];
        if (isValue(g00) && isValue(g10)) {
          let g01 = row[fi];
          let g11 = row[ci];
          if (ci === row.length) g11 = row[row.length - 1];

          if (isValue(g01) && isValue(g11)) {
            return builder.interpolate(i - fi, j - fj, g00, g10, g01, g11);
          }
        }
      }

      return null;
    };

    let isValue = function isValue(x) {
      return x !== null && x !== undefined;
    };

    let createField = function createField(columns, bounds, callback) {
      function field(x, y) {
        let column = columns[Math.round(x)];
        return (column && column[Math.round(y)]) || NULL_WEATHER_VECTOR;
      }

      field.release = function () {
        columns = [];
      };

      field.randomize = function (o) {
        let x, y;
        let safetyNet = 0;

        do {
          x = Math.round(Math.floor(Math.random() * bounds.width) + bounds.x);
          y = Math.round(Math.floor(Math.random() * bounds.height) + bounds.y);
        } while (field(x, y)[2] === null && safetyNet++ < 30);

        o.x = x;
        o.y = y;
        return o;
      };

      callback(bounds, field);
    };

    let buildBounds = function buildBounds(bounds, width, height) {
      let upperLeft = bounds[0];
      let lowerRight = bounds[1];
      let x = Math.round(upperLeft[0]);
      let y = Math.max(Math.floor(upperLeft[1], 0), 0);
      let yMax = Math.min(Math.ceil(lowerRight[1], height), height - 1);
      return {
        x: x,
        y: y,
        xMax: width,
        yMax: yMax,
        width: width,
        height: height,
      };
    };

    let createMask = function createMask() {
      let cacheCanvas = document.createElement("canvas");
      cacheCanvas.width = canvasWeather.width;
      cacheCanvas.height = canvasWeather.height;
      let ctx = cacheCanvas.getContext("2d");

      let imageData = ctx.getImageData(
        0,
        0,
        canvasWeather.width,
        canvasWeather.height
      );

      return {
        cacheCanvas: cacheCanvas,
        imageData: imageData,
        isVisible: function isVisible(x, y) {
          let i = (y * canvasWeather.width + x) * 4;
          return imageData.data[i + 3] > 0; // non-zero alpha means pixel is visible
        },
        set: function set(x, y, rgba) {
          let i = (y * canvasWeather.width + x) * 4;
          imageData.data[i] = rgba[0];
          imageData.data[i + 1] = rgba[1];
          imageData.data[i + 2] = rgba[2];
          imageData.data[i + 3] = rgba[3];
          return this;
        },
      };
    };
    let COLORSCALE = [
      [1, [63, 47, 113]],
      [1, [68, 51, 119]],
      [1, [75, 57, 127]],
      [1, [80, 61, 134]],
      [1, [86, 67, 140]],
      [1, [90, 71, 144]],
      [1, [96, 76, 154]],
      [1, [98, 77, 160]],
      [1, [99, 82, 165]],
      [1, [96, 82, 170]],
      [1, [96, 82, 172]],
      [1, [86, 80, 175]],
      [1, [83, 81, 179]],
      [1, [79, 77, 181]],
      [1, [77, 75, 185]],
      [1, [77, 75, 190]],
      [1, [75, 73, 194]],
      [1, [73, 70, 202]],
      [1, [75, 75, 208]],
      [1, [73, 76, 209]],
      [1, [79, 82, 213]],
      [1, [85, 88, 220]],
      [1, [87, 90, 226]],
      [1, [88, 91, 232]],
      [1, [88, 91, 242]],
      [1, [92, 95, 246]],
      [1, [92, 103, 246]],
      [1, [92, 107, 244]],
      [1, [92, 113, 243]],
      [1, [92, 113, 240]],
      [1, [92, 120, 240]],
      [1, [92, 130, 240]],
      [1, [92, 137, 240]],
      [1, [92, 144, 240]],
      [1, [86, 139, 237]],
      [1, [76, 144, 235]],
      [1, [69, 142, 225]],
      [1, [65, 140, 225]],
      [1, [66, 140, 223]],
      [1, [63, 138, 222]],
      [1, [58, 134, 219]],
      [1, [18, 136, 218]],
      [1, [16, 141, 205]],
      [1, [9, 144, 200]],
      [1, [2, 147, 194]],
      [1, [1, 192, 158]],
      [1, [1, 150, 189]],
      [1, [0, 149, 189]],
      [1, [22, 154, 186]],
      [1, [27, 157, 189]],
      [1, [26, 152, 183]],
      [1, [20, 157, 182]],
      [1, [16, 166, 184]],
      [1, [11, 176, 182]],
      [1, [2, 184, 178]],
      [1, [1, 190, 175]],
      [1, [1, 190, 148]],
      [1, [1, 194, 137]],
      [1, [0, 199, 131]],
      [1, [12, 205, 126]],
      [1, [20, 205, 116]],
      [1, [28, 208, 113]],
      [1, [35, 211, 101]],
      [1, [33, 203, 109]],
      [1, [30, 208, 109]],
      [1, [38, 204, 111]],
      [1, [43, 207, 116]],
      [1, [44, 210, 118]],
      [1, [45, 207, 117]],
      [1, [41, 208, 104]],
      [1, [53, 221, 108]],
      [1, [53, 220, 88]],
      [1, [60, 220, 95]],
      [1, [60, 220, 79]],
      [1, [60, 220, 71]],
      [1, [60, 220, 64]],
      [1, [60, 220, 60]],
      [1, [71, 220, 60]],
      [1, [75, 220, 60]],
      [1, [83, 220, 60]],
      [1, [96, 215, 59]],
      [1, [107, 215, 59]],
      [1, [118, 215, 59]],
      [1, [128, 212, 53]],
      [1, [133, 209, 51]],
      [1, [139, 205, 54]],
      [1, [146, 201, 51]],
      [1, [149, 195, 49]],
      [1, [162, 195, 49]],
      [1, [174, 197, 46]],
      [1, [181, 198, 38]],
      [1, [181, 199, 34]],
      [1, [183, 202, 29]],
      [1, [198, 200, 24]],
      [1, [199, 193, 17]],
      [1, [201, 190, 10]],
      [1, [203, 182, 6]],
      [1, [206, 173, 17]],
      [1, [208, 172, 26]],
      [1, [205, 170, 31]],
      [1, [198, 162, 37]],
      [1, [198, 158, 37]],
      [1, [194, 149, 40]],
      [1, [191, 148, 43]],
      [1, [189, 134, 39]],
      [1, [185, 129, 33]],
      [1, [184, 126, 25]],
      [1, [180, 120, 16]],
      [1, [179, 109, 9]],
      [1, [179, 105, 9]],
      [1, [179, 101, 9]],
      [1, [177, 95, 22]],
      [1, [177, 92, 17]],
      [1, [178, 93, 30]],
      [1, [184, 96, 37]],
      [1, [178, 92, 34]],
      [1, [186, 88, 22]],
      [1, [176, 87, 26]],
      [1, [172, 86, 34]],
      [1, [167, 88, 40]],
      [1, [167, 82, 40]],
      [1, [173, 76, 38]],
      [1, [180, 71, 37]],
      [1, [186, 67, 35]],
      [1, [188, 61, 36]],
      [1, [192, 60, 34]],
      [1, [197, 49, 25]],
      [1, [197, 44, 19]],
      [1, [197, 39, 13]],
      [1, [193, 40, 15]],
      [1, [191, 44, 20]],
      [1, [188, 49, 27]],
      [1, [185, 52, 31]],
      [1, [182, 52, 36]],
      [1, [179, 52, 32]],
      [1, [177, 48, 28]],
      [1, [175, 46, 26]],
      [1, [175, 43, 23]],
      [1, [173, 39, 18]],
      [1, [178, 31, 8]],
      [1, [178, 31, 8]],
      [1, [179, 30, 6]],
      [1, [174, 26, 1]],
      [1, [167, 25, 2]],
      [1, [173, 25, 1]],
      [1, [172, 16, 0]],
      [1, [173, 15, 1]],
      [1, [173, 16, 1]],
      [1, [169, 17, 2]],
      [1, [173, 15, 1]],
      [1, [180, 14, 0]],
      [1, [176, 14, 2]],
      [1, [175, 9, 44]],
      [1, [180, 6, 59]],
      [1, [180, 6, 59]],
      [1, [170, 15, 59]],
      [1, [170, 15, 70]],
      [1, [166, 7, 64]],
      [1, [158, 6, 63]],
      [1, [164, 4, 64]],
      [1, [156, 4, 68]],
      [1, [154, 1, 73]],
      [1, [154, 1, 80]],
      [1, [151, 1, 86]],
      [1, [151, 1, 96]],
      [1, [151, 1, 103]],
      [1, [148, 1, 105]],
      [1, [135, 28, 109]],
      [1, [133, 23, 108]],
      [1, [137, 8, 99]],
      [1, [137, 15, 95]],
      [1, [133, 18, 91]],
      [1, [129, 22, 85]],
      [1, [126, 26, 83]],
      [1, [123, 28, 82]],
      [1, [122, 31, 83]],
      [1, [122, 33, 84]],
      [1, [117, 33, 81]],
      [1, [114, 27, 76]],
      [1, [113, 25, 75]],
      [1, [112, 24, 74]],
      [1, [110, 21, 71]],
      [1, [109, 19, 70]],
      [1, [108, 17, 69]],
      [1, [107, 16, 68]],
      [1, [107, 16, 68]],
      [1, [107, 15, 67]],
      [1, [106, 13, 66]],
      [1, [104, 11, 64]],
      [1, [103, 10, 63]],
      [1, [103, 9, 63]],
      [1, [102, 8, 62]],
      [1, [101, 7, 61]],
      [1, [101, 6, 60]],
      [1, [101, 5, 60]],
      [1, [99, 4, 58]],
      [1, [97, 3, 56]],
      [1, [95, 2, 55]],
      [1, [93, 5, 55]],
      [1, [91, 7, 55]],
      [1, [90, 8, 55]],
      [1, [90, 11, 56]],
      [1, [88, 13, 56]],
      [1, [84, 14, 54]],
      [1, [83, 12, 53]],
      [1, [83, 11, 53]],
      [1, [83, 10, 52]],
      [1, [83, 8, 51]],
      [1, [82, 7, 50]],
      [1, [81, 6, 51]],
      [1, [79, 4, 47]],
      [1, [79, 4, 47]],
      [1, [78, 3, 46]],
      [1, [77, 2, 45]],
      [1, [77, 1, 45]],
      [1, [74, 3, 44]],
      [1, [73, 5, 44]],
      [1, [72, 4, 43]],
      [1, [72, 3, 43]],
      [1, [69, 4, 44]],
      [1, [67, 6, 46]],
      [1, [64, 5, 44]],
      [1, [63, 7, 44]],
      [1, [63, 6, 44]],
      [1, [62, 6, 44]],
      [1, [62, 5, 43]],
      [1, [61, 4, 43]],
      [1, [61, 4, 42]],
      [1, [60, 4, 42]],
      [1, [60, 3, 42]],
      [1, [60, 3, 41]],
      [1, [60, 3, 40]],
      [1, [60, 3, 39]],
      [1, [60, 2, 38]],
      [1, [59, 3, 38]],
      [1, [58, 3, 36]],
      [1, [58, 3, 36]],
      [1, [58, 2, 36]],
      [1, [57, 3, 35]],
      [1, [57, 2, 35]],
      [1, [56, 1, 34]],
      [1, [56, 0, 34]],
      [1, [56, 0, 33]],
      [1, [55, 0, 33]],
      [1, [55, 0, 32]],
      [1, [54, 0, 32]],
      [1, [54, 0, 31]],
      [1, [53, 0, 31]],
      [1, [53, 0, 30]],
      [1, [52, 0, 30]],
      [1, [51, 0, 30]],
      [1, [50, 0, 29]],
      [1, [49, 0, 28]],
      [1, [48, 0, 27]],
      [1, [47, 0, 25]],
    ];

    let SCALE = {
      bounds: [MIN_VELOCITY_INTENSITY, MAX_VELOCITY_INTENSITY],
      gradient: {}, // 计算色值及step
    };

    let setColorScale = function setColorScale() {
      let n = parseFloat(
          ((MAX_VELOCITY_INTENSITY - MIN_VELOCITY_INTENSITY) / 255).toFixed(4)
        ),
        m = MIN_VELOCITY_INTENSITY;

      for (let i = 0; i < COLORSCALE.length; i++) {
        COLORSCALE[i][0] = m;
        m += n;
      }
      return COLORSCALE;
    };
    function segmentedColorScale(segments) {
      let points = [],
        interpolators = [],
        ranges = [],
        opacitys = [];

      for (let i = 0; i < segments.length - 1; i++) {
        points.push(segments[i + 1][0]);
        interpolators.push(
          colorInterpolator(segments[i][1], segments[i + 1][1])
        );
        ranges.push([segments[i][0], segments[i + 1][0]]);
        opacitys.push([segments[i][1][3], segments[i + 1][1][3]]);
      }

      return function (point, alpha) {
        let i;
        for (i = 0; i < points.length - 1; i++) {
          if (point <= points[i]) {
            break;
          }
        }

        let range = ranges[i];
        // alpha = opacitys[i][0] * 255;
        alpha = 0.7 * 255;
        return interpolators[i](proportion(point, range[0], range[1]), alpha);
      };
    }
    function colorInterpolator(start, end) {
      let r = start[0],
        g = start[1],
        b = start[2];
      let Δr = end[0] - r,
        Δg = end[1] - g,
        Δb = end[2] - b;
      return function (i, a) {
        return [
          Math.floor(r + i * Δr),
          Math.floor(g + i * Δg),
          Math.floor(b + i * Δb),
          a,
        ];
      };
    }
    function proportion(x, low, high) {
      return (clamp(x, low, high) - low) / (high - low);
    }
    let clamp = function clamp(x, low, high) {
      return Math.max(low, Math.min(x, high));
    };
    SCALE.gradient = segmentedColorScale(setColorScale());
    let OVERLAY_ALPHA = Math.floor(0.7 * 255); //透明度

    let interpolateField = function interpolateField(gridFun, bounds) {
      let columns = [];
      let x = bounds.x;
      let mask = createMask();

      function interpolateColumn(x) {
        let column = [];

        for (let y = bounds.y; y <= bounds.yMax; y += 2) {
          let coord = mapTar.getCoordinateFromPixel([x, y]);
          let color = [0, 0, 0, 1];
          if (coord) {
            let λ = coord[0],
              φ = coord[1];

            if (isFinite(λ)) {
              let weather = gridFun.interpolate(λ, φ);
              if (
                isValue(weather) &&
                !isNaN(weather) &&
                weather >= MIN_VELOCITY_INTENSITY &&
                weather <= MAX_VELOCITY_INTENSITY
              ) {
                color = SCALE.gradient(weather, OVERLAY_ALPHA);
                // if (weather <= 0.2) color = [255, 255, 255, OVERLAY_ALPHA]
                // else if (weather <= 1.5) color = [82, 63, 141, OVERLAY_ALPHA] //1
                // else if (weather <= 3.3) color = [111, 134, 240, OVERLAY_ALPHA]
                // else if (weather <= 5.4) color = [70, 186, 198, OVERLAY_ALPHA] //3
                // else if (weather <= 7.9) color = [104, 225, 111, OVERLAY_ALPHA]
                // else if (weather <= 10.7) color = [196, 209, 78, OVERLAY_ALPHA] //5
                // else if (weather <= 13.8) color = [215, 121, 72, OVERLAY_ALPHA]
                // else if (weather <= 17.1) color = [195, 68, 85, OVERLAY_ALPHA] //7
                // else if (weather <= 20.7) color = [178, 78, 105, OVERLAY_ALPHA]
                // else if (weather <= 24.4) color = [152, 74, 117, OVERLAY_ALPHA] //9
                // else if (weather <= 28.4) color = [128, 64, 108, OVERLAY_ALPHA]
                // else if (weather <= 32.6) color = [102, 32, 70, OVERLAY_ALPHA] //11
                // else color = [72, 36, 52, OVERLAY_ALPHA]
              }
            }
          }
          mask
            .set(x, y, color)
            .set(x + 1, y, color)
            .set(x, y + 1, color)
            .set(x + 1, y + 1, color);
        }

        columns[x + 1] = columns[x] = column;
      }

      (function batchInterpolate() {
        let start = Date.now();
        while (x < bounds.width) {
          interpolateColumn(x);
          x += 2;

          if (Date.now() - start > 1000) {
            setTimeout(batchInterpolate, 25);
            return;
          }
        }
        createField(columns, bounds, function () {
          setTimeout(() => {
            if (canvasWeather) drawOverlay(mask);
          });
        });
      })();
    };
    let drawOverlay = function drawOverlay(field) {
      if (!field) return;
      let ctx = canvasWeather.getContext("2d");
      ctx.clearRect(0, 0, canvasWeather.width + 1, canvasWeather.height + 1);
      ctx.putImageData(field.imageData, 0, 0);
      // ctx.drawImage(field.cacheCanvas, 0, 0, canvasWeather.width, canvasWeather.height);
      options.map.render();
    }; // 开始绘制

    let start = function start(bounds, width, height) {
      stop();
      buildGrid(gridData, function (grid) {
        interpolateField(grid, buildBounds(bounds, width, height));
      });
    };

    let stop = function stop() {
      if (weathery.field) weathery.field.release();
    };

    let weathery = {
      start: start,
      stop: stop,
    };
    return weathery;
  };

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id);
    };
  }

  var isMobile = function isMobile() {
    return /android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i.test(
      navigator.userAgent
    );
    // return true
  };

  let createCanvas = function createCanvas(width, height, Canvas) {
    if (typeof document !== "undefined") {
      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      return canvas;
    } else {
      return new Canvas(width, height);
    }
  };

  let superClass = mapApi.imageLayer;
  _inheritsLoose(seaWeather, superClass);
  let _this;
  let moveend, movestart;
  function seaWeather(options) {
    if (!options) {
      options = {};
    }
    _this = superClass.call(this, options) || this;
    _this._canvas = null;
    _this.data = options.data;
    _this.$Weather = null;
    _this.isClear = false;
    _this.options = options;

    if (isMobile()) {
      const fun = () => {
        if (_this._canvas) _this._canvas.style.display = "block";
        let size = options.map.getSize();
        if (!_this._canvas) {
          _this._canvas = createCanvas(size[0], size[1]);
        } else {
          _this._canvas.width = size[0];
          _this._canvas.height = size[1];
        }
        _this._canvas.id = "seaWeather";
        canvasWeather = _this._canvas;

        this._canvas.style.position = "absolute";
        _this.renderData();
      };
      const fun1 = () => {
        if (document.querySelector("#cacheCanvas"))
          document.querySelector("#cacheCanvas").remove();
        if (_this._canvas) _this._canvas.style.display = "none";
      };
      fun();
      moveend = options.map.on("moveend", fun, _this);
      movestart = options.map.on("movestart", fun1);
    } else {
      _this.setSource(
        new mapApi.ImageCanvasSource({
          logo: options.logo,
          state: options.state,
          attributions: options.attributions,
          resolutions: options.resolutions,
          canvasFunction: function canvasFunction(
            extent,
            resolution,
            pixelRatio,
            size
          ) {
            if (!date && new Date().getTime() - date < 50) return;
            else date = new Date().getTime();
            if (!_this._canvas) {
              _this._canvas = createCanvas(size[0], size[1]);
            } else {
              _this._canvas.width = size[0];
              _this._canvas.height = size[1];
            }
            canvasWeather = _this._canvas;
            _this.renderData();
            return _this._canvas;
          },
          ratio: 1,
        })
      );
    }

    return _this;
  }

  let _proto = seaWeather.prototype;

  _proto.renderData = function renderData() {
    let _extent = _this._getExtent();
    if (_this._canvas && !_this.$Weather) {
      _this.$Weather = new Weather({
        canvas: _this._canvas,
        projection: _this._getProjectionCode(),
        data: _this.getData(),
        param: _this.getParam(),
      });
    }
    _this.$Weather.start(_extent[0], _extent[1], _extent[2]);
  };

  _proto.getData = function getData() {
    return _this.data;
  };

  _proto.getParam = function getParam() {
    return _this.options;
  };

  let date = null;

  _proto._getExtent = function _getExtent() {
    let size = mapTar.getSize();
    let extent = mapTar.getView().calculateExtent(size);

    if (size && extent) {
      return [
        [
          [0, 0],
          [size[0], size[1]],
        ],
        size[0],
        size[1],
        [
          [extent[0], extent[1]],
          [extent[2], extent[3]],
        ],
      ];
    } else {
      return false;
    }
  };

  _proto.appendTo = function appendTo(map, layerIndex) {
    if (isMobile()) {
      let parent = document.querySelector(".ol-layers").children[0];
      if (parent.children[1]) {
        parent.insertBefore(this._canvas, parent.children[1]);
      } else
        document
          .querySelector(".ol-layers")
          .children[0].appendChild(this._canvas);
      cacheCanvasEnd = true;

      // document.querySelector(".ol-layers").children[0].appendChild(this._canvas)
    } else {
      if (map && map instanceof mapApi.Map) {
        _this.set("originMap", map);
        _this.getSource().projection_ = _this._getProjectionCode();
        _this.getMap().getLayers().insertAt(layerIndex, _this);
      } else {
        throw new Error("not map object");
      }
    }
  };

  _proto.clearWeather = function clearWeather() {
    if (isMobile()) {
      if (!mapTar) return;
      if (this._canvas) {
        let canvas = createCanvas(this._canvas.width, this._canvas.height);
        canvas.style.position = "absolute";
        canvas.id = "cacheCanvas";
        document.querySelector(".ol-layers").children[0].appendChild(canvas);
        // let ctx = canvas.getContext("2d");
        // ctx.drawImage(this._canvas, 0, 0, this._canvas.width, this._canvas.height)
        // ctx.putImageData(this._canvas.getContext("2d").getImageData(0, 0, this._canvas.width, this._canvas.height), 0, 0);
        const fun = () => {
          setTimeout(() => {
            if (cacheCanvasEnd)
              setTimeout(() => {
                canvas.remove();
              });
            else fun();
          });
        };
        fun();
      }
      if (_this.$Weather) _this.$Weather.stop();
      _this.isClear = true;
      if (this._canvas) this._canvas.remove();
      this._canvas = null;
      mapApi.unByKey(moveend);
      mapApi.unByKey(movestart);
      mapTar.renderSync();
    } else {
      if (!mapTar) return;
      if (_this.$Weather) _this.$Weather.stop();
      _this.isClear = true;
      mapTar.removeLayer(_this);
      mapTar.renderSync();
    }
  };

  _proto.setMap = function setMap(map) {
    _this ? _this.set("originMap", map) : "";
  };

  _proto.getMap = function getMap() {
    return _this.get("originMap");
  };

  _proto._getProjectionCode = function _getProjectionCode() {
    let code = "";
    let map = mapTar;

    if (map) {
      code = map.getView() && map.getView().getProjection();
    } else {
      code = mapApi.getProjection("EPSG:3857");
    }

    return code;
  };
  return new seaWeather(options);
};
const SeaWeather_Distribute = function (options) {
  return new seaWeather(options);
};
export default SeaWeather_Distribute;
