const state = {
  config: [
    // {
    //   ref: "areaPlayBack",
    //   component: () => import("@/views/map/mapTool/areaPlayBack.vue"),
    // },
    // {
    //   ref: "extentFilter",
    //   component: () => import("@/views/map/mapTool/extentFilter.vue"),
    // },
    // {
    //   ref: "mapTool",
    //   component: () => import("@/views/map/mapTool/index.vue"),
    // },
    // {
    //   ref: "contextMenu",
    //   component: () => import("@/views/map/contextMenu/index.vue"),
    // },
    // {
    //   ref: "mapPosition",
    //   component: () => import("@/views/map/mapPosition/mapPosition.vue"),
    // },
    // {
    //   ref: "observation",
    //   component: () => import("@/views/map/observation/index.vue"),
    // },
    // {
    //   ref: "mapFishBound",
    //   component: () => import("@/views/map/mapFishBound/index.vue"),
    // },
    // {
    //   ref: "SystemSettingDefault",
    //   component: () => import("@/views/system/systemSettings.vue"),
    // },
  ],
};
const mutations = {};
const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
