<template>
  <hl-dialog
    :title="title"
    :bottom="bottom"
    :right="right"
    :width="width"
    :height="height"
    tope-area="t"
    :bgimg="require('@/assets/image/map/information_box.png')"
    ref="Dialog"
  >
    <div class="content" v-for="(item, index) in legendData" :key="index">
      <div>
        <img
          :src="item.image"
          alt=""
          :style="{ width: '1.5rem', height: '1.5rem' }"
        />
      </div>
      <div>
        <font>{{ item.name }}</font>
      </div>
    </div>
  </hl-dialog>
</template>
<script>
import HlDialog from "@/components/hlDialog/index.vue";
export default {
  name: "MapLegend",
  components: {
    HlDialog,
  },
  props: ["title", "legendData", "right", "bottom", "width", "height"],
  data() {
    return {};
  },
  inject: [],
  watch: {},
  onLoad() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  padding: 0.5rem 0 0 0;
}
.content > div {
  display: flex;
  align-items: center;
  color: #fff;
}
font {
  // vertical-align: -webkit-baseline-middle;
}
</style>
