const getters = {
  map: (state) => state["map/map"],
  mapTyphoon: (state) => state["map/mapTyphoon"],
  mapSwitch: (state) => state["map/mapSwitch"],
  mapWeather: (state) => state["map/mapWeather"],
  mapTime: (state) => state["map/mapTime"],
  mapOther: (state) => state["map/mapOther"],
  mapMenu: (state) => state["map/mapMenu"],
};
export default getters;
