import Vue from "vue";
import mapConfig from "./config/staticConfig";
import mapValue from "./config/staticValue";
import mapFun from "./map-fun/map-fun";
import mapApi from "./map-api/map-api.vue";
import mapBase from "./map-base/map-base.vue";
import mapLegend from "./map-legend/map-legend.vue";
import mapRoute from "./map-route/map-route.vue";
import mapTimeLine from "./map-timeline/map-timeline.vue";
import mapTool from "./map-tool/map-tool.vue";
import mapWeather from "./map-weather/index.vue";
import mapSwitch from "./map-switch/map-switch.vue";
import mapLonLat from "./map-lonlat/map-lonlat.vue";
import mapService from "./map-service/map-service.vue";
// import mapTime from './map-time/map-time.vue'
import mapTimeSelect from "./map-timeSelect/map-timeSelect.vue";
import mapTest from "./map-test/map-test.vue";
Vue.prototype.mapApi = mapApi;
Vue.prototype.$mapConfig = mapConfig;
Vue.use(mapFun);
Vue.prototype.$mapValue = mapValue;
Vue.component("map-base", mapBase);
Vue.component("map-legend", mapLegend);
Vue.component("map-route", mapRoute);
Vue.component("map-timeline", mapTimeLine);
Vue.component("map-tool", mapTool);
Vue.component("map-weather", mapWeather);
Vue.component("map-switch", mapSwitch);
Vue.component("map-lonlat", mapLonLat);
Vue.component("map-service", mapService);
// Vue.component('map-time', mapTime)
Vue.component("map-timeSelect", mapTimeSelect);
Vue.component("map-test", mapTest);
