<template>
  <div class="typhoonList">
    <list :data="selectId" v-if="selectId.length" @getPath="getPath"></list>
    <!-- 弹窗部分 -->
    <pop :show="show">
      <span class="typhoon-list-close ty-list-close" @click="closePop"></span>
      <div class="typhoon_list_data">
        <div class="typhoon_list_title_div">
          <table>
            <tbody>
              <tr class="typhoon_tr_title">
                <th class="year">年份</th>
                <th class="code">编号</th>
                <th class="name">中文名</th>
                <th class="ename">英文名</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div style="display: flex;height: 100%;overflow: auto;">
        <div class="typhoon_list_year_div">
          <template v-for="(item, index) in yearList">
            <div :key="index" class="tyName" @click.prevent="selectTy(item, index)"
              :class="activeIndex === index ? 'active' : ' '">
              {{ item.TYPHOON_YEAR + `年` }}
            </div>
          </template>
        </div>

        <div class="check">
          <van-checkbox-group v-model="result" @change="changeTy" :max="4">
            <van-checkbox style="margin: 20px 0" v-for="(item, index) in tyList" :name="JSON.stringify(item)"
              shape="square" icon-size="14px" :key="index"><span style="width: 60px;display: inline-block;">{{
                item.TYPHOON_ID }} </span> <span style="width:60px;display: inline-block;margin-left: 10px;">{{
    item.CHN_NAME }}</span>
              <span style="margin-left: 50px;">{{ item.ENG_NAME }}</span> </van-checkbox>
          </van-checkbox-group>
        </div>
      </div>



      <!-- <div>
        <h3>台风路径</h3>
        <div class="list_route">
          <div style="overflow: scroll">
            <template v-for="(item, index) in yearList">
              <div :key="index" class="tyName" @click.prevent="selectTy(item, index)"
                :class="activeIndex === index ? 'active' : ' '">
                {{ item.TYPHOON_YEAR }}
              </div>
            </template>
          </div>

          <div class="check">
            <van-checkbox-group v-model="result" @change="changeTy" :max="4">
              <van-checkbox style="margin: 30px 0" v-for="(item, index) in tyList" :name="JSON.stringify(item)"
                :key="index">{{ item.TYPHOON_ID }} {{ item.CHN_NAME }}
                {{ item.ENG_NAME }}</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
      </div> -->
    </pop>
    <!-- 弹窗部分 -->
  </div>
</template>

<script>
import Pop from "@/components/ui/popup.vue";
import List from "@/components/ui/tylist";
// import yearList from "@/constants/yearList";
import { Checkbox, CheckboxGroup, Toast } from "vant";
export default {
  name: "TyphoonList",
  components: {
    Pop,
    List,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
  },
  data() {
    return {
      yearList:[], //台风年份列表
      tyList: [], //台风列表
      result: [], //选中台风
      selectId: [],
      ids: [],
      activeIndex: 0,
      show: false,
    };
  },
  watch: {},
  mounted() {
    this.yearList = this.getYearList(new Date().getFullYear(),2010);
    console.log(2222,this.yearList)
    this.getTyList();
    // let currentYear = new Date().getFullYear();
    // //默认显示当前台风路径
    // this.$axios
    //   .get(`/api/getTyphoonList.json?occurDate=${currentYear}`)
    //   .then(async (res) => {
    //     if (res.success) {
    //       let typhoonObj = res.result.rows[0];
    //       this.selectId.push(typhoonObj);
    //       //多选框回显
    //       this.result.push(JSON.stringify(typhoonObj));
    //       await this.$store.dispatch(
    //         "map/mapTyphoon/getSelected",
    //         this.selectId
    //       );
    //       let obj = this.$store.getters.mapTyphoon.filterObj;
    //       if (obj.checked) this.getPath(obj.TYPHOON_ID, 1);
    //     } else {
    //       Toast.fail(res.message);
    //     }
    //   });
  },
  methods: {
    //弹出层
    async showPop() {
      // this.show = true;
      await this.$store.dispatch("map/mapMenu/setVisible")
    },
    //关闭弹出层
    closePop() {
      // this.show = false;
      this.$store.dispatch("map/mapMenu/setVisible")
    },
    //获取台风列表
    getTyList(data) {
      let year = data === undefined ? this.yearList[0].TYPHOON_YEAR : data;
      this.$axios
        .get(`/api/getTyphoonList.json?occurDate=${year}`)
        .then((res) => {
          if (res.success) {
            this.tyList = res.result.rows;
          } else {
            Toast.fail(res.message);
          }
        });
    },
    // 获取台风路径
    getPath(id, loadType) {
      this.$axios
        .get(`/api/getTyphoonRoute.json?typhoonId=${id}`)
        .then((res) => {
          if (res.success) {
            //添加
            this.$store.dispatch("map/mapTyphoon/setSelected", {
              data: res.result,
              type: loadType ? loadType : 2,
              TYPHOON_ID: id,
            });
          } else {
            Toast.fail(res.message);
          }
        });
    },
    getYearList(endYear,startYear) {
      let yearList = [];
      for (var i = startYear; i <= endYear; i++) {
        yearList.push({ TYPHOON_YEAR: i, label: i });
      }
      return yearList.reverse();
    },
    //选中台风
    async changeTy(val) {
      // // 清空所有
      // this.$store.dispatch("map/mapTyphoon/setSelected", { type: -1 });
      this.selectId = val.map((item) => {
        item = JSON.parse(item);
        return item;
      });
      await this.$store.dispatch("map/mapTyphoon/getSelected", this.selectId);
      let obj = this.$store.getters.mapTyphoon.filterObj;
      // console.log(22,obj)
      if (obj.checked) this.getPath(obj.TYPHOON_ID, 1);
      else
        this.$store.dispatch(
          "map/mapTyphoon/setSelected",
          Object.assign({ TYPHOON_ID: obj.TYPHOON_ID, type: 0 })
        );
      // this.closePop();
    },
    //选择台风年份
    selectTy(item, index) {
      this.activeIndex = index;
      this.getTyList(item.TYPHOON_YEAR);
    },
  },
};
</script>

<style lang="less" scoped>
.typhoon-list-close {
  width: 28px;
  height: 4px;
  position: absolute;
  display: inline-block;
  left: 50%;
  top: 10px;
  background: rgba(218, 220, 223, 1);
  border-radius: 2px;
  margin-left: -14px;
  // background-color: red;
}

.typhoon_list_title_div {
  margin-top: 30px;
  letter-spacing: 2px;
  position: relative;
  color: rgba(102, 102, 102, 1);
  overflow: auto;
}

.typhoon_list_data table {

  border-spacing: 0px 0px !important;
  border-collapse: collapse !important;
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.typhoon_list_year_div {
  height: 100%;
  overflow: auto;
  background-color: #efefef;
  width: 25%;
  text-align: center;
  color: black;
  margin-top: 10px;
}

.year,
.name {
  width: 20%;
}


//滚动但是隐藏滚动条
::-webkit-scrollbar {
  width: 0;
  height: 10px;
}

.tyName {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;

}

.check {
  text-align: center;
  margin: -10px 5px;
  padding: 10px 0;
  overflow-y: scroll;
  width: 90%;
}

.active {
  background-color: #fff;
  position: relative;

  &::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #CCCCCC;
    position: absolute;
    top: 6px;
    left: 73px;
  }
}

.list_route {
  display: flex;
  position: absolute;
  height: 100%;
  overflow-y: scroll;
}

/deep/.van-checkbox-group {
  margin: 10px 0;
}

// /deep/.van-checkbox__label {
//   color: #3d83ec !important;
//   font-size: 20px;
// }

// /deep/.van-checkbox__icon .van-icon {
//   border: 1px solid #3d83ec;
// }

/deep/ .van-overlay {
  background-color: transparent !important;
}
</style>