//数据配置页

const mapProjection = {
  from: "EPSG:4326",
  to: "EPSG:4326",
};

const multiCondition = false; // 每条轨迹记录是否单独一套时间查询条件
// 地图视图
const mapView = {
  center: [119.45453740089054, 35.156405515799184],
  // center: [108.36451876445048, 18.64928841285221],
  zoom: 8,
  // maxZoom: 18,
  minZoom: 2,
  projection: mapProjection.to,
};
const mapControl = {
  zoom: false,
  rotate: false,
  attribution: false,
};
const interactions = {
  pinchRotate: false, // 移动端禁止地图旋转
  dragPan: true,
  mouseWheelZoom: true,
};
// 基础底图
// 类型1:天地图矢量（底图+路网）,天地图影像（底图+路网+专题），发布的海图（底图）;
// 2:高德矢量（底图+路网）,高德影像（底图+路网+专题），发布的海图（底图）;
// 3:arcgis矢量（底图）,arcgis影像（底图），发布的海图（底图）;
const mapSwitch = 1;
const baseMapCountMax = 3; // 基础底图最大数目设定（用于区分不同情况下基础底图数据不相同问题）
const mapScale = [1, 1];
const routeType = false; //轨迹类型 true-动态轨迹;false-静态轨迹
const mapTool = {
  singleTool: false, //当前工具执行次数
  crossTool: true, //不同具条交互
  updateTool: true, //更新最新要素 （只保留最新绘制图形）
  identifyTool: false, //自定义绘制（回调要素绘制）
};
const mapInterval = {
  shipRefresh: 60000,
};
export default {
  mapProjection: mapProjection,
  mapView: mapView,
  mapControl: mapControl,
  interactions: interactions,
  mapSwitch: mapSwitch,
  baseMapCountMax: baseMapCountMax,
  mapScale: mapScale,
  routeType: routeType,
  mapTool: mapTool,
  mapInterval: mapInterval,
  multiCondition: multiCondition,
};
// uni.$u.mapConfig = {
//   mapView: mapView,
//   mapControl: mapControl,
//   mapId: mapId,
//   mapSwitch: mapSwitch,
//   baseMapCountMax: baseMapCountMax,
//   mapUrl: mapUrl,
// }
