<template>
  <div class="timeContainer" v-if="timeLineFlag">
    <map-base ref="mapCom" :map="map" />
    <map-timeline
      ref="timeline"
      :auto-play="timeline.autoPlay"
      :repeat="true"
      :date-arr="timeline.dateArr"
      :show-hour="true"
      :init-hour="timeline.initHour"
      :init-minute="timeline.initMinute"
      :time24="timeline.time24"
      :query-interval-index="queryIntervalIndex"
      @timeToDo="timelineNext"
      @changeParam="changeParam"
    />
  </div>
</template>

<script>
// import { hlDialog } from "@/components";
import mapTimeline from "./map-timeline/map-timeline.vue";
// import mapTimeSelect from "./map-timeSelect/map-timeSelect.vue";
export default {
  name: "TimeWeather",
  components: {
    // hlDialog,
    mapTimeline,
    // mapTimeSelect,
  },
  props: ["map"],
  data() {
    return {
      options: {
        title: "气象观测",
        height: "auto",
        width: "15rem",
        bottom: "0rem",
        left: "0rem",
        topeArea: ",,c",
      },
      mapTime: this.$store.getters.mapTime,

      timeLineFlag: false,
      timeline: {
        dateArr: [],
        initHour: 0,
        initMinute: 0,
        autoPlay: false,
      },

      timeSelect: {
        startTime: new Date().getTime() - 24 * 60 * 60 * 1000,
        endTime: new Date().getTime(),
      },
      showData: {},
      queryIntervalIndex: 2,
      multiCondition: this.$mapConfig.multiCondition,
    };
  },
  inject: [],
  watch: {
    "mapTime.selected": {
      async handler(v) {
        if (v && v.moduleType === "mapWeather") {
          //时间轴
          if (v.type === 1 && v.callType === 1) {
            this.timeLineFlag = false;
            this.$nextTick(() => {
              this.timeLineFlag = v.data.checked;
              // this.$nextTick(() => {
              //   if (this.timeLineFlag) {
              //     this.$refs.timeLineC.setRestore();
              //   }
              // });
              this.dealTimeLine(v);
            });
          }
          //时间控件
          else if (v.type === 2 && v.callType === 1) {
            if (v.data.startTime && v.data.endTime)
              Object.assign(this.timeSelect, v.data);
          }
        }
      },
      deep: true,
    },
  },
  onLoad() {},
  mounted() {
    // this.$refs.timeLineC.setMin();
    // console.log("轨迹时间控件");
  },
  methods: {
    initTimeLine(startTime, d) {
      var e = new Date(startTime);
      // if (this.timeline.autoPlay) this.timeline.initHour = e.getHours();
      // else this.timeline.initHour = e.getHours();
      var hour = e.getHours();
      this.timeline.initHour = hour % 3 === 0 ? hour : parseInt(hour / 3) * 3;
      this.timeline.initMinute = 0;
      var s = this.$timestampTostr(e);
      e.setDate(e.getDate() + d);
      e = this.$timestampTostr(e);
      this.timeline.dateArr = this.$getDateArr(s, e);
      // console.log("时间轴集合", this.timeline.dateArr);
    },
    // 时间轴节点触发回调
    timelineNext(v) {
      let json = {};
      if (v) {
        json = {
          startTime: this.timeSelect.startTime,
          endTime: new Date(v.name.replace(/-/g, "/")).getTime(),
        };
      } else {
        json = this.timeSelect;
      }
      if (this.timeLineFlag)
        this.$store.dispatch("map/mapTime/setSelected", {
          type: 1,
          callType: 2,
          moduleType: "mapWeather",
          data: json,
        });
    },
    getTimeSelect(data) {
      this.timeSelect = data;
      this.timeLineFlag = false;
      this.timeline = {
        dateArr: [],
        initHour: 0,
        initMinute: 0,
        autoPlay: false,
      };
      this.$nextTick(() => {
        this.timeLineFlag = true;
        this.timeline.autoPlay = true;
        this.$nextTick(() => {
          // this.$refs.timeLineC.setRestore();
          let d = Math.round(
            (this.timeSelect.endTime - this.timeSelect.startTime) /
              1000 /
              60 /
              60 /
              24
          );
          this.initTimeLine(this.timeSelect.startTime, d);
          // //内部调用时,默认执行全时间段
          // this.timelineNext();
        });
      });
    },
    closed() {
      this.$store.dispatch("map/mapWeather/setSelected", {
        checked: false,
        childType: 1,
      });
      this.timeline = {
        dateArr: [],
        initHour: 0,
        initMinute: 0,
        autoPlay: false,
      };
      this.timeLineFlag = false;
    },
    changeParam(param) {
      this.queryIntervalIndex = param.queryIntervalIndex;
      this.timeLineFlag = false;
      this.$nextTick(() => {
        this.timeLineFlag = true;
      });
    },
    dealTimeLine(v) {
      if (this.timeLineFlag) {
        this.timeline.autoPlay = v.data.autoPlay;
        v.data.startTime = Number(v.data.startTime);
        v.data.endTime = Number(v.data.endTime);
        Object.assign(this.timeSelect, {
          startTime: v.data.startTime,
          endTime: v.data.endTime,
        });
        let d = Math.round(
          (v.data.endTime - v.data.startTime) / 1000 / 60 / 60 / 24
        );
        if (d < 0) d = 1;
        this.initTimeLine(v.data.startTime, d);
        this.showData = JSON.parse(JSON.stringify(v.showData));
      } else {
        this.timeline = {
          dateArr: [],
          initHour: 0,
          initMinute: 0,
          autoPlay: false,
        };
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.hlDialog {
  ::v-deep {
    .hl-dialog__header {
      color: #fff;
      background-color: #409eff;
      .hl-dialog__title {
        color: #fff;
      }
    }
  }
}
.timeContainer {
  z-index: 2000;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: auto;
  left: 0;
  .timeLine {
    width: 100%;
    position: fixed;
    bottom: 2rem;
    z-index: 98;
    // font-size: 0.5rem;
  }
  .timeSelect {
    z-index: 98;
    width: 100%;
    box-sizing: border-box;
  }
  .nameC {
    position: absolute;
    top: 18rem;
    z-index: 98;
    align-items: center;
    // color: white;
    padding: 0.8rem;
    overflow-y: scroll;
    max-height: 90px;
    text-align: center;
    justify-content: center;
    flex-wrap: nowrap;
    box-sizing: border-box;
    width: 100%;
    min-width: 25%;

    .nameItem {
      display: flex;
      white-space: nowrap;

      div {
        width: 100%;
        background-color: rgba(64, 158, 255, 0.2);
        text-align: end;
      }
    }
    .fixed {
      color: orange;
    }
    .unfixed {
      // color: white;
    }
  }
}
</style>
