const seaWeather = function (options) {
  let mapApi = options.mapApi;
  let mapTar = options.map;
  let weatherType = options.weatherType;
  // let featureArray = options.featureArray
  // let upCanvas = options.upCanvas
  function _inheritsLoose(subClass, superClass) {
    subClass.prototype = Object.create(superClass.prototype);
    subClass.prototype.constructor = subClass;
    subClass.__proto__ = superClass;
  }

  let canvasWeather = null;
  let Weather = function Weather(params) {
    if (!params.projection) params.projection = "EPSG:4326";
    let MIN_VELOCITY_INTENSITY = params.param.minValue || 0.1;
    let MAX_VELOCITY_INTENSITY = params.param.maxValue || 30;
    // let MIN_VELOCITY_INTENSITY = params.minValue || 0.0001;
    // let MAX_VELOCITY_INTENSITY = params.maxValue || 5;
    var MAX_PARTICLE_AGE = params.param.particleAge || 90;
    var PARTICLE_LINE_WIDTH = 2;
    var PARTICLE_MULTIPLIER = params.param.particleMultiplier || 1 / 1000;
    var PARTICLE_REDUCTION = Math.pow(window.devicePixelRatio, 1 / 3) || 1.6;
    var FRAME_RATE = params.param.frameRate || 13,
      FRAME_TIME = 1000 / FRAME_RATE;
    let defaulColorScale = [
      "rgb(36,104, 180)",
      "rgb(60,157, 194)",
      "rgb(128,205,193 )",
      "rgb(151,218,168 )",
      "rgb(198,231,181)",
      "rgb(238,247,217)",
      "rgb(255,238,159)",
      "rgb(252,217,125)",
      "rgb(255,182,100)",
      "rgb(252,150,75)",
      "rgb(250,112,52)",
      "rgb(245,64,32)",
      "rgb(237,45,28)",
      "rgb(220,24,32)",
      "rgb(180,0,35)",
    ];
    // let defaulColorScale = ["#006837", "#1a9850", "#66bd63", "#a6d96a", "#d9ef8b", "#ffffbf",
    //   "#fee08b", "#fdae61", "#f46d43", "#d73027", "#a50026"];
    let colorScale = params.param.colorScale || defaulColorScale;
    let NULL_WEATHER_VECTOR = [NaN, NaN, null];
    let builder;
    let grid;
    let gridData = params.data;
    let date;
    let λ0, φ0, Δλ, Δφ, ni, nj, maxLon, minLat;

    // var VELOCITY_SCALE = (params.velocityScale || 0.005) * (Math.pow(window.devicePixelRatio, 1 / 3) || 1);

    let setData = function setData(data) {
      gridData = data;
    };

    var bilinearInterpolateVector = function bilinearInterpolateVector(
      x,
      y,
      g00,
      g10,
      g01,
      g11
    ) {
      var rx = 1 - x;
      var ry = 1 - y;
      var a = rx * ry,
        b = x * ry,
        c = rx * y,
        d = x * y;
      var u = g00[0] * a + g10[0] * b + g01[0] * c + g11[0] * d;
      var v = g00[1] * a + g10[1] * b + g01[1] * c + g11[1] * d;
      return [u, v, Math.sqrt(u * u + v * v)];
    };

    let createWeatherBuilder = function createWeatherBuilder(uComp, vComp) {
      let uData = uComp.data,
        vData = vComp.data;
      return {
        header: uComp.header,
        data: function data(i) {
          return [uData[i], vData[i]];
        },
        interpolate: bilinearInterpolateVector,
      };
    };
    var createBuilder = function createBuilder(data) {
      var uComp = null,
        vComp = null;
      data.forEach(function (record) {
        if (record.header.type) {
          switch (record.header.type) {
            case "windU":
            case "currentU":
              uComp = record;
              break;
            case "windV":
            case "currentV":
              vComp = record;
              break;

            default:
          }
        } else {
          uComp = vComp = record;
        }
      });
      return createWeatherBuilder(uComp, vComp);
    };
    let buildGrid = function buildGrid(data, callback) {
      builder = createBuilder(data);
      let header = builder.header;
      λ0 = header.lo1;
      φ0 = Math.max(header.la2, header.la1);
      Δλ = header.dx;
      Δφ = header.dy;
      ni = header.nx;
      nj = header.ny;
      maxLon = header.maxLon;
      minLat = header.minLat;
      // feature = header.feature;
      date = new Date(header.refTime);
      date.setHours(date.getHours() + header.forecastTime);
      grid = [];
      let p = 0;
      let isContinuous = Math.floor(ni * Δλ) >= 360;

      for (let j = 0; j < nj; j++) {
        // for (let j = nj - 1; j >= 0; j--) {
        let row = [];

        for (let i = 0; i < ni; i++, p++) {
          row[i] = builder.data(p);
        }
        // for (let i = ni - 1; i >= 0; i--, p++) {
        //   row[i] = builder.data(p);
        // }

        if (isContinuous) {
          row.push(row[0]);
        }

        grid[j] = row;
      }

      callback({
        date: date,
        interpolate: interpolate,
      });
    };

    let interpolate = function interpolate(λ, φ) {
      if (!grid) return null;
      // let flag = feature.getGeometry().intersectsCoordinate([λ, φ]);

      //20211027
      let flag = true;
      // let flag = true
      // for (let m = 0; m < featureArray.length; m++) {
      //   if (featureArray[m].intersectsCoordinate([λ, φ])) {
      //     flag = false
      //     m = featureArray.length
      //   }
      // }
      if (λ < λ0 || φ0 < φ || λ > maxLon || φ < minLat || !flag) {
        // if (λ < λ0 || φ0 < φ || λ > maxLon || φ < minLat) {
        return -1;
      }
      // let i = floorMod(λ - λ0, 360) / Δλ;
      let i = (λ - λ0) / Δλ;
      let j = (φ0 - φ) / Δφ;
      let fi = Math.floor(i),
        ci = fi + 1;
      let fj = Math.floor(j),
        cj = fj + 1;
      let row = grid[fj];

      if (row) {
        let g00 = row[fi];
        let g10 = row[ci];
        if (ci === row.length) g10 = row[row.length - 1];
        row = grid[cj];
        if (cj === grid.length) row = grid[grid.length - 1];
        if (isValue(g00) && isValue(g10)) {
          let g01 = row[fi];
          let g11 = row[ci];
          if (ci === row.length) g11 = row[row.length - 1];

          if (isValue(g01) && isValue(g11)) {
            return builder.interpolate(i - fi, j - fj, g00, g10, g01, g11);
          }
        }
      }

      return null;
    };

    let isValue = function isValue(x) {
      return x !== null && x !== undefined;
    };

    var isMobile = function isMobile() {
      return /android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i.test(
        navigator.userAgent
      );
    };

    // var distort = function distort(projection, λ, φ, x, y, scale, wind, windy) {
    //   var u = wind[0] * scale;
    //   var v = wind[1] * scale;
    //   var d = distortion(projection, λ, φ, x, y, windy);
    //   wind[0] = d[0] * u + d[2] * v;
    //   wind[1] = d[1] * u + d[3] * v;
    //   return wind;
    // };

    // var distortion = function distortion(projection, λ, φ, x, y, windy) {
    //   var τ = 2 * Math.PI;
    //   var H = params.projection === 'EPSG:4326' ? 5 : Math.pow(10, -5.2);
    //   var hλ = λ < 0 ? H : -H;
    //   var hφ = φ < 0 ? H : -H;
    //   var pλ = project(φ, λ + hλ, windy);
    //   var pφ = project(φ + hφ, λ, windy);
    //   var k = Math.cos(φ / 360 * τ);
    //   return [(pλ[0] - x) / hλ / k, (pλ[1] - y) / hλ / k, (pφ[0] - x) / hφ, (pφ[1] - y) / hφ];
    // };
    // var mercY = function mercY(lat) {
    //   return Math.log(Math.tan(lat / 2 + Math.PI / 4));
    // };

    // var project = function project(lat, lon, windy) {
    //   var ymin = mercY(windy.south);
    //   var ymax = mercY(windy.north);
    //   var xFactor = windy.width / (windy.east - windy.west);
    //   var yFactor = windy.height / (ymax - ymin);
    //   var y = mercY(deg2rad(lat));
    //   var x = (deg2rad(lon) - windy.west) * xFactor;
    //   y = (ymax - y) * yFactor;
    //   return [x, y];
    // };
    let createField = function createField(columns, bounds, callback) {
      function field(x, y) {
        let column = columns[Math.round(x)];
        return (column && column[Math.round(y)]) || NULL_WEATHER_VECTOR;
      }

      field.release = function () {
        columns = [];
      };

      field.randomize = function (o) {
        let x, y;
        let safetyNet = 0;

        do {
          x = Math.round(Math.floor(Math.random() * bounds.width) + bounds.x);
          y = Math.round(Math.floor(Math.random() * bounds.height) + bounds.y);
        } while (field(x, y)[2] === null && safetyNet++ < 30);

        o.x = x;
        o.y = y;
        return o;
      };

      callback(bounds, field);
    };

    let buildBounds = function buildBounds(bounds, width, height) {
      let upperLeft = bounds[0];
      let lowerRight = bounds[1];
      let x = Math.round(upperLeft[0]);
      let y = Math.max(Math.floor(upperLeft[1], 0), 0);
      // let xMax = Math.min(Math.ceil(lowerRight[0], width), width - 1);
      let yMax = Math.min(Math.ceil(lowerRight[1], height), height - 1);
      return {
        x: x,
        y: y,
        xMax: width,
        yMax: yMax,
        width: width,
        height: height,
      };
    };

    let deg2rad = function deg2rad(deg) {
      return (deg / 180) * Math.PI;
    };

    // let rad2deg = function rad2deg(ang) {
    //   return ang / (Math.PI / 180.0);
    // };

    // let invert;

    // if (params.projection === 'EPSG:4326') {
    //   invert = function invert(x, y, weathery) {
    //     let mapLonDelta = weathery.east - weathery.west;
    //     let mapLatDelta = weathery.south - weathery.north;
    //     let lat = rad2deg(weathery.north) + y / weathery.height * rad2deg(mapLatDelta);
    //     let lon = rad2deg(weathery.west) + x / weathery.width * rad2deg(mapLonDelta);
    //     return [lon, lat];
    //   };
    // } else {
    //   invert = function invert(x, y, weathery) {
    //     let mapLonDelta = weathery.east - weathery.west;
    //     let worldMapRadius = weathery.width / rad2deg(mapLonDelta) * 360 / (2 * Math.PI);
    //     let mapOffsetY = worldMapRadius / 2 * Math.log((1 + Math.sin(weathery.south)) / (1 - Math.sin(weathery.south)));
    //     let equatorY = weathery.height + mapOffsetY;
    //     let a = (equatorY - y) / worldMapRadius;
    //     let lat = 180 / Math.PI * (2 * Math.atan(Math.exp(a)) - Math.PI / 2);
    //     let lon = rad2deg(weathery.west) + x / weathery.width * rad2deg(mapLonDelta);
    //     return [lon, lat];
    //   };
    // }
    let createMask = function createMask() {
      // Create a detached canvas, ask the model to define the mask polygon, then fill with an opaque color.
      let view = {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      };
      let width = view.width,
        height = view.height;
      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      let context = canvas.getContext("2d");
      context.fillStyle = "rgba(255, 0, 0, 1)";
      context.fill();
      let imageData = context.getImageData(0, 0, width, height);
      let data = imageData.data; // layout: [r, g, b, a, r, g, b, a, ...]

      return {
        imageData: imageData,
        isVisible: function isVisible(x, y) {
          let i = (y * width + x) * 4;
          return data[i + 3] > 0; // non-zero alpha means pixel is visible
        },
        set: function set(x, y, rgba) {
          let i = (y * width + x) * 4;
          data[i] = rgba[0];
          data[i + 1] = rgba[1];
          data[i + 2] = rgba[2];
          data[i + 3] = rgba[3];
          return this;
        },
      };
    };
    let COLORSCALE = [
      [1, [63, 47, 113]],
      [1, [68, 51, 119]],
      [1, [75, 57, 127]],
      [1, [80, 61, 134]],
      [1, [86, 67, 140]],
      [1, [90, 71, 144]],
      [1, [96, 76, 154]],
      [1, [98, 77, 160]],
      [1, [99, 82, 165]],
      [1, [96, 82, 170]],
      [1, [96, 82, 172]],
      [1, [86, 80, 175]],
      [1, [83, 81, 179]],
      [1, [79, 77, 181]],
      [1, [77, 75, 185]],
      [1, [77, 75, 190]],
      [1, [75, 73, 194]],
      [1, [73, 70, 202]],
      [1, [75, 75, 208]],
      [1, [73, 76, 209]],
      [1, [79, 82, 213]],
      [1, [85, 88, 220]],
      [1, [87, 90, 226]],
      [1, [88, 91, 232]],
      [1, [88, 91, 242]],
      [1, [92, 95, 246]],
      [1, [92, 103, 246]],
      [1, [92, 107, 244]],
      [1, [92, 113, 243]],
      [1, [92, 113, 240]],
      [1, [92, 120, 240]],
      [1, [92, 130, 240]],
      [1, [92, 137, 240]],
      [1, [92, 144, 240]],
      [1, [86, 139, 237]],
      [1, [76, 144, 235]],
      [1, [69, 142, 225]],
      [1, [65, 140, 225]],
      [1, [66, 140, 223]],
      [1, [63, 138, 222]],
      [1, [58, 134, 219]],
      [1, [18, 136, 218]],
      [1, [16, 141, 205]],
      [1, [9, 144, 200]],
      [1, [2, 147, 194]],
      [1, [1, 192, 158]],
      [1, [1, 150, 189]],
      [1, [0, 149, 189]],
      [1, [22, 154, 186]],
      [1, [27, 157, 189]],
      [1, [26, 152, 183]],
      [1, [20, 157, 182]],
      [1, [16, 166, 184]],
      [1, [11, 176, 182]],
      [1, [2, 184, 178]],
      [1, [1, 190, 175]],
      [1, [1, 190, 148]],
      [1, [1, 194, 137]],
      [1, [0, 199, 131]],
      [1, [12, 205, 126]],
      [1, [20, 205, 116]],
      [1, [28, 208, 113]],
      [1, [35, 211, 101]],
      [1, [33, 203, 109]],
      [1, [30, 208, 109]],
      [1, [38, 204, 111]],
      [1, [43, 207, 116]],
      [1, [44, 210, 118]],
      [1, [45, 207, 117]],
      [1, [41, 208, 104]],
      [1, [53, 221, 108]],
      [1, [53, 220, 88]],
      [1, [60, 220, 95]],
      [1, [60, 220, 79]],
      [1, [60, 220, 71]],
      [1, [60, 220, 64]],
      [1, [60, 220, 60]],
      [1, [71, 220, 60]],
      [1, [75, 220, 60]],
      [1, [83, 220, 60]],
      [1, [96, 215, 59]],
      [1, [107, 215, 59]],
      [1, [118, 215, 59]],
      [1, [128, 212, 53]],
      [1, [133, 209, 51]],
      [1, [139, 205, 54]],
      [1, [146, 201, 51]],
      [1, [149, 195, 49]],
      [1, [162, 195, 49]],
      [1, [174, 197, 46]],
      [1, [181, 198, 38]],
      [1, [181, 199, 34]],
      [1, [183, 202, 29]],
      [1, [198, 200, 24]],
      [1, [199, 193, 17]],
      [1, [201, 190, 10]],
      [1, [203, 182, 6]],
      [1, [206, 173, 17]],
      [1, [208, 172, 26]],
      [1, [205, 170, 31]],
      [1, [198, 162, 37]],
      [1, [198, 158, 37]],
      [1, [194, 149, 40]],
      [1, [191, 148, 43]],
      [1, [189, 134, 39]],
      [1, [185, 129, 33]],
      [1, [184, 126, 25]],
      [1, [180, 120, 16]],
      [1, [179, 109, 9]],
      [1, [179, 105, 9]],
      [1, [179, 101, 9]],
      [1, [177, 95, 22]],
      [1, [177, 92, 17]],
      [1, [178, 93, 30]],
      [1, [184, 96, 37]],
      [1, [178, 92, 34]],
      [1, [186, 88, 22]],
      [1, [176, 87, 26]],
      [1, [172, 86, 34]],
      [1, [167, 88, 40]],
      [1, [167, 82, 40]],
      [1, [173, 76, 38]],
      [1, [180, 71, 37]],
      [1, [186, 67, 35]],
      [1, [188, 61, 36]],
      [1, [192, 60, 34]],
      [1, [197, 49, 25]],
      [1, [197, 44, 19]],
      [1, [197, 39, 13]],
      [1, [193, 40, 15]],
      [1, [191, 44, 20]],
      [1, [188, 49, 27]],
      [1, [185, 52, 31]],
      [1, [182, 52, 36]],
      [1, [179, 52, 32]],
      [1, [177, 48, 28]],
      [1, [175, 46, 26]],
      [1, [175, 43, 23]],
      [1, [173, 39, 18]],
      [1, [178, 31, 8]],
      [1, [178, 31, 8]],
      [1, [179, 30, 6]],
      [1, [174, 26, 1]],
      [1, [167, 25, 2]],
      [1, [173, 25, 1]],
      [1, [172, 16, 0]],
      [1, [173, 15, 1]],
      [1, [173, 16, 1]],
      [1, [169, 17, 2]],
      [1, [173, 15, 1]],
      [1, [180, 14, 0]],
      [1, [176, 14, 2]],
      [1, [175, 9, 44]],
      [1, [180, 6, 59]],
      [1, [180, 6, 59]],
      [1, [170, 15, 59]],
      [1, [170, 15, 70]],
      [1, [166, 7, 64]],
      [1, [158, 6, 63]],
      [1, [164, 4, 64]],
      [1, [156, 4, 68]],
      [1, [154, 1, 73]],
      [1, [154, 1, 80]],
      [1, [151, 1, 86]],
      [1, [151, 1, 96]],
      [1, [151, 1, 103]],
      [1, [148, 1, 105]],
      [1, [135, 28, 109]],
      [1, [133, 23, 108]],
      [1, [137, 8, 99]],
      [1, [137, 15, 95]],
      [1, [133, 18, 91]],
      [1, [129, 22, 85]],
      [1, [126, 26, 83]],
      [1, [123, 28, 82]],
      [1, [122, 31, 83]],
      [1, [122, 33, 84]],
      [1, [117, 33, 81]],
      [1, [114, 27, 76]],
      [1, [113, 25, 75]],
      [1, [112, 24, 74]],
      [1, [110, 21, 71]],
      [1, [109, 19, 70]],
      [1, [108, 17, 69]],
      [1, [107, 16, 68]],
      [1, [107, 16, 68]],
      [1, [107, 15, 67]],
      [1, [106, 13, 66]],
      [1, [104, 11, 64]],
      [1, [103, 10, 63]],
      [1, [103, 9, 63]],
      [1, [102, 8, 62]],
      [1, [101, 7, 61]],
      [1, [101, 6, 60]],
      [1, [101, 5, 60]],
      [1, [99, 4, 58]],
      [1, [97, 3, 56]],
      [1, [95, 2, 55]],
      [1, [93, 5, 55]],
      [1, [91, 7, 55]],
      [1, [90, 8, 55]],
      [1, [90, 11, 56]],
      [1, [88, 13, 56]],
      [1, [84, 14, 54]],
      [1, [83, 12, 53]],
      [1, [83, 11, 53]],
      [1, [83, 10, 52]],
      [1, [83, 8, 51]],
      [1, [82, 7, 50]],
      [1, [81, 6, 51]],
      [1, [79, 4, 47]],
      [1, [79, 4, 47]],
      [1, [78, 3, 46]],
      [1, [77, 2, 45]],
      [1, [77, 1, 45]],
      [1, [74, 3, 44]],
      [1, [73, 5, 44]],
      [1, [72, 4, 43]],
      [1, [72, 3, 43]],
      [1, [69, 4, 44]],
      [1, [67, 6, 46]],
      [1, [64, 5, 44]],
      [1, [63, 7, 44]],
      [1, [63, 6, 44]],
      [1, [62, 6, 44]],
      [1, [62, 5, 43]],
      [1, [61, 4, 43]],
      [1, [61, 4, 42]],
      [1, [60, 4, 42]],
      [1, [60, 3, 42]],
      [1, [60, 3, 41]],
      [1, [60, 3, 40]],
      [1, [60, 3, 39]],
      [1, [60, 2, 38]],
      [1, [59, 3, 38]],
      [1, [58, 3, 36]],
      [1, [58, 3, 36]],
      [1, [58, 2, 36]],
      [1, [57, 3, 35]],
      [1, [57, 2, 35]],
      [1, [56, 1, 34]],
      [1, [56, 0, 34]],
      [1, [56, 0, 33]],
      [1, [55, 0, 33]],
      [1, [55, 0, 32]],
      [1, [54, 0, 32]],
      [1, [54, 0, 31]],
      [1, [53, 0, 31]],
      [1, [53, 0, 30]],
      [1, [52, 0, 30]],
      [1, [51, 0, 30]],
      [1, [50, 0, 29]],
      [1, [49, 0, 28]],
      [1, [48, 0, 27]],
      [1, [47, 0, 25]],
    ];
    let SCALE = {
      bounds: [MIN_VELOCITY_INTENSITY, MAX_VELOCITY_INTENSITY],
      gradient: {}, // 计算色值及step
    };

    let setColorScale = function setColorScale() {
      let n = parseFloat(
          ((MAX_VELOCITY_INTENSITY - MIN_VELOCITY_INTENSITY) / 255).toFixed(4)
        ),
        m = MIN_VELOCITY_INTENSITY;

      for (let i = 0; i < COLORSCALE.length; i++) {
        COLORSCALE[i][0] = m;
        m += n;
      }
      return COLORSCALE;
    };
    function segmentedColorScale(segments) {
      let points = [],
        interpolators = [],
        ranges = [],
        opacitys = [];

      for (let i = 0; i < segments.length - 1; i++) {
        points.push(segments[i + 1][0]);
        interpolators.push(
          colorInterpolator(segments[i][1], segments[i + 1][1])
        );
        ranges.push([segments[i][0], segments[i + 1][0]]);
        opacitys.push([segments[i][1][3], segments[i + 1][1][3]]);
      }

      return function (point, alpha) {
        let i;
        for (i = 0; i < points.length - 1; i++) {
          if (point <= points[i]) {
            break;
          }
        }

        let range = ranges[i];
        // alpha = opacitys[i][0] * 255;
        alpha = 0.7 * 255;
        return interpolators[i](proportion(point, range[0], range[1]), alpha);
      };
    }
    function colorInterpolator(start, end) {
      let r = start[0],
        g = start[1],
        b = start[2];
      let Δr = end[0] - r,
        Δg = end[1] - g,
        Δb = end[2] - b;
      return function (i, a) {
        return [
          Math.floor(r + i * Δr),
          Math.floor(g + i * Δg),
          Math.floor(b + i * Δb),
          a,
        ];
      };
    }
    function proportion(x, low, high) {
      return (clamp(x, low, high) - low) / (high - low);
    }
    let clamp = function clamp(x, low, high) {
      return Math.max(low, Math.min(x, high));
    };
    SCALE.gradient = segmentedColorScale(setColorScale());
    // let OVERLAY_ALPHA = Math.floor(0.7 * 255); //透明度

    let interpolateField = function interpolateField(
      grid,
      bounds,
      extent,
      callback
    ) {
      // var projection = {};
      // var mapArea = (extent.south - extent.north) * (extent.west - extent.east);
      // var velocityScale = VELOCITY_SCALE * Math.pow(mapArea, 0.4);
      let columns = [];
      let x = bounds.x;
      let mask = createMask();

      function interpolateColumn(x) {
        let column = [];

        for (var y = bounds.y; y <= bounds.yMax; y += 2) {
          // var coord = invert(x, y, extent);
          let coord = mapTar.getCoordinateFromPixel([x, y]);
          var color = [0, 0, 0, 1];

          if (coord) {
            var λ = coord[0],
              φ = coord[1];

            if (isFinite(λ)) {
              var wind = grid.interpolate(λ, φ);

              if (
                isValue(wind[2]) &&
                !isNaN(wind[2]) &&
                wind[2] >= MIN_VELOCITY_INTENSITY &&
                wind[2] <= MAX_VELOCITY_INTENSITY
              ) {
                // wind = distort(projection, λ, φ, x, y, velocityScale, wind, extent);
                column[y + 1] = column[y] = wind;
                // color = SCALE.gradient(wind[2], OVERLAY_ALPHA);
              }
            }
          }
          mask
            .set(x, y, color)
            .set(x + 1, y, color)
            .set(x, y + 1, color)
            .set(x + 1, y + 1, color);
        }

        columns[x + 1] = columns[x] = column;
      }

      (function batchInterpolate() {
        let start = Date.now();
        while (x < bounds.width) {
          interpolateColumn(x);
          x += 2;

          if (Date.now() - start > 1000) {
            setTimeout(batchInterpolate, 25);
            return;
          }
        }
        createField(columns, bounds, callback);
      })();
    };
    // let drawOverlay = function drawOverlay(field) {
    //   if (!field) return;
    //   let canvas = canvasWeather;
    //   let ctx = canvas.getContext('2d');
    //   ctx.clearRect(0, 0, 3000, 3000); // field.imageData.data = data

    //   ctx.putImageData(field.imageData, 0, 0);
    // }; // 开始绘制

    let animationLoop;

    var animate = function animate(bounds, field) {
      function weatherIntensityColorScale(min, max) {
        colorScale.indexFor = function (m) {
          return Math.max(
            0,
            Math.min(
              colorScale.length - 1,
              Math.round(((m - min) / (max - min)) * (colorScale.length - 1))
            )
          );
        };

        return colorScale;
      }

      var colorStyles = weatherIntensityColorScale(
        MIN_VELOCITY_INTENSITY,
        MAX_VELOCITY_INTENSITY
      );
      var buckets = colorStyles.map(function () {
        return [];
      });
      if (mapTar.getView().getZoom() > 8) PARTICLE_MULTIPLIER = 1 / 1000;
      else PARTICLE_MULTIPLIER = 1 / 2000;
      var particleCount = Math.round(
        bounds.width * bounds.height * PARTICLE_MULTIPLIER
      );

      if (isMobile()) {
        particleCount *= PARTICLE_REDUCTION;
      }

      var fadeFillStyle = "rgba(0, 0, 0, 0.97)";
      var particles = [];

      for (var i = 0; i < particleCount; i++) {
        particles.push(
          field.randomize({
            age: Math.floor(Math.random() * MAX_PARTICLE_AGE) + 0,
          })
        );
      }
      // const computeWindUV = (u, v) => {
      //   var value = Math.sqrt(u * u + v * v);
      //   var angle = Math.acos(u / value);
      //   if (v < 0) {
      //     angle = -angle;
      //   }
      //   angle = Math.round((angle / Math.PI) * 180 + 90);
      //   if (angle < 0) {
      //     angle = angle + 360;
      //   }
      //   angle = Math.round(360 - angle);
      //   value = value.toFixed(2);
      //   return angle
      // };
      function evolve() {
        buckets.forEach(function (bucket) {
          bucket.length = 0;
        });
        particles.forEach(function (particle) {
          if (particle.age > MAX_PARTICLE_AGE) {
            field.randomize(particle).age = 0;
          }

          var x = particle.x;
          var y = particle.y;
          var v = field(x, y);
          var m = v[2];

          if (m === null) {
            particle.age = MAX_PARTICLE_AGE;
          } else {
            let xt = 0;
            let yt = 0;
            if (weatherType === "wind") {
              xt = x + (v[0] * 1) / 3;
              yt = y - (v[1] * 1) / 3;
            } else if (weatherType === "current") {
              xt = x - v[0] * 5;
              yt = y + v[1] * 5;
            }
            // let angle = computeWindUV(v[0], v[1])
            // console.log(11, x,y,angle, Math.atan(v[1] / v[0]) * 180 / Math.PI, v[0], v[1])
            if (field(xt, yt)[2] !== null) {
              particle.xt = xt;
              particle.yt = yt;
              buckets[colorStyles.indexFor(m)].push(particle);
            } else {
              particle.x = xt;
              particle.y = yt;
            }
          }

          particle.age += 1;
        });
      }

      var g = canvasWeather.getContext("2d");
      g.lineWidth = PARTICLE_LINE_WIDTH;
      g.fillStyle = fadeFillStyle;
      g.globalAlpha = 0.6;

      function draw() {
        mapTar.render();
        var prev = "lighter";
        g.globalCompositeOperation = "destination-in";
        g.fillRect(bounds.x, bounds.y, bounds.width, bounds.height);
        g.globalCompositeOperation = prev;
        g.globalAlpha = 0.9;
        buckets.forEach(function (bucket, i) {
          if (bucket.length > 0) {
            g.beginPath();
            g.strokeStyle = colorStyles[i];
            bucket.forEach(function (particle) {
              g.moveTo(particle.x, particle.y);
              g.lineTo(particle.xt, particle.yt);
              particle.x = particle.xt;
              particle.y = particle.yt;
            });
            g.stroke();
          }
        });
      }

      var then = Date.now();

      (function frame() {
        animationLoop = requestAnimationFrame(frame);
        var now = Date.now();
        var delta = now - then;

        if (delta > FRAME_TIME) {
          then = now - (delta % FRAME_TIME);
          evolve();
          draw();
          array.push(new Date().getTime());
          // console.log(11, array)
          _this.bb();
        }
      })();
    };

    let updateData = function updateData(data, bounds, width, height, extent) {
      delete params.data;
      params.data = data;
      if (extent) start(bounds, width, height, extent);
    };

    let start = function start(bounds, width, height, extent) {
      let mapBounds = {
        south: deg2rad(extent[0][1]),
        north: deg2rad(extent[1][1]),
        east: deg2rad(extent[1][0]),
        west: deg2rad(extent[0][0]),
        width: width,
        height: height,
      };
      stop();
      buildGrid(gridData, function (grid) {
        interpolateField(
          grid,
          buildBounds(bounds, width, height),
          mapBounds,
          function (bounds, field) {
            // weathery.field = field;
            animate(bounds, field);
          }
        );
      });
    };

    let stop = function stop() {
      if (weathery.field) weathery.field.release();
      if (animationLoop) cancelAnimationFrame(animationLoop);
    };

    let shift = function shift(dx, dy) {
      let canvas = params.canvas,
        w = canvas.width,
        h = canvas.height,
        ctx = canvas.getContext("2d");

      if (w > dx && h > dy) {
        let clamp = function clamp(high, value) {
          return Math.max(0, Math.min(high, value));
        };

        let imageData = ctx.getImageData(
          clamp(w, -dx),
          clamp(h, -dy),
          clamp(w, w - dx),
          clamp(h, h - dy)
        );
        ctx.clearRect(0, 0, w, h);
        ctx.putImageData(imageData, clamp(w, dx), clamp(h, dy));
      }
    };

    let weathery = {
      params: params,
      start: start,
      stop: stop,
      update: updateData,
      shift: shift,
      createField: createField,
      interpolatePoint: interpolate,
      setData: setData,
    };
    return weathery;
  };

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id);
    };
  }

  var isMobile = function isMobile() {
    return /android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i.test(
      navigator.userAgent
    );
  };

  let createCanvas = function createCanvas(width, height, Canvas) {
    if (typeof document !== "undefined") {
      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      return canvas;
    } else {
      return new Canvas(width, height);
    }
  };

  let getDirection = function getDirection(uMs, vMs, angleConvention) {
    if (angleConvention.endsWith("CCW")) {
      vMs = vMs > 0 ? (vMs = -vMs) : Math.abs(vMs);
    }

    let velocityAbs = Math.sqrt(Math.pow(uMs, 2) + Math.pow(vMs, 2));
    let velocityDir = Math.atan2(uMs / velocityAbs, vMs / velocityAbs);
    let velocityDirToDegrees = (velocityDir * 180) / Math.PI + 180;

    if (angleConvention === "bearingCW" || angleConvention === "meteoCCW") {
      velocityDirToDegrees += 180;
      if (velocityDirToDegrees >= 360) velocityDirToDegrees -= 360;
    }

    return velocityDirToDegrees;
  };

  let getSpeed = function getSpeed(uMs, vMs, unit) {
    let velocityAbs = Math.sqrt(Math.pow(uMs, 2) + Math.pow(vMs, 2));

    if (unit === "k/h") {
      return meterSec2kilometerHour(velocityAbs);
    } else if (unit === "kt") {
      return meterSec2Knots(velocityAbs);
    } else {
      return velocityAbs;
    }
  };

  let meterSec2Knots = function meterSec2Knots(meters) {
    return meters / 0.514;
  };

  let meterSec2kilometerHour = function meterSec2kilometerHour(meters) {
    return meters * 3.6;
  };

  let superClass = mapApi.imageLayer;
  _inheritsLoose(seaWeather, superClass);
  let _this;
  let moveend, movestart;
  function seaWeather(options) {
    if (!options) {
      options = {};
    }
    _this = superClass.call(this, options) || this;
    _this._canvas = null;
    _this.data = options.data;
    _this.$Weather = null;
    _this.isClear = false;
    _this.options = options;

    if (isMobile()) {
      const fun = () => {
        if (_this._canvas) _this._canvas.style.display = "block";
        let size = options.map.getSize();
        if (!_this._canvas) {
          _this._canvas = createCanvas(size[0], size[1]);
        } else {
          _this._canvas.width = size[0];
          _this._canvas.height = size[1];
        }
        _this._canvas.id = "seaWeather";
        canvasWeather = _this._canvas;

        this._canvas.style.position = "absolute";
        _this.renderData();
      };
      const fun1 = () => {
        if (_this._canvas) _this._canvas.style.display = "none";
      };
      fun();
      moveend = options.map.on("moveend", fun, _this);
      movestart = options.map.on("movestart", fun1);
    } else {
      _this.setSource(
        new mapApi.ImageCanvasSource({
          logo: options.logo,
          state: options.state,
          attributions: options.attributions,
          resolutions: options.resolutions,
          canvasFunction: function canvasFunction(
            extent,
            resolution,
            pixelRatio,
            size
          ) {
            if (!_this._canvas) {
              _this._canvas = createCanvas(size[0], size[1]);
            } else {
              _this._canvas.width = size[0];
              _this._canvas.height = size[1];
            }
            canvasWeather = _this._canvas;

            _this.renderData();
            return _this._canvas;
          },
          ratio: 1,
        })
      );
      options.map.on("precompose", _this.redraw, _this);
    }

    return _this;
  }

  let _proto = seaWeather.prototype;

  _proto.renderData = function renderData() {
    if (_this._canvas && !_this.$Weather) {
      let _extent1 = _this._getExtent();
      _this.$Weather = new Weather({
        canvas: _this._canvas,
        projection: _this._getProjectionCode(),
        data: _this.getData(),
        param: _this.getParam(),
      });
      _this.$Weather.start(_extent1[0], _extent1[1], _extent1[2], _extent1[3]);
    } else if (_this._canvas && _this.$Weather) {
      let _extent2 = _this._getExtent();

      _this.$Weather.start(_extent2[0], _extent2[1], _extent2[2], _extent2[3]);
    }
  };

  _proto.getData = function getData() {
    return _this.data;
  };

  _proto.getParam = function getParam() {
    return _this.options;
  };

  let array = [];
  let date = null;
  let timeOut = null;
  _proto.ee = function ee() {
    // array.push(new Date().getTime())
    // console.log(22, array)
  };
  //movestart(移除+重绘)
  _proto.aa = function aa() {
    array = [];
    _this.dd();
    if (_this.$Weather) _this.$Weather.stop();
    _this.isClear = true;
  };
  //移除
  _proto.dd = function dd() {
    let canvas = canvasWeather;
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, 3000, 3000); // field.imageData.data = data
  };
  //绘制结束（刷新开始）
  _proto.bb = function bb() {
    date = null;
    _this.ee();
    _this.redraw();
  };
  //刷新开始
  _proto.redraw = function redraw() {
    if (!date) date = new Date().getTime();
    clearTimeout(timeOut);
    if (_this.isClear) {
      // _this.cc()
      return;
    }
    let size = mapTar.getSize();
    let extent = mapTar.getView().calculateExtent(size);
    let _extent = _this.options.extent || extent;
    _this.setExtent(_extent);
    timeOut = setTimeout(() => {
      if (new Date().getTime() - date < 3000) {
        _this.redraw();
        _this.isClear = true;
      }
      // _this.redraw()
    }, 100);
  };
  //moveend(刷新结束)
  _proto.ff = function ff() {
    _this.isClear = false;
    _this.renderData();
  };
  _proto.cc = function cc() {
    if (!date) date = new Date().getTime();
    let size = mapTar.getSize();
    let extent = mapTar.getView().calculateExtent(size);
    let _extent = _this.options.extent || extent;
    _this.setExtent(_extent);
    if (new Date().getTime() - date < 3000) {
      _this.cc();
    }
  };

  _proto._getExtent = function _getExtent() {
    let size = mapTar.getSize();
    let extent = mapTar.getView().calculateExtent(size);

    if (size && extent) {
      return [
        [
          [0, 0],
          [size[0], size[1]],
        ],
        size[0],
        size[1],
        [
          [extent[0], extent[1]],
          [extent[2], extent[3]],
        ],
      ];
    } else {
      return false;
    }
  };

  _proto.appendTo = function appendTo(map, layerIndex) {
    if (isMobile()) {
      let parent = document.querySelector(".ol-layers").children[0];
      if (parent.children[1]) {
        parent.insertBefore(this._canvas, parent.children[1]);
      } else
        document
          .querySelector(".ol-layers")
          .children[0].appendChild(this._canvas);

      // document.querySelector(".ol-layers").children[0].appendChild(this._canvas)
    } else {
      if (map && map instanceof mapApi.Map) {
        _this.set("originMap", map);
        // _this.getSource().projection_ = _this._getProjectionCode();
        // map.addLayer(_this);
        _this.getMap().getLayers().insertAt(layerIndex, _this);
        // _this.setZIndex(layerIndex)
      } else {
        throw new Error("not map object");
      }
    }
  };
  // _proto.appendTo = function appendTo() {
  //   // mapTar.getViewport().insertBefore(this._canvas, mapTar.getViewport().children[1])
  //   document.getElementsByClassName("ol-unselectable ol-layers")[0].children[0].appendChild(this._canvas)
  // }

  _proto.getPointData = function getPointData(coordinates) {
    let gridValue = _this.$Weather.interpolatePoint(
      coordinates[0],
      coordinates[1]
    );

    if (
      gridValue &&
      !isNaN(gridValue[0]) &&
      !isNaN(gridValue[1]) &&
      gridValue[2]
    ) {
      return {
        direction: getDirection(
          gridValue[0],
          gridValue[1],
          _this.options.angleConvention || "bearingCCW"
        ),
        speed: getSpeed(gridValue[0], gridValue[1], _this.options.speedUnit),
      };
    }
  };

  _proto.clearWeather = function clearWeather() {
    if (isMobile()) {
      let _map = mapTar;
      if (!_map) return;
      if (this._canvas) {
        let canvas = createCanvas(this._canvas.width, this._canvas.height);
        canvas.style.position = "absolute";
        canvas.id = "cacheCanvas";
        document.querySelector(".ol-layers").children[0].appendChild(canvas);
        // let ctx = canvas.getContext("2d");
        // ctx.drawImage(this._canvas, 0, 0, this._canvas.width, this._canvas.height)
        // ctx.putImageData(this._canvas.getContext("2d").getImageData(0, 0, this._canvas.width, this._canvas.height), 0, 0);
        canvas.remove();
      }
      if (_this.$Weather) _this.$Weather.stop();
      _this.isClear = true;
      // document.querySelector(".ol-layers").children[0].removeChild(this._canvas)
      if (this._canvas) this._canvas.remove();
      this._canvas = null;
      mapApi.unByKey(moveend);
      mapApi.unByKey(movestart);
      mapTar.renderSync();
    } else {
      let _map = mapTar;
      if (!_map) return;
      if (_this.$Weather) _this.$Weather.stop();
      _this.isClear = true;
      _map.removeLayer(_this);
      _map.un("precompose", _this.redraw, _this);
      mapTar.renderSync();
    }
  };

  _proto.setMap = function setMap(map) {
    _this ? _this.set("originMap", map) : "";
  };

  _proto.getMap = function getMap() {
    return _this.get("originMap");
  };

  _proto._getProjectionCode = function _getProjectionCode() {
    let code = "";
    let map = mapTar;

    if (map) {
      // code = map.getView() && map.getView().getProjection().getCode();
      code = map.getView() && map.getView().getProjection();
    } else {
      // code = 'EPSG:3857';
      code = mapApi.getProjection("EPSG:3857");
    }

    return code;
  };
  return new seaWeather(options);
};
const SeaWeather_Particle = function (options) {
  return new seaWeather(options);
};
// export default function (options) {
//   return new ol.layer.seaWeather(options)
// }
export default SeaWeather_Particle;
