import request from "@/utils/request";
// 雷达
export function getRadarData() {
  return request({
    url: "/mapWeather/ocean/rander.json",
    method: "get",
  });
}
// 云图
export function getCloudData() {
  return request({
    url: "/mapWeather/ocean/cloud.json",
    method: "get",
  });
}
