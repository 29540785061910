<template>
  <div>
    <map-base ref="mapCom" :map="map" />
  </div>
</template>
<script>
export default {
  name: "MapSwitch",
  props: ["isShow", "map"],
  components: {},
  data() {
    return {
      mapSwitch: this.$store.getters.mapSwitch,
      baseLayerIds: [], //基础图层Id数组,
      layerCountMax: this.$mapConfig.baseMapCountMax,
    };
  },
  watch: {
    "mapSwitch.selected": {
      handler(v) {
        if (v) {
          this.$refs.mapCom.removeAllBaseLayer();
          this.switchMapPost(v.type);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$refs.mapCom.removeAllBaseLayer();
    this.switchMapPost("vectorMap");
  },
  methods: {
    //传入方式调用
    switchMapPost(value) {
      this.changeBaseLayer(value);
    },
    /**
     * 切换天地
     */
    switchBaseMapTD(value) {
      if (value === "vectorMap") {
        let vecLayer = this.initTDvecLayer(true);
        vecLayer.map((v, k) => {
          this.$refs.mapCom.insertAtLayer(k, v);
        });
      } else if (value === "imageMap") {
        let imageLayer = this.initTDImageLayer(true);
        imageLayer.map((v, k) => {
          this.$refs.mapCom.insertAtLayer(k, v);
        });
        this.$refs.mapCom.insertAtLayer(
          this.layerCountMax - 1,
          this.addThemeLayer()
        );
      } else if (value === "htMap") {
        this.$refs.mapCom.insertAtLayer(
          0,
          this.initTileLayerZYX(
            this.$mapValue.mapUrl[1] + "20210602/",
            true,
            "EPSG:3857",
            "htLayer"
          )
        );
      } else if (value === "tltLayer") {
        this.$refs.mapCom.insertAtLayer(
          0,
          this.initTileLayerSplicing(
            [this.$mapValue.mapUrl[1] + "20210602/", this.$mapValue.mapUrl[5]],
            true,
            "EPSG:3857",
            "tltLayer",
            [7]
          )
        );
      } else if (value === "hlMap") {
        this.$refs.mapCom.insertAtLayer(
          0,
          this.initTileLayer(
            this.$mapValue.mapUrl[1] + "Layers/_alllayers/",
            true,
            "EPSG:3857",
            "hlLayer"
          )
        );
      }
      //补充图层到最大图层数目
      let baseLayerLength = parseInt(
        this.$refs.mapCom.getbaseMapLayer().length
      );
      for (let i = 0; i < this.layerCountMax - baseLayerLength; i++) {
        let layer = new this.mapApi.TileLayer({
          source: new this.mapApi.WMTSSource({}),
          id: "coverLayer" + i,
          type: "baseMap",
        });
        this.$refs.mapCom.insertAtLayer(baseLayerLength + i, layer);
      }
    },
    /**
     * 切换高德
     */
    switchBaseMapGD(value) {
      if (value === "vectorMap") {
        let vecLayer = this.initGDVecLayer(true);
        vecLayer.map((v, k) => {
          this.$refs.mapCom.insertAtLayer(k, v);
        });
      } else if (value === "imageMap") {
        let imageLayer = this.initGDImageLayer(true);
        imageLayer.map((v, k) => {
          this.$refs.mapCom.insertAtLayer(k, v);
        });
        this.$refs.mapCom.insertAtLayer(
          this.layerCountMax - 1,
          this.addThemeLayer()
        );
      } else if (value === "htMap") {
        this.$refs.mapCom.insertAtLayer(
          0,
          this.initTileLayerZYX(
            this.$mapValue.mapUrl[1] + "20210602/",
            true,
            "EPSG:3857",
            "htLayer"
          )
        );
      }
      //补充图层到最大图层数目
      let baseLayerLength = parseInt(
        this.$refs.mapCom.getbaseMapLayer().length
      );
      for (let i = 0; i < this.layerCountMax - baseLayerLength; i++) {
        let layer = new this.mapApi.TileLayer({
          source: new this.mapApi.WMTSSource({}),
          id: "coverLayer" + i,
          type: "baseMap",
        });
        this.$refs.mapCom.insertAtLayer(baseLayerLength + i, layer);
      }
    },
    /**
     * 切换arcgis
     */
    switchBaseMapArcgis(value) {
      if (value === "imageMap") {
        let imageLayer = this.addArcgisImageLayer();
        imageLayer.map((v, k) => {
          this.$refs.mapCom.insertAtLayer(k, v);
        });
        this.$refs.mapCom.insertAtLayer(
          this.layerCountMax - 1,
          this.addThemeLayer()
        );
      } else if (value === "hlMap") {
        this.$refs.mapCom.insertAtLayer(
          0,
          this.initTileLayer(
            this.$mapValue.mapUrl[1] + "Layers/_alllayers/",
            true,
            "EPSG:3857",
            "htLayer"
          )
        );
      } else if (value === "htMap") {
        this.$refs.mapCom.insertAtLayer(
          0,
          this.initTileLayerZYX(
            this.$mapValue.mapUrl[1] + "20210602/",
            true,
            "EPSG:3857",
            "htLayer"
          )
        );
      }
      //补充图层到最大图层数目
      let baseLayerLength = parseInt(
        this.$refs.mapCom.getbaseMapLayer().length
      );
      for (let i = 0; i < this.layerCountMax - baseLayerLength; i++) {
        let layer = new this.mapApi.TileLayer({
          source: new this.mapApi.WMTSSource({}),
          id: "coverLayer" + i,
          type: "baseMap",
        });
        this.$refs.mapCom.insertAtLayer(baseLayerLength + i, layer);
      }
    },
    /**
     * 切换底图
     */
    changeBaseLayer(value) {
      switch (this.$mapConfig.mapSwitch) {
        case 1:
          this.switchBaseMapTD(value);
          break;
        case 2:
          this.switchBaseMapGD(value);
          break;
        case 3:
          this.switchBaseMapArcgis(value);
          break;
      }
    },
    /**
     * 天地图矢量
     */
    initTDvecLayer() {
      let projection = this.mapApi.getProjection("EPSG:4326");
      let projectionExtent = projection.getExtent();
      let size = this.mapApi.getWidth(projectionExtent) / 256;
      let resolutions = [];
      for (let z = 2; z < 19; ++z) {
        resolutions[z] = size / Math.pow(2, z);
      }
      let layer1 = new this.mapApi.TileLayer({
        source: new this.mapApi.WMTSSource({
          name: "0-20级底图",
          url: "http://t{0-6}.tianditu.gov.cn/vec_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
          layer: "vec",
          style: "default",
          matrixSet: "c",
          format: "tiles",
          wrapX: true,
          tileGrid: new this.mapApi.WMTSTileGrid({
            origin: new this.mapApi.getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20,
            ],
          }),
        }),
        type: "baseMap",
        id: "tdvec",
      });
      let layer2 = new this.mapApi.TileLayer({
        source: new this.mapApi.WMTSSource({
          type: "baseMap",
          name: "0-20级注记",
          url: "http://t{0-6}.tianditu.gov.cn/cva_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
          layer: "cva",
          style: "default",
          matrixSet: "c",
          format: "tiles",
          wrapX: true,
          tileGrid: new this.mapApi.WMTSTileGrid({
            origin: new this.mapApi.getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20,
            ],
          }),
        }),
        type: "baseMap",
        id: "tdcva",
      });
      return [layer1, layer2];
    },
    /**
     * 天地图影像图
     */
    initTDImageLayer() {
      let projection = this.mapApi.getProjection("EPSG:4326");
      let projectionExtent = projection.getExtent();
      let size = this.mapApi.getWidth(projectionExtent) / 256;
      let resolutions = [];
      for (let z = 2; z < 19; ++z) {
        resolutions[z] = size / Math.pow(2, z);
      }
      let layer1 = new this.mapApi.TileLayer({
        source: new this.mapApi.WMTSSource({
          name: "0-20级影像底图",
          url: "http://t{0-6}.tianditu.gov.cn/img_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
          layer: "img",
          style: "default",
          matrixSet: "c",
          format: "tiles",
          wrapX: true,
          tileGrid: new this.mapApi.WMTSTileGrid({
            origin: new this.mapApi.getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20,
            ],
          }),
        }),
        type: "baseMap",
        id: "gdimg",
      });
      let layer2 = new this.mapApi.TileLayer({
        source: new this.mapApi.WMTSSource({
          name: "0-20级影像注记",
          url: "http://t{0-6}.tianditu.gov.cn/cia_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
          layer: "cia",
          style: "default",
          matrixSet: "c",
          format: "tiles",
          wrapX: true,
          tileGrid: new this.mapApi.WMTSTileGrid({
            origin: new this.mapApi.getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20,
            ],
          }),
        }),
        type: "baseMap",
        id: "gdcia",
      });
      return [layer1, layer2];
    },
    /**
     * 高德矢量
     */
    initGDVecLayer(visible) {
      let layer1 = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          crossOrigin: "anonymous",
          url: "http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7",
        }),
        name: "高德矢量",
        id: "vecLayer",
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push("vecLayer");
      return [layer1];
    },
    /**
     * 高德影像
     */
    initGDImageLayer(visible) {
      let layer1 = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          crossOrigin: "anonymous",
          url: "http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=2&style=6",
        }),
        name: "高德影像底图",
        id: "imageLayer",
        visible: visible,
        type: "baseMap",
      });
      let layer2 = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          crossOrigin: "anonymous",
          url: "http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=8",
        }),
        name: "高德影像注记",
        id: "imageCvaLayer",
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push("imageLayer");
      this.baseLayerIds.push("imageCvaLayer");
      return [layer1, layer2];
    },
    /**
     * zyx十六进制结构加载
     */
    initTileLayer(url, visible, projection, layerId) {
      //给8位字符串文件名补0
      function zeroPad(num, len, radix) {
        let str = num.toString(radix || 10);
        while (str.length < len) {
          str = "0" + str;
        }
        return str.toUpperCase();
      }
      // ol.source.XYZ添加瓦片地图的层
      let layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          tileUrlFunction: function (tileCoord) {
            let x = "C" + zeroPad(tileCoord[1], 8, 16);
            let y = "R" + zeroPad(tileCoord[2], 8, 16);
            let z = "L" + zeroPad(tileCoord[0], 2, 10);
            return url + z + "/" + y + "/" + x + ".png";
          },
          projection: projection,
        }),
        id: layerId,
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push(layerId);
      return layer;
    },
    /**
     * zyx结构加载
     */
    initTileLayerZYX(url, visible, projection, layerId) {
      // ol.source.XYZ添加瓦片地图的层
      let layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          url: url + "{z}/{y}/{x}.png",
          projection: projection,
        }),
        id: layerId,
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push(layerId);
      return layer;
    },
    /**
     * zxy结构加载
     */
    initTileLayerZXY(url, visible, projection, layerId) {
      // ol.source.XYZ添加瓦片地图的层
      let layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          url: url + "{z}/{x}/{y}.png",
          projection: projection,
        }),
        id: layerId,
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push(layerId);
      return layer;
    },
    /**
     * 级别整合
     */
    initTileLayerSplicing(url, visible, projection, layerId, level) {
      // ol.source.XYZ添加瓦片地图的层
      let layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          tileUrlFunction: (tileCoord) => {
            let u = "";
            let x = tileCoord[1];
            let y = tileCoord[2];
            let z = tileCoord[0];
            if (z <= level[0]) u = url[0] + z + "/" + y + "/" + x + ".png";
            else u = url[1] + z + "/" + x + "/" + y + ".png";
            return u;
          },
          projection: projection,
        }),
        id: layerId,
        visible: visible,
        type: "baseMap",
      });
      this.baseLayerIds.push(layerId);
      return layer;
    },
    /**
     * 添加专题图
     */
    addThemeLayer: function () {
      return new this.mapApi.VectorLayer({
        id: this.layerId,
        name: this.layerId,
        type: "baseMap",
        source: new this.mapApi.VectorSource({
          projection: "EPSG:4326",
          url: this.$mapValue.mapUrl[1] + "json/themeMap.json",
          format: new this.mapApi.GeoJSON(),
        }),
        visible: true,
        style: (v) => {
          return new this.mapApi.Style({
            fill: new this.mapApi.Fill({
              color: "rgba(59,136,237,0.5)",
            }),
            // stroke: new this.mapApi.Stroke({
            //   width: 2,
            //   color: "#ffe036"
            // }),
            text: new this.mapApi.Text({
              font: "bold 15px 微软雅黑",
              offsetX: 0,
              offsetY: 40,
              text: v.get("text"),
              fill: new this.mapApi.Fill({ color: "#0059bd" }),
              textBaseline: "bottom",
              stroke: new this.mapApi.Stroke({
                // 线样式
                color: "#fff",
                width: 1,
              }),
            }),
          });
        },
      });
    },
    /**
     * esri影像图
     */
    addArcgisImageLayer() {
      const layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          url: "https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        }),
        id: "arcgisImageLayer",
        type: "baseMap",
      });
      return [layer];
    },
    /**
     * esri矢量图
     */
    addArcgisVecLayer() {
      const layer = new this.mapApi.TileLayer({
        source: new this.mapApi.XYZSource({
          url: "https://server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
        }),
        id: "arcgisVecLayer",
        type: "baseMap",
      });
      return [layer];
    },

    //--------------------------------------------------底图切换end----------------------------------------------------------------------------------------------------------------------
  },
};
</script>
<style lang="scss" scoped></style>
