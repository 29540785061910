<template>
  <div class="list">
    <ul>
      <li
        v-for="(item, index) in data"
        :key="item.id"
        class="list-li"
        @click="getPath(item)"
      >
        <div
          class="icon"
          :style="{ backgroundColor: fontBackColor[index] }"
        ></div>
        <div class="ty-name" :style="{ backgroundColor: fontBackColor[index] }">
          {{ item.TYPHOON_ID }}{{ item.CHN_NAME }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import icon from "../../assets/image/map/typhoon/ty.png";
export default {
  name: "List",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      icon,
      fontBackColor: ["#FFAD31", "#35BF9E", "#35BF9E", "#2698F7"],
    };
  },
  methods: {
    getPath(item) {
      this.$emit("getPath", item.TYPHOON_ID);
    },
  },
};
</script>

<style scoped>
.list {
  position: absolute;
  z-index: 2000;
  top: 25px;
  left: 5px;
}

.list-li {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.icon {
  background: url("../../assets/image/map/typhoon/ty.png") center no-repeat;
  background-color: #fdac34;
  width: 30px;
  height: 30px;
  background-size: 50% 50%;
  border-radius: 50%;
}

.ty-name {
  position: relative;
  line-height: 2em;
  background-color: #fdac34;
  color: #fff;
  border-radius: 1em;
  padding: 0 2em 0 3em;
  mask-image: radial-gradient(
    transparent 0,
    transparent 1.25em,
    black 1.25em,
    black
  );
  mask-size: 100em 100em;
  mask-repeat: no-repeat;
  mask-position: -48.75em center;
  margin-left: -30px;
}
</style>
