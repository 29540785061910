<template>
  <div>
    <map-base ref="mapCom" :map="map" />
    <div v-for="(data, index) in layerIds" :key="index">
      <component
        v-if="data.isTrue"
        :is="data.component"
        :ref="data.layerId"
        :map="map"
        :layer-id="data.layerId"
        :st="data.showType"
        :param="data.param"
        @changDistribute="changDistribute"
        @changParticle="changParticle"
        @changGridangle="changGridangle"
        @changGridvalue="changGridvalue"
        ><slot
      /></component>
    </div>
  </div>
</template>
<script>
export default {
  name: "Weather",
  props: ["map"],
  components: {},
  inject: [
    "dealTimeLine",
    "SeaWeather_Distribute",
    "SeaWeather_Particle",
    "SeaWeather_GridValue",
    "SeaWeather_GridAngle",
  ],
  data() {
    return {
      distribute_tar: null,
      particle_tar: null,
      gridValue_tar: null,
      gridAngle_tar: null,

      layerIds: [
        {
          layerId: "wind",
          resolution: "1.0",
          center: [120, 36],
          data: [],
          param: {},
          isTrue: false,
          showType: {
            distribute_select: { type: 1, checked: true, layerCount: 1 },
            particle_select: { type: 2, checked: false, layerCount: 2 },
            gridValue_select: { type: 3, checked: false, layerCount: 3 },
            gridAngle_select: { type: 4, checked: false, layerCount: 0 },
          },
          baseLayerLength: 0,
          component: () =>
            import("@/components/map/map-weather/weather/wind.vue"),
        },
        {
          layerId: "current",
          resolution: "1.0",
          center: [120, 36],
          data: [],
          param: {},
          isTrue: false,
          showType: {
            distribute_select: { type: 1, checked: false, layerCount: 0 },
            particle_select: { type: 2, checked: true, layerCount: 2 },
            gridValue_select: { type: 3, checked: false, layerCount: 3 },
          },
          baseLayerLength: 0,
          component: () =>
            import("@/components/map/map-weather/weather/current.vue"),
        },
        {
          layerId: "wave",
          resolution: "1.0",
          center: [120, 36],
          data: [],
          param: {},
          isTrue: false,
          showType: {
            distribute_select: { type: 1, checked: true, layerCount: 0 },
            gridValue_select: { type: 3, checked: false, layerCount: 3 },
          },
          baseLayerLength: 0,
          component: () =>
            import("@/components/map/map-weather/weather/wave.vue"),
        },
      ],
      bindWeather: null,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    getShowWeather(param) {
      this.clear();
      this.bindWeather = null;
      this.layerIds.map((lar) => {
        if (lar.layerId === param.type) lar.isTrue = param.checked;
        else lar.isTrue = false;
        if (lar.isTrue) {
          this.bindWeather = lar;
        }
      });
    },
    infoWin(param) {
      this.$refs[this.bindWeather.layerId][0].infoWin(param);
    },
    /**
     * 获取风级
     */
    getWindLevel(value) {
      let level = 0;
      if (value <= 0.2) level = 0;
      else if (value <= 1.5) level = 1;
      else if (value <= 3.3) level = 2;
      else if (value <= 5.4) level = 3;
      else if (value <= 7.9) level = 4;
      else if (value <= 10.7) level = 5;
      else if (value <= 13.8) level = 6;
      else if (value <= 17.1) level = 7;
      else if (value <= 20.7) level = 8;
      else if (value <= 24.4) level = 9;
      else if (value <= 28.4) level = 10;
      else if (value <= 32.6) level = 11;
      else level = 12;
      return level;
    },
    addLayer(par) {
      const funDistribute = (par) => {
        if (par.type === 1) {
          if (this.distribute_tar) this.distribute_tar.clearWeather();
          this.distribute_tar = new this.SeaWeather_Distribute(param);
          this.distribute_tar.appendTo(
            this.map,
            this.bindWeather.baseLayerLength + par.layerCount
          );
        }
      };
      const funParticle = (par) => {
        if (par.type === 2) {
          if (this.particle_tar) this.particle_tar.clearWeather();
          this.particle_tar = new this.SeaWeather_Particle(
            Object.assign(param, { colorScale: ["rgb(36,104, 180)"] })
          );
          this.particle_tar.appendTo(
            this.map,
            this.bindWeather.baseLayerLength + par.layerCount
          );
        }
      };
      const funGridValue = (par) => {
        if (par.type === 3) {
          if (this.gridValue_tar) this.gridValue_tar.clearWeather();
          this.gridValue_tar = new this.SeaWeather_GridValue(param);
          this.gridValue_tar.appendTo(
            this.map,
            this.bindWeather.baseLayerLength + par.layerCount
          );
        }
      };
      const funGridAngle = (par) => {
        if (par.type === 4) {
          if (this.gridAngle_tar) this.gridAngle_tar.clearWeather();
          this.gridAngle_tar = new this.SeaWeather_GridAngle(param);
        }
      };
      var data = this.bindWeather.data;
      if (!data || (data && data.length === 0)) return;
      let param = {
        data: data,
        mapApi: this.mapApi,
        map: this.map,
        minValue: this.bindWeather.param.minValue,
        maxValue: this.bindWeather.param.maxValue,
        weatherType: this.bindWeather.layerId,
      };
      if (!par) {
        for (let i in this.bindWeather.showType) {
          if (this.bindWeather.showType[i].checked) {
            par = this.bindWeather.showType[i];
            funDistribute(par);
            funParticle(par);
            funGridValue(par);
            funGridAngle(par);
          }
        }
      } else {
        funDistribute(par);
        funParticle(par);
        funGridValue(par);
        funGridAngle(par);
      }
    },
    // 默认
    changDistribute(data) {
      if (data.checked) {
        this.addLayer(data);
      } else {
        if (this.distribute_tar) this.distribute_tar.clearWeather();
      }
      this.infoFlag(data.checked);
    },
    // 粒子
    changParticle(data) {
      if (data.checked) {
        this.addLayer(data);
      } else {
        if (this.particle_tar) this.particle_tar.clearWeather();
      }
      this.infoFlag(data.checked);
    },
    // 数值
    changGridvalue(data) {
      if (data.checked) {
        this.addLayer(data);
      } else {
        if (this.gridValue_tar) this.gridValue_tar.clearWeather();
      }
      this.infoFlag(data.checked);
    },
    // 风羽
    changGridangle(data) {
      if (data.checked) {
        this.addLayer(data);
      } else {
        if (this.gridAngle_tar) this.gridAngle_tar.clearWeather();
      }
      this.infoFlag(data.checked);
    },

    infoFlag(flag) {
      let count = 0;
      for (let i in this.bindWeather.showType) {
        if (this.bindWeather.showType[i].checked) count++;
      }
      // console.log("infoFlag", count, flag);
      if (count === 1 && flag) this.dealTimeLine(true);
      else if (count === 0 && !flag) {
        this.$refs.mapCom.removeSeaWeatherInfo();
        this.dealTimeLine(false);
      }
    },
    clear() {
      if (this.distribute_tar) this.distribute_tar.clearWeather();
      if (this.particle_tar) this.particle_tar.clearWeather();
      if (this.gridValue_tar) this.gridValue_tar.clearWeather();
      if (this.gridAngle_tar) this.gridAngle_tar.clearWeather();
    },
  },
  beforeDestroy() {
    this.clear();
  },
};
</script>
<style lang="scss" scoped></style>
