<template>
  <div v-if="isShow" id="coord" ref="coord" class="coordStyle">
    <div class="ship-window">
      <div>全部船舶：{{ ship.all }}</div>
      <div>在线北斗船舶：{{ ship.bd }}</div>
      <div>在线AIS船舶：{{ ship.ais }}</div>
    </div>
    <div class="location-window">
      经度：{{ lonlat.lon }} , 纬度：{{ lonlat.lat }}
    </div>
  </div>
</template>
<script>
export default {
  name: "MapLonlat",
  props: ["map", "isShow"],
  components: {},
  data() {
    return {
      lonlat: {
        lon: null,
        lat: null,
      },
      ship: {
        all: "0",
        bd: "0",
        ais: "0",
      },
    };
  },
  watch: {},
  mounted() {
    this.setLonLat(this.map.getView().getCenter());
    this.bindEvent();
  },
  methods: {
    searchShip() {
      // console.log(111, this.shipName);
    },
    bindEvent() {
      this.map.on("pointermove", this.touchOnly);
    },
    touchOnly(e) {
      const lonlat = this.map.getCoordinateFromPixel(e.pixel);
      this.setLonLat(lonlat);
    },
    setLonLat(lonlat) {
      this.lonlat = {
        lon: this.$formatDegree(lonlat[0].toFixed(6)),
        lat: this.$formatDegree(lonlat[1].toFixed(6)),
      };
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.coordStyle {
  position: fixed;
  height: 2rem;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  display: flex;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  flex-direction: row;
  align-items: center;
  bottom: 0rem;
  z-index: 10;
  font-size: 0.9rem;
  justify-content: center;
  background: #e6f0fa;
  color: black;

  & > .ship-window {
    height: 1.6rem;
    background: white;
    display: flex;
    flex: 2;
    padding: 0 0.5rem;
    flex-direction: row;
    align-items: center;
    border: 1px solid #d1d1d1;
    justify-content: space-between;
  }

  & > .location-window {
    height: 1.6rem;
    background: white;
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px solid #d1d1d1;
    justify-content: center;
    text-align: center;
    margin-left: 0.5rem;
  }
}
</style>
