<template>
  <div>
    <map-base ref="mapCom" :map="map" />
  </div>
</template>
<script>
export default {
  name: "MapServices",
  props: ["map"],
  components: {},
  data() {
    return {
      selectedLayer: null, //区分单击的图层]
      layerId: this.$mapValue.layer[9].layerId,
    };
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  methods: {
    /**
    鼠标滑动事件
     */
    touchOnly() {},
    /**
     * 地图单击事件
     * @param {*} evt
     */
    singleClick(evt) {
      if (!evt.coordinate) return;
      let serviceLayerInfoWin = true;
      this.map.forEachFeatureAtPixel(evt.pixel, () => {
        serviceLayerInfoWin = false;
      });
      if (serviceLayerInfoWin) {
        this.map.forEachLayerAtPixel(evt.pixel, (layer) => {
          if (!layer.get("type") || layer.get("type") !== "tileLayer") return;
          let url = layer
            .getSource()
            .getFeatureInfoUrl(
              evt.coordinate,
              this.map.getView().getResolution(),
              layer.get("crs"),
              { INFO_FORMAT: "application/json" }
            );
          this.readJson(
            url + "&FEATURE_COUNT=1000",
            evt.coordinate,
            layer.get("id")
          );
        });
      }
    },
    /**
     * 添加服务图层
     */
    getServices(servicesData, type) {
      if (!servicesData) return;
      let servicesSource = new this.mapApi.TileWMSSource({
        crossOrigin: "anonymous",
        params: {
          LAYERS: servicesData.layers,
          VERSION: servicesData.version,
          CRS: servicesData.crs ? servicesData.crs : servicesData.srs,
          WIDTH: servicesData.width,
          HEIGHT: servicesData.heigtht,
        },
        projection: servicesData.crs ? servicesData.crs : servicesData.srs,
        url: servicesData.url + "wms",
      });
      let layer = new this.mapApi.TileLayer({
        source: servicesSource,
        id: servicesData.layerId,
        type: type ? type : "tileLayer",
        crs: servicesData.crs ? servicesData.crs : servicesData.srs,
      });
      let baseLayerLength = parseInt(
        this.$refs.mapCom.getAllTileLayer().length
      );
      this.$refs.mapCom.insertAtLayer(baseLayerLength, layer);
      this.map.render();
    },
    /***
     * 移除服务图层
     */
    removeServices(layerId) {
      if (!layerId) return;
      this.$refs.mapCom.removeLightMarkerLayer();
      this.$refs.mapCom.removeFlashMarker();
      this.$refs.mapCom.removeLayer(layerId, 1);
      this.$refs.mapCom.closeInfoWin(null, layerId);
    },
    /**
     * 清空所有tileLayer
     */
    removeTiledServices() {
      this.$refs.mapCom.removeLightMarkerLayer();
      this.$refs.mapCom.removeFlashMarker();
      this.$refs.mapCom.removeAllServiceLayer();
      this.$refs.mapCom.closeInfoWin(null, this.layerId);
    },
    /**
     * json形式读取feature
     */
    readJson(url, coordinate, layerId) {
      const fun = (feature) => {
        // console.log(feature);
        let source = new this.mapApi.VectorSource();
        let features = new this.mapApi.GeoJSON().readFeatures(feature);
        source.addFeatures(features);
        //线图层不添加高亮效果
        if (
          source.getFeatures()[0] &&
          (source.getFeatures()[0].getGeometry() instanceof
            this.mapApi.MultiLineString ||
            source.getFeatures()[0].getGeometry() instanceof
              this.mapApi.LineString)
        )
          return;
        else if (
          source.getFeatures()[0] &&
          (source.getFeatures()[0].getGeometry() instanceof
            this.mapApi.MultiPoint ||
            source.getFeatures()[0].getGeometry() instanceof this.mapApi.Point)
        ) {
          let v = source.getFeatures()[0];
          this.$refs.mapCom.addLightMarkerLayer(
            v.getGeometry(),
            new this.mapApi.Circle({
              radius: 2,
              stroke: new this.mapApi.Stroke({
                color: "rgba(0, 0, 0, 0.7)",
              }),
              fill: new this.mapApi.Fill({
                color: "rgba(0,54,255,0.4)",
              }),
            }),
            new this.mapApi.Fill({
              color: "pink",
            }),
            new this.mapApi.Stroke({
              width: 2,
              color: "red",
            }),
            new this.mapApi.Text({
              font: "bold 14px 微软雅黑",
              offsetX: 0,
              offsetY: 50,
              scale: 1,
              text: v.get("名称"),
              fill: new this.mapApi.Fill({ color: "#00dd53" }),
              textBaseline: "bottom",
              stroke: new this.mapApi.Stroke({
                // 线样式
                color: "#000",
                width: 2,
              }),
            })
          );

          //记录单击图层,避免同一位置有不同图层
          this.selectedLayer = {
            pixel: coordinate,
          };
          this.$emit("click", {
            coordinate: coordinate,
            layerId: layerId,
            moduleId: this.layerId,
            properties: v.getProperties(),
          });
        } else {
          //同一图层下,当前位置如果有多个feature,则用面积区分,用以获取最上层的feature信息-----------------------------------------
          let area;
          if (source.getFeatures().length !== 0) {
            area = source.getFeatures()[0].getGeometry().getArea() * 10000;
            source.getFeatures().map((v) => {
              if (v.getGeometry().getArea() * 10000 < area) {
                area = v.getGeometry().getArea() * 10000;
              }
            });
          }
          source.getFeatures().map((v) => {
            if (
              (this.selectedLayer && this.selectedLayer.pixel === coordinate) ||
              //匹配相同面积的feature
              area !== v.getGeometry().getArea() * 10000
            ) {
              return;
            }
            if (
              (v.getGeometry() instanceof this.mapApi.MultiPolygon &&
                v.getGeometry().intersectsCoordinate(coordinate)) ||
              (v.getGeometry() instanceof this.mapApi.Polygon &&
                v.getGeometry().intersectsCoordinate(coordinate)) ||
              area === v.getGeometry().getArea() * 10000
            ) {
              this.$refs.mapCom.addLightMarkerLayer(
                v.getGeometry(),
                new this.mapApi.Circle({
                  radius: 2,
                  stroke: new this.mapApi.Stroke({
                    color: "rgba(0, 0, 0, 0.7)",
                  }),
                  fill: new this.mapApi.Fill({
                    color: "rgba(0,54,255,0.4)",
                  }),
                }),
                new this.mapApi.Fill({
                  color: "pink",
                }),
                new this.mapApi.Stroke({
                  width: 2,
                  color: "red",
                }),
                new this.mapApi.Text({
                  font: "bold 14px 微软雅黑",
                  offsetX: 0,
                  offsetY: 50,
                  scale: 1,
                  text: v.get("名称"),
                  fill: new this.mapApi.Fill({ color: "#00dd53" }),
                  textBaseline: "bottom",
                  stroke: new this.mapApi.Stroke({
                    // 线样式
                    color: "#000",
                    width: 2,
                  }),
                })
              );
              //记录单击图层,避免同一位置有不同图层
              this.selectedLayer = {
                pixel: coordinate,
              };
              this.$emit("click", {
                coordinate: coordinate,
                layerId: layerId,
                moduleId: this.layerId,
                properties: v.getProperties(),
              });
            }
          });
        }
      };

      this.$axios({
        method: "get",
        url: url,
      }).then((result) => {
        fun(result.data);
      });
    },
    /***
     * 获取地图服务参数
     */
    getMapUrlData(address) {
      address = decodeURIComponent(address);
      const servicesData = {
        url: address.split("wms?")[0],
        layers: "",
        srs: "",
        crs: "",
        version: "",
        width: "",
        height: "",
        layerId: "",
      };
      try {
        var urldata = address.split("wms?")[1].toString().split("&");
        urldata.forEach((item) => {
          servicesData[item.split("=")[0]] = item.split("=")[1];
        });
        servicesData.layerId = servicesData.layers.split(":")[1];
        return servicesData;
      } catch (e) {
        return e;
      }
    },
  },
};
</script>
