// import "./mapTool.css";
import Draw from "../draw/draw.js";
import Grid from "../grid/grid.js";
class MapTool {
  constructor(props) {
    this.map = props.map;
    this.mapApi = props.mapApi;
    this.callData = null; //回调数据
    this.dragZoomIn; //拉框缩小
    this.dragZoomOut; //拉框放大

    //默认工具条专用
    this.mapTool;
    this.mapTip;
    this.mapDom = props.mapContainer;

    this.singleTool = props.singleTool;
    this.crossTool = props.crossTool;
    this.updateTool = props.updateTool;
    this.identifyTool = props.identifyTool;
    this.$mapConfig = props.mapConfig;
    this.allDrawContainer = [];
    this.draw = null;

    this.addDragZoomIn(false);
    this.addDragZoomOut(false);
  }
  setDefaultInteraction() {
    this.removeDraw();
    this.removeGrid();
    this.deactiveZoomIn();
    this.deactiveZoomOut();
    this.mapDom.style.cursor = "default";
  }

  positionTo(position, zoom, degree) {
    if (!zoom) zoom = this.map.getView().getZoom();
    if (!degree) degree = 360;
    this.map.getView().animate({
      center: position,
      duration: 2000,
      zoom: zoom,
      rotation:
        ((this.map.getView().getRotation() + Math.PI * 2) / 360) * degree,
    });
  }
  addMapToolEvent(t, t1, t2, t3) {
    this.mapDom.style.cursor = "default";
    if (this.crossTool) this.deactiveDraw();
    //跨用具当且只取消工具，但保留工具图层
    else {
      if (t !== "Clear") {
        this.removeDraw();
        this.removeGrid();
      }
    }
    this.removeMessureLast();
    this.deactiveZoomIn();
    this.deactiveZoomOut();
    this._mapToolConductEvt(t, t1, t2, t3);
    return this;
  }
  //自定义专用
  _mapToolConductEvt(type, type1, type2, type3) {
    // this.setCallData()
    switch (type) {
      case "Pan": //平移
        this.setPan();
        this.setCallData("Pan", []);
        break;
      case "Zoomin": //放大
        this.dragMapZoomIn();
        this.setCallData("Zoomin", []);
        break;
      case "Zoomout": //缩小
        this.dragMapZoomOut();
        this.setCallData("Zoomout", []);
        break;
      case "Extent": //框选
        this.setExtent(type, "Extent_Polygon");
        break;
      case "Direction": //方位角
        this.setDirection(type, "Direction");
        break;
      case "marker": //设置标识
        this.setMarker("Point", "Marker");
        break;
      case "Pos": //定位
        this.positionTo(type1);
        this.setCallData("Pos", type1);
        break;
      case "LineString": //测量
        this.initMeasureTools("Messure");
        break;
      case "View": //默认视图
        this.setDefaultExtent();
        this.setCallData("View", []);
        break;
      case "Grid": //格网
        this.removeGrid();
        this.addGrid(type1);
        this.setCallData("Grid", []);
        break;
      case "IdentifyPost": //传入方式执行
        this.addIdentifyPost(type1, type2, type3, "Calculate");
        break;
      case "Clear":
        this.removeAllDraw();
        this.removeGrid();
        this.setCallData("Clear", []);
        break;
      case "Deactive":
        this.deactiveDraw();
        this.setCallData("Deactive", []);
        break;
      default:
        this.setCallData("other", []);
        break;
    }
  }
  removeAllDraw() {
    this.allDrawContainer.map((v) => {
      if (v) v.removeLayer();
    });
  }
  removeDraw() {
    if (this.draw) this.draw.removeLayer();
  }
  deactiveDraw() {
    if (this.draw) this.draw.deactiveDraw();
  }
  //移除格网
  removeGrid() {
    if (this.grid) this.grid.removeGrid();
  }
  //移除最后一次测量相关
  removeMessureLast() {
    if (this.draw) {
      this.draw.removeMessureListener();
      this.draw.removeMessureOverlay(1);
    }
  }
  showGridLayer() {
    if (this.grid) this.grid.addLayer();
  }
  deactiveZoomIn() {
    this.dragZoomIn.setActive(false);
  }
  deactiveZoomOut() {
    this.dragZoomOut.setActive(false);
  }
  activeZoomIn() {
    this.dragZoomIn.setActive(true);
  }
  activeZoomOut() {
    this.dragZoomOut.setActive(true);
  }
  // 放大
  addDragZoomIn(status) {
    this.dragZoomIn = new this.mapApi.InteractionDragZoom({
      condition: this.mapApi.always,
      out: false,
    });
    this.map.addInteraction(this.dragZoomIn);
    this.dragZoomIn.setActive(status);
  }
  //缩小
  addDragZoomOut(status) {
    this.dragZoomOut = new this.mapApi.InteractionDragZoom({
      condition: this.mapApi.always,
      out: true,
    });
    this.map.addInteraction(this.dragZoomOut);
    this.dragZoomOut.setActive(status);
  }
  //平移
  setPan() {
    this.deactiveZoomIn();
    this.deactiveZoomOut();
    this.mapDom.style.cursor = "move";
  }
  //初始化放大
  dragMapZoomIn() {
    this.map.getView().setZoom(this.map.getView().getZoom() + 1);
  }
  //初始化缩小
  dragMapZoomOut() {
    this.map.getView().setZoom(this.map.getView().getZoom() - 1);
  }
  //拉框查询
  setExtent(type, rootType) {
    this.draw = new Draw({
      map: this.map,
      mapApi: this.mapApi,
      setCallData: this.setCallData.bind(this),
      rootType: rootType,
      singleTool: this.singleTool,
      updateTool: this.updateTool,
      identifyTool: this.identifyTool,
      mapConfig: this.$mapConfig,
    });
    this.draw.drawingTool(type);
    this.allDrawContainer.push(this.draw);
  }
  //拉框查询
  setDirection(type, rootType) {
    this.draw = new Draw({
      map: this.map,
      mapApi: this.mapApi,
      setCallData: this.setCallData.bind(this),
      rootType: rootType,
      singleTool: this.singleTool,
      updateTool: this.updateTool,
      identifyTool: this.identifyTool,
      mapConfig: this.$mapConfig,
    });
    this.draw.drawingTool(type);
    this.allDrawContainer.push(this.draw);
  }
  //设置标注
  setMarker(type, rootType) {
    this.draw = new Draw({
      map: this.map,
      mapApi: this.mapApi,
      setCallData: this.setCallData.bind(this),
      rootType: rootType,
      singleTool: this.singleTool,
      updateTool: this.updateTool,
      identifyTool: this.identifyTool,
      mapConfig: this.$mapConfig,
    });
    this.draw.drawingTool(type);
    this.allDrawContainer.push(this.draw);
  }
  //默认视野
  setDefaultExtent() {
    this.map
      .getView()
      .fit([
        117.30224568387656, 32.58626862991018, 123.35488469241555,
        43.35189587976487,
      ]);
  }
  //经纬网格化
  addGrid(type) {
    this.grid = new Grid({
      map: this.map,
      mapApi: this.mapApi,
      mapConfig: this.$mapConfig,
      // extent: [119.120916, 34.672952, 120.045458, 35.430688],
    }).addGrid(type);
    this.map.render();
  }
  addIdentifyPost(type, type1, type2, rootType) {
    this.draw = new Draw({
      map: this.map,
      mapApi: this.mapApi,
      setCallData: this.setCallData.bind(this),
      rootType: rootType,
      singleTool: this.singleTool,
      updateTool: this.updateTool,
      identifyTool: this.identifyTool,
      mapConfig: this.$mapConfig,
    });
    this.draw.drawPost(type, type1, type2);
    this.allDrawContainer.push(this.draw);
  }
  //测量工具
  initMeasureTools(rootType) {
    this.removeMessureLast();
    this.draw = new Draw({
      map: this.map,
      mapApi: this.mapApi,
      setCallData: this.setCallData.bind(this),
      rootType: rootType,
      singleTool: this.singleTool,
      updateTool: this.updateTool,
      identifyTool: this.identifyTool,
      mapConfig: this.$mapConfig,
    });
    this.draw.drawingTool("Messure");
    this.allDrawContainer.push(this.draw);
  }
  removeMessureInteraction() {
    this.map.removeInteraction(this.drawMessure);
  }
  setCallData(callType, data) {
    this.callData = {
      type: callType,
      data: data,
    };
  }
  update(param) {
    if (this.draw)
      for (let key in param) {
        if (Object.prototype.hasOwnProperty.call(this, key))
          this.draw[key] = param[key];
      }
  }
}
export default MapTool;
