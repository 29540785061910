<template>
  <div v-if="isShow">
    <!-- 最小化样式 -->
    <div
      v-if="!visible"
      :id="id"
      @mouseover="mouseover"
      @mouseout="mouseout"
      class="hl_dialog_preview"
      ref="preview"
    >
      <!-- 标题区域 -->
      <div class="hl-dialog__header" :style="{ height: headerHeight }">
        <!-- 标题 -->
        <slot name="min_title">
          <span>{{ title }}</span>
        </slot>
        <!-- 最小化按钮 -->
        <div
          v-if="topeArea.indexOf('s') >= 0"
          @click="setRestore"
          type="button"
          class="hl-dialog__header-btn hl-dialog__headerbtn"
        >
          <em class="el-icon el-icon-full-screen"></em>
        </div>
        <!-- 关闭按钮 -->
        <div
          v-if="topeArea.indexOf('c') >= 0"
          @click="closeDialog"
          type="button"
          class="hl-dialog__header-btn"
        >
          <em class="el-icon el-icon-close"></em>
        </div>
      </div>
    </div>
    <!-- 正常样式 -->
    <div
      v-else
      :id="id"
      class="hl-dialog"
      :style="{ top, left, right, bottom, width, height, zIndex }"
    >
      <!-- 背景 -->
      <div
        class="hl-dialog__bg"
        :style="{ 'background-image': `url(${bgimg})` }"
      ></div>
      <!-- 拖拽区域 -->
      <div @mousedown="mousedown" class="mousedown" />
      <!-- 标题区域 -->
      <div class="hl-dialog__header" :style="{ height: headerHeight }">
        <!-- 标题 -->
        <span v-if="topeArea.indexOf('t') >= 0" class="hl-dialog__title">{{
          title
        }}</span>
        <!-- 最小化按钮 -->
        <div
          v-if="topeArea.indexOf('s') >= 0"
          @click="setMin"
          type="button"
          class="hl-dialog__header-btn hl-dialog__headerbtn"
        >
          <em class="el-icon el-icon-minus"></em>
        </div>
        <!-- 关闭按钮 -->
        <div
          v-if="topeArea.indexOf('c') >= 0"
          @click="closeDialog"
          type="button"
          class="hl-dialog__header-btn"
        >
          <em class="el-icon el-icon-close"></em>
        </div>
      </div>
      <!-- 窗体插槽 -->
      <div class="hl-dialog__body">
        <slot></slot>
      </div>
      <!-- 底部区域 -->
      <div class="hl-dialog__footer"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HlDialog",
  props: {
    // 窗体标题
    title: {
      type: String,
      required: false,
      default: "",
    },
    id: {
      type: String,
      required: false,
      default: () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            var r = (Math.random() * 16) | 0,
              v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
        );
      },
    },
    // 窗体布局
    top: {
      type: String,
      required: false,
    },
    left: {
      type: String,
      required: false,
    },
    right: {
      type: String,
      required: false,
    },
    bottom: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      required: false,
      default: "20rem",
    },
    zIndex: {
      type: String,
      required: false,
      default: "100",
    },
    height: {
      type: String,
      required: false,
      default: "10rem",
    },
    // 窗体的背景图
    bgimg: {
      type: String,
      required: false,
      default: "",
    },
    // 标题、最小化按钮、关闭按钮的显示开关
    // t,s,c分别控制标题、最小化按钮、关闭按钮的显示
    topeArea: {
      type: String,
      required: false,
      default: "t,s,c",
    },
    //头部高度
    headerHeight: {
      type: String,
      required: false,
      default: "2.15rem",
    },
  },
  data() {
    return {
      system: this.$store.getters.system,
      isShow: true,
      visible: true,
      selectElement: "",
    };
  },
  watch: {
    // "system.panleFlag": {
    //   handler: function (value) {
    //     this.visible = value;
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  // computed: {
  // },
  mounted() {
    console.log(this.title);
  },
  destroyed() {},
  methods: {
    mousedown(event) {
      if (this.id) {
        this.selectElement = document.getElementById(this.id);
        var div1 = this.selectElement;
        this.selectElement.style.cursor = "move";
        this.isDowm = true;
        var distanceX = event.clientX - this.selectElement.offsetLeft;
        var distanceY = event.clientY - this.selectElement.offsetTop;
        document.onmousemove = function (ev) {
          var oevent = ev || event;
          div1.style.left = oevent.clientX - distanceX + "px";
          div1.style.top = oevent.clientY - distanceY + "px";
        };
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmouseup = null;
          div1.style.cursor = "default";
        };
      }
    },
    setMin() {
      this.visible = false;
    },
    closeDialog() {
      this.$emit("close");
      this.isShow = false;
    },
    setRestore() {
      this.$refs.preview && (this.$refs.preview.style.right = "unset");
      this.visible = true;
      this.mouseover();
    },
    mouseover() {
      this.$nextTick(() => {
        let preview = document.querySelectorAll(".hl_dialog_preview");
        preview.forEach((element, index) => {
          element.style.transition = "null";
          element.style.right = `${index * 17.5 + 0.8125}rem`;
        });
      });
    },
    mouseout() {
      this.$nextTick(() => {
        let preview = document.querySelectorAll(".hl_dialog_preview");
        preview.forEach((element) => {
          element.style.transition = "all .1s";
          element.style.right = `0.8125rem`;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hl_dialog_preview {
  position: absolute;
  cursor: pointer;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  bottom: 3.3125rem;
  right: 0.8125rem;
  color: #000000;
  width: 17.5rem;
  height: 3.75rem;
  background-color: #e6f0fa;
  box-shadow: 0px 0px 1.25rem 0px rgba(0, 0, 0, 0.5);
  border-radius: 0.625rem;
  z-index: 99;
  user-select: none;

  .mousedown {
    height: 100%;
  }

  .hl-dialog__header {
    width: 100%;
    height: 100%;

    .hl-dialog__header-btn {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.mousedown {
  width: 100%;
  height: 2.15rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
}

.hl-dialog {
  position: absolute;
  min-width: 11.8rem;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.hl-dialog__bg {
  background-color: #e6f0fa;
  position: absolute;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: -1;
  background-size: 100% 100%;

  .hl-dialog__bg_top {
    height: 3.875rem;
    width: 100%;
    display: flex;

    .hl-dialog__bg_top_left {
      width: calc(100% - 0.5625rem);
      max-width: 30rem;
      height: 100%;
      background-image: url("~@/assets/main/box_top_left.png");
    }

    .hl-dialog__bg_top_center {
      flex: 1;
      height: 100%;
      background-image: url("~@/assets/main/box_top_center.png");
    }

    .hl-dialog__bg_top_right {
      width: 0.5rem;
      height: 100%;
      background-image: url("~@/assets/main/box_top_right.png");
    }
  }

  .hl-dialog__bg_center {
    flex: 1;
    display: flex;

    .hl-dialog__bg_center_left {
      width: 0.5rem;
      height: 100%;
      background-image: url("~@/assets/main/box_center_left.png");
    }

    .hl-dialog__bg_center_center {
      flex: 1;
      height: 100%;
      background-image: url("~@/assets/main/box_center_center.png");
    }

    .hl-dialog__bg_center_right {
      width: 0.5rem;
      height: 100%;
      background-image: url("~@/assets/main/box_center_right.png");
    }
  }

  .hl-dialog__bg_bottom {
    height: 3.5rem;
    width: 100%;
    display: flex;

    .hl-dialog__bg_bottom_left {
      width: 1rem;
      max-width: 30rem;
      height: 100%;
      background-image: url("~@/assets/main/box_bottom_left.png");
    }

    .hl-dialog__bg_bottom_center {
      flex: 1;
      height: 100%;
      background-image: url("~@/assets/main/box_bottom_center.png");
    }

    .hl-dialog__bg_bottom_right {
      width: 4.125rem;
      height: 100%;
      background-image: url("~@/assets/main/box_bottom_right.png");
    }
  }
}

.hl-dialog__header {
  position: relative;
  .hl-dialog__title {
    user-select: none;
    color: #000000;
    font-size: 1rem;
    font-family: Microsoft YaHei;
    margin: 0.5rem 1rem;
    display: inline-block;
    font-weight: bold;
    position: absolute;
  }

  .hl-dialog__headerbtn {
    right: 2.3rem !important;
  }

  .hl-dialog__header-btn {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 20px;
    height: 20px;
    color: white;
    border-radius: 9999px;
    background-color: #5fb2ff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
    z-index: 99;
  }
}

.hl-dialog__body {
  height: calc(100% - 3.15rem);
  padding: 0 1rem;
}

.hl-dialog__footer {
  height: 1rem;
}
</style>
