import "./draw.css";
class Draw {
  constructor(props) {
    //这里初始化class的时候需要传map对象进来
    this.map = props.map;
    this.id = props.id ? props.id : Math.random().toString();
    this.mapApi = props.mapApi;
    this.source;
    this.drawLayer;
    this.drawInteraction;
    //样式
    this.style = {
      fill: new this.mapApi.Fill({
        // color: 'rgba(255, 255, 255, 0.2)'
        // color: 'rgba(0,255,0,0.5)'
      }),
      stroke: new this.mapApi.Stroke({
        color: "red",
        width: 2,
      }),
      image: new this.mapApi.Circle({
        radius: 7,
        fill: new this.mapApi.Fill({
          color: "red",
        }),
      }),
      imageIcon: new this.mapApi.Icon({
        // anchor: [0.5, 0.5],
        src: require("@/assets/image/map/mapTool/ic_eljcz1.png"),
        scale: 1,
        // rotation: this.getRadius(data.angle)
        // anchorYUnits: 'pixels'
      }),
    };
    this.toolStyle = new this.mapApi.Style({
      fill: this.style.fill,
      stroke: this.style.stroke,
      image: this.style.image,
    });

    //外部调用工具条参数
    this.setCallData = props.setCallData; //回调
    this.rootType = props.rootType; //模块类型
    this.singleTool = props.singleTool; //当前工具多次绘制
    this.updateTool = props.updateTool; //刷新最新绘制
    this.identifyTool = props.identifyTool; //自定义绘制
    this.$mapConfig = props.mapConfig;

    //测量相关参数
    this.toolTip = "messureTool";
    this.helpTooltipElement;
    this.measureTooltipElement;
    this.helpTooltip;
    this.middleTooltip;
    this.measureTooltip;
    this.measureType;
    this.sketch;
    this.continueLineMsg = "单击继续绘制线,双击结束绘制";
    this.startLineMsg = "单击开始绘制";
    this.helpMsg = null;
    this.drawListener = null;
    this.drawStarter = null;
    this.drawPointerMove = null;
    this.drawPointerOut = null;
    this.drawSingleClick = null;
    this.selectedStyle = new this.mapApi.Style({
      fill: this.style.fill,
      stroke: this.style.stroke,
      image: this.style.imageIcon,
    });
    this.init();
  }
  init() {
    if (this.getLayer(this.id).length === 0) {
      //临时图层的数据源
      this.source = new this.mapApi.VectorSource();
      //新建临时图层，并设置临时图层渲染各种要素的样式
      this.drawLayer = new this.mapApi.VectorLayer({
        id: this.id,
        source: this.source,
        style: this.toolStyle,
      });
      // this.map.addLayer(this.drawLayer)
      this.insertAtLayer(this.$mapConfig.baseMapCountMax, this.drawLayer);
    }
  }
  insertAtLayer(index, layer) {
    let layersArray = this.map.getLayers();
    layersArray.insertAt(index, layer);
  }
  // 获取图层(判断图层是否存在)
  getLayer(layerId) {
    let layer = [];
    this.map.getLayers().array_.map((v) => {
      if (v && v.get("id") && v.get("id").indexOf(layerId) > -1) {
        layer.push(v);
      }
    });
    return layer;
  }
  addLayer() {
    this.map.addLayer(this.drawLayer);
  }
  //传值方式绘制----------------------start--------------------
  setImageIconStyle(icon) {
    return new this.mapApi.Style({
      image: new this.mapApi.Icon({
        src: icon,
        scale: this.style.imageIcon.getScale(),
      }),
    });
  }
  setImageCircleStyle() {
    return new this.mapApi.Style({
      image: new this.mapApi.Circle({
        radius: 7,
        fill: new this.mapApi.Fill({
          color: "rgba(255,0,0,0.5)",
        }),
      }),
    });
  }
  setFillStyle(color, lineDash) {
    return new this.mapApi.Style({
      fill: new this.mapApi.Fill({
        color: color ? color : "rgba(255, 255, 255, 0.2)",
      }),
      stroke: new this.mapApi.Stroke({
        color: "red",
        width: 2,
        lineDash: lineDash,
      }),
    });
  }
  setStrokeStyle(lineDash) {
    return new this.mapApi.Style({
      stroke: new this.mapApi.Stroke({
        color: "red",
        width: 2,
        lineDash: lineDash,
      }),
    });
  }
  drawPost(type, dtdcType, imageSrc) {
    this.removeMessureListener();
    this.bindDraw(type, dtdcType, imageSrc);
  }
  bindDraw(type, dtdcType, imageSrc) {
    this.selectedStyle = null;
    this.drawingTool(type);
    let lineDash = [],
      fillLineDash = [],
      color = "red";
    switch (type) {
      case "Point":
        switch (dtdcType) {
          case "img1":
            this.selectedStyle = this.setImageIconStyle(imageSrc);
            break;
          default:
            this.selectedStyle = this.setImageCircleStyle();
            break;
        }
        break;
      case "LineString":
        switch (dtdcType) {
          case "lineDash":
            lineDash = [1, 3, 5, 7];
            break;
          default:
            lineDash = [];
            break;
        }
        this.selectedStyle = this.setStrokeStyle(lineDash);
        break;
      case "Polygon":
        switch (dtdcType) {
          case "lineDash":
            fillLineDash = [1, 3, 5, 7];
            color = "rgba(255,255,0,0.5)";
            break;
          default:
            fillLineDash = [];
            color = "rgba(0,255,0,0.5)";
            break;
        }
        this.selectedStyle = this.setFillStyle(color, fillLineDash);
        break;
      case "Circle":
        break;
      case "Extent":
        break;
      default:
        break;
    }
  }
  drawingTool(type) {
    if (type === "Extent") {
      this.drawingExtent();
    } else if (type === "Messure") {
      this.drawingMessure();
    } else if (type === "Direction") {
      this.drawingDirection("Circle");
    } else {
      this.drawingDefault(type);
    }
    this.id += " " + type + "ToolLayer";
    this.drawLayer.set("id", this.id);
  }

  //默认绘制(点线面圆等)
  drawingDefault(type) {
    if (type === "Polygon") {
      this.drawInteraction = new this.mapApi.Draw({
        source: this.source, //设置要素源，绘制结束后将绘制的要素添加到临时图层
        type: type, //绘制的类型
        // freehand: true,
      });
      this.map.addInteraction(this.drawInteraction);
      this.drawInteraction.on("drawstart", (evt) => {
        this.drawstart(evt);
      });
      //绘图结束事件回调
      this.drawInteraction.on("drawend", (evt) => {
        this.drawingEnd(evt);
      });
    } else {
      let center = [];
      let radius = 0;
      let geo = "";
      this.map.getViewport().onmousedown = (evt) => {
        evt.stopPropagation();
        // console.log('单击', evt)
        let interactions = this.map.getInteractions().array_;
        for (let key in interactions) {
          if (interactions[key] instanceof this.mapApi.InteractionDragPan)
            interactions[key].setActive(false);
        }

        this.drawstartPro(evt);
        this.addExtentLayerDefaultPro(evt);
        this.addDirectionContainer();
        let that = this;
        that.touchOnlyEvt = that.map.on("pointermove", (evt) => {
          that.touchOnlyDefaultPro(evt, that, (param) => {
            radius = param.radius;
            geo = param.geo;
          });
        });
        center = this.map.getCoordinateFromPixel([evt.clientX, evt.clientY]);
      };
      this.map.getViewport().onmouseup = () => {
        // console.log('弹起', evt)
        this.map.getViewport().onmouseover = "";
        this.map.getViewport().onmousedown = "";
        this.map.getViewport().onmouseup = "";
        let interactions = this.map.getInteractions().array_;
        for (let key in interactions) {
          if (interactions[key] instanceof this.mapApi.InteractionDragPan)
            interactions[key].setActive(true);
        }
        this.drawingEndPro({
          type: "Circle",
          center: center,
          radius: radius,
          geo: geo,
        });
        this.removeLayerDefaultPro();
      };
    }
  }
  addExtentLayerDefaultPro(evt) {
    if (!this.defaultLayer) {
      let source = new this.mapApi.VectorSource();
      this.defaultLayer = new this.mapApi.VectorLayer({
        id: "defaultLayer",
        source: source,
      });
      this.insertAtLayer(99, this.defaultLayer);
    }
    this.fromCoordDefault = this.map.getCoordinateFromPixel([
      evt.clientX,
      evt.clientY,
    ]);
    let feature = new this.mapApi.Feature({
      geometry: new this.mapApi.Point(this.fromCoordDefault),
    });
    this.defaultLayer.getSource().addFeature(feature);
  }
  touchOnlyDefaultPro(evt, that, callback) {
    if (that.defaultLayer) that.defaultLayer.getSource().clear();
    let features = [];
    // 起点
    let fromCoordDefault = that.fromCoordDefault;
    // let fromCoordFeature = new that.mapApi.Feature({
    //     geometry: new that.mapApi.Point(fromCoordDefault),
    // });
    // features.push(fromCoordFeature)
    // 终点
    let toCoord = that.map.getCoordinateFromPixel(evt.pixel);
    // let toCoordFeature = new that.mapApi.Feature({
    //     geometry: new that.mapApi.Point(toCoord),
    // });
    // features.push(toCoordFeature)
    // 橡皮线
    let lineGeo = new that.mapApi.LineString([fromCoordDefault, toCoord]);
    let lineFeature = new that.mapApi.Feature({ geometry: lineGeo });
    lineFeature.setStyle(
      new that.mapApi.Style({
        stroke: new that.mapApi.Stroke({
          color: "purple",
          width: "1",
        }),
      })
    );
    features.push(lineFeature);
    const offsetAngle = (startx, starty, endx, endy) => {
      let tan = 0;
      if (endx == startx) {
        tan = (Math.atan(0) * 180) / Math.PI;
      } else {
        tan =
          (Math.atan(Math.abs((endy - starty) / (endx - startx))) * 180) /
          Math.PI;
      }
      if (endx >= startx && endy >= starty) {
        //第一象限
        return -tan;
      } else if (endx < startx && endy > starty) {
        //第二象限
        return tan - 180;
      } else if (endx < startx && endy < starty) {
        return 180 - tan; //第三象限
      } else {
        //第四象限
        return tan;
      }
    };
    let direction = offsetAngle(
      fromCoordDefault[0],
      fromCoordDefault[1],
      toCoord[0],
      toCoord[1]
    );
    if (direction >= -90 && direction <= 180) direction += 90;
    else if (direction >= -180 && direction <= -90) direction += 450;

    // let direction = Math.atan((toCoord[1] - fromCoordDefault[1]) / (toCoord[0] - fromCoordDefault[0]))
    // direction = direction * 180 / Math.PI
    const formatDegree = function (value) {
      // value = Math.abs(value);
      var v1 = Math.floor(value); // 度
      // var v2 = Math.floor((value - v1) * 60); // 分
      // var v3 = Math.round(((value - v1) * 3600) % 60); // 秒
      // return v1 + "°" + v2 + "'" + v3 + '"';
      return v1 + "°";
    };
    // // direction = direction.toFixed(2)
    let directionShow = formatDegree(direction);
    // console.log('方位角', direction)
    let outputLength = this.mapApi.getLength(lineGeo, {
      projection: "EPSG:4326",
    });
    let lengthShow = (outputLength / 1000 / 1.852).toFixed(2);
    // console.log('半径', length)

    document.querySelector("#direction_length").placeholder = lengthShow;
    document.querySelector("#direction_angle").placeholder = directionShow;

    // 矫正距离绘制圆形
    // let lineGeo = new that.mapApi.LineString([fromCoordDefault, toCoord]);
    // let lengthShow = this.mapApi.getLength(lineGeo, {
    //     projection: "EPSG:4326",
    // });
    let length = this.mapApi.getLength(lineGeo, {
      projection: "EPSG:3857",
    });
    let circleGeo = new this.mapApi.CircleGeom(fromCoordDefault, length);
    let circleFeature = new that.mapApi.Feature({
      geometry: circleGeo,
    });
    circleFeature.setStyle(
      new this.mapApi.Style({
        stroke: new this.mapApi.Stroke({
          color: "purple",
          width: 2,
        }),
      })
    );
    features.push(circleFeature);
    that.defaultLayer.getSource().addFeatures(features);
    if (callback)
      callback({
        radius: outputLength / 100000,
        geo: circleGeo,
      });
  }
  removeLayerDefaultPro() {
    if (this.defaultLayer) {
      this.defaultLayer.getSource().clear();
      this.defaultLayer.dispose();
      this.map.removeLayer(this.defaultLayer);
    }
    if (this.touchOnlyEvt) this.mapApi.unByKey(this.touchOnlyEvt);
  }
  // 方位角
  drawingDirection() {
    // this.drawInteraction = new this.mapApi.Draw({
    //     source: this.source, //设置要素源，绘制结束后将绘制的要素添加到临时图层
    //     type: type, //绘制的类型
    //     // freehand: true,
    //     style: new this.mapApi.Style({
    //         fill: new this.mapApi.Fill({
    //             color: 'rgba(255,255,255,0)'
    //         }),
    //         stroke: new this.mapApi.Stroke({
    //             color: 'purple',
    //             width: 2
    //         }),
    //         image: new this.mapApi.Circle({
    //             radius: 7,
    //             fill: new this.mapApi.Fill({
    //                 color: 'purple'
    //             })
    //         }),
    //     })
    // });
    // this.map.addInteraction(this.drawInteraction);
    // this.drawInteraction.on('drawstart', (evt) => {
    //     this.drawstart(evt)
    //     this.addDirectionLayer(evt)
    //     this.addDirectionContainer()
    //     let that = this
    //     that.touchOnlyEvt = that.map.on("pointermove", (evt) => {
    //         that.touchOnly(evt, that)
    //     });
    // });
    // //绘图结束事件回调
    // this.drawInteraction.on('drawend', (evt) => {
    //     this.drawingEnd(evt)
    //     this.removeDirectionLayer()
    // });
    // let center = []
    // let radius = 0;
    // let geo = ''
    this.map.getViewport().onmousedown = (evt) => {
      evt.stopPropagation();
      // console.log('单击', evt)
      let interactions = this.map.getInteractions().array_;
      for (let key in interactions) {
        if (interactions[key] instanceof this.mapApi.InteractionDragPan)
          interactions[key].setActive(false);
      }

      this.drawstartPro(evt);
      this.addDirectionLayerPro(evt);
      this.addDirectionContainer();
      let that = this;
      that.touchOnlyEvt = that.map.on("pointermove", (evt) => {
        that.touchOnlyPro(
          evt,
          that
          //     (param) => {
          //     radius = param.radius
          //     geo = param.geo
          // }
        );
      });
      // center = this.map.getCoordinateFromPixel([evt.clientX, evt.clientY])
    };
    this.map.getViewport().onmouseup = () => {
      // console.log('弹起', evt)
      this.map.getViewport().onmouseover = "";
      this.map.getViewport().onmousedown = "";
      this.map.getViewport().onmouseup = "";
      let interactions = this.map.getInteractions().array_;
      for (let key in interactions) {
        if (interactions[key] instanceof this.mapApi.InteractionDragPan)
          interactions[key].setActive(true);
      }
      // this.drawingEndPro({
      //     type: 'Circle',
      //     center: center,
      //     radius: radius,
      //     geo: geo,
      // })
      this.removeDirectionLayer();

      this.removeMessureListener();
      if (!this.singleTool) this.drawingDirection();
    };
  }
  addDirectionLayer(evt) {
    if (!this.directionLayer) {
      let source = new this.mapApi.VectorSource();
      this.directionLayer = new this.mapApi.VectorLayer({
        id: "directionLayer",
        source: source,
      });
      this.insertAtLayer(99, this.directionLayer);
    }
    this.fromCoord = evt.feature.getGeometry().getCenter();
    let feature = new this.mapApi.Feature({
      geometry: new this.mapApi.Point(this.fromCoord),
    });
    this.directionLayer.getSource().addFeature(feature);
  }
  addDirectionLayerPro(evt) {
    if (!this.directionLayer) {
      let source = new this.mapApi.VectorSource();
      this.directionLayer = new this.mapApi.VectorLayer({
        id: "directionLayer",
        source: source,
      });
      this.insertAtLayer(99, this.directionLayer);
    }
    if (!this.directionLayer.getSource())
      this.directionLayer.setSource(new this.mapApi.VectorSource());
    this.fromCoord = this.map.getCoordinateFromPixel([
      evt.clientX,
      evt.clientY,
    ]);
    let feature = new this.mapApi.Feature({
      geometry: new this.mapApi.Point(this.fromCoord),
    });
    this.directionLayer.getSource().addFeature(feature);
  }
  addDirectionContainer() {
    let directionEle = document.createElement("div");
    directionEle.id = "direction";
    directionEle.className = "direction";
    this.map.getViewport().parentNode.append(directionEle);
    directionEle.style.position = "fixed";
    directionEle.style.zIndex = "999";
    directionEle.style.right = "10px";
    directionEle.style.bottom = "40px";
    directionEle.style.backgroundColor = "rgba(0,63,101,0.7)";
    directionEle.style.color = "white";
    directionEle.style.borderRadius = "5px";
    directionEle.style.padding = "10px";

    let ele = document.createElement("div");
    ele.style.display = "flex";
    directionEle.append(ele);

    let childLengthName = document.createElement("div");
    childLengthName.innerText = "距离（海里）：";
    ele.append(childLengthName);
    var childLength = document.createElement("input");
    childLength.id = "direction_length";
    ele.append(childLength);
    let childAngleName = document.createElement("div");
    childAngleName.innerText = "方位（°）：";
    ele.append(childAngleName);
    var childAngle = document.createElement("input");
    childAngle.id = "direction_angle";
    ele.append(childAngle);
  }
  removeDirectionLayer() {
    if (this.directionLayer) {
      if (this.directionLayer.getSource())
        this.directionLayer.getSource().clear();
      this.directionLayer.dispose();
      this.map.removeLayer(this.directionLayer);
    }
    this.directionLayer = "";
    if (this.touchOnlyEvt) this.mapApi.unByKey(this.touchOnlyEvt);
    this.map.getViewport().onmouseover = "";
    this.map.getViewport().onmousedown = "";
    this.map.getViewport().onmouseup = "";
    if (document.querySelector("#direction"))
      document.querySelector("#direction").remove();
  }
  touchOnlyPro(evt, that, callback) {
    if (that.directionLayer) that.directionLayer.getSource().clear();
    let features = [];
    // 起点
    let fromCoord = that.fromCoord;
    // let fromCoordFeature = new that.mapApi.Feature({
    //     geometry: new that.mapApi.Point(fromCoord),
    // });
    // features.push(fromCoordFeature)
    // 终点
    let toCoord = that.map.getCoordinateFromPixel(evt.pixel);
    // let toCoordFeature = new that.mapApi.Feature({
    //     geometry: new that.mapApi.Point(toCoord),
    // });
    // features.push(toCoordFeature)
    // 橡皮线
    let lineGeo = new that.mapApi.LineString([fromCoord, toCoord]);
    let lineFeature = new that.mapApi.Feature({ geometry: lineGeo });
    lineFeature.setStyle(
      new that.mapApi.Style({
        stroke: new that.mapApi.Stroke({
          color: "purple",
          width: "1",
        }),
      })
    );
    features.push(lineFeature);
    const offsetAngle = (startx, starty, endx, endy) => {
      let tan = 0;
      if (endx == startx) {
        tan = (Math.atan(0) * 180) / Math.PI;
      } else {
        tan =
          (Math.atan(Math.abs((endy - starty) / (endx - startx))) * 180) /
          Math.PI;
      }
      if (endx >= startx && endy >= starty) {
        //第一象限
        return -tan;
      } else if (endx < startx && endy > starty) {
        //第二象限
        return tan - 180;
      } else if (endx < startx && endy < starty) {
        return 180 - tan; //第三象限
      } else {
        //第四象限
        return tan;
      }
    };
    let direction = offsetAngle(
      fromCoord[0],
      fromCoord[1],
      toCoord[0],
      toCoord[1]
    );
    if (direction >= -90 && direction <= 180) direction += 90;
    else if (direction >= -180 && direction <= -90) direction += 450;

    // let direction = Math.atan((toCoord[1] - fromCoord[1]) / (toCoord[0] - fromCoord[0]))
    // direction = direction * 180 / Math.PI
    const formatDegree = function (value) {
      // value = Math.abs(value);
      var v1 = Math.floor(value); // 度
      // var v2 = Math.floor((value - v1) * 60); // 分
      // var v3 = Math.round(((value - v1) * 3600) % 60); // 秒
      // return v1 + "°" + v2 + "'" + v3 + '"';
      return v1 + "°";
    };
    // // direction = direction.toFixed(2)
    let directionShow = formatDegree(direction);
    // console.log('方位角', direction)
    let outputLength = this.mapApi.getLength(lineGeo, {
      projection: "EPSG:4326",
    });
    let lengthShow = (outputLength / 1000 / 1.852).toFixed(2);
    // console.log('半径', length)

    document.querySelector("#direction_length").placeholder = lengthShow;
    document.querySelector("#direction_angle").placeholder = directionShow;

    // 矫正距离绘制圆形
    let length = this.mapApi.getLength(lineGeo, {
      projection: "EPSG:3857",
    });
    let circleGeo = new this.mapApi.CircleGeom(fromCoord, length);
    let circleFeature = new that.mapApi.Feature({
      geometry: circleGeo,
    });
    circleFeature.setStyle(
      new this.mapApi.Style({
        stroke: new this.mapApi.Stroke({
          color: "purple",
          width: 2,
        }),
      })
    );
    features.push(circleFeature);
    that.directionLayer.getSource().addFeatures(features);

    if (callback)
      callback({
        radius: outputLength / 100000,
        geo: circleGeo,
      });
  }
  touchOnly(evt, that) {
    if (that.directionLayer) that.directionLayer.getSource().clear();
    let features = [];
    // 起点
    let fromCoord = that.fromCoord;
    let fromCoordFeature = new that.mapApi.Feature({
      geometry: new that.mapApi.Point(fromCoord),
    });
    features.push(fromCoordFeature);
    // 终点
    let toCoord = that.map.getCoordinateFromPixel(evt.pixel);
    let toCoordFeature = new that.mapApi.Feature({
      geometry: new that.mapApi.Point(toCoord),
    });
    features.push(toCoordFeature);
    // 橡皮线
    let lineGeo = new that.mapApi.LineString([fromCoord, toCoord]);
    let lineFeature = new that.mapApi.Feature({ geometry: lineGeo });
    lineFeature.setStyle(
      new that.mapApi.Style({
        stroke: new that.mapApi.Stroke({
          color: "purple",
          width: "1",
        }),
      })
    );
    features.push(lineFeature);
    that.directionLayer.getSource().addFeatures(features);
    const offsetAngle = (startx, starty, endx, endy) => {
      let tan = 0;
      if (endx == startx) {
        tan = (Math.atan(0) * 180) / Math.PI;
      } else {
        tan =
          (Math.atan(Math.abs((endy - starty) / (endx - startx))) * 180) /
          Math.PI;
      }
      if (endx >= startx && endy >= starty) {
        //第一象限
        return -tan;
      } else if (endx < startx && endy > starty) {
        //第二象限
        return tan - 180;
      } else if (endx < startx && endy < starty) {
        return 180 - tan; //第三象限
      } else {
        //第四象限
        return tan;
      }
    };
    let direction = offsetAngle(
      fromCoord[0],
      fromCoord[1],
      toCoord[0],
      toCoord[1]
    );
    if (direction >= -90 && direction <= 180) direction += 90;
    else if (direction >= -180 && direction <= -90) direction += 450;

    // let direction = Math.atan((toCoord[1] - fromCoord[1]) / (toCoord[0] - fromCoord[0]))
    // direction = direction * 180 / Math.PI
    const formatDegree = function (value) {
      // value = Math.abs(value);
      var v1 = Math.floor(value); // 度
      var v2 = Math.floor((value - v1) * 60); // 分
      var v3 = Math.round(((value - v1) * 3600) % 60); // 秒
      return v1 + "°" + v2 + "'" + v3 + '"';
    };
    // // direction = direction.toFixed(2)
    direction = formatDegree(direction);
    // console.log('方位角', direction)
    let outputLength = this.mapApi.getLength(lineGeo, {
      projection: "EPSG:4326",
    });
    let length = (outputLength / 1000 / 1.852).toFixed(2);
    // console.log('半径', length)

    document.querySelector("#direction_length").placeholder = length;
    document.querySelector("#direction_angle").placeholder = direction;
  }
  //框选
  drawingExtent() {
    // this.drawInteraction = new this.mapApi.Draw({
    //     source: this.source, //设置要素源，绘制结束后将绘制的要素添加到临时图层
    //     type: 'LineString', //绘制的类型
    //     maxPoints: 2,
    //     geometryFunction: (coordinates, geometry) => {
    //         if (!geometry) {
    //             geometry = new this.mapApi.Polygon([120, 36], [121, 26]);
    //         }
    //         var start = coordinates[0];
    //         var end = coordinates[1];
    //         geometry.setCoordinates([
    //             [start, [start[0], end[1]], end, [end[0], start[1]], start]
    //         ]);
    //         return geometry;
    //     }
    // });
    // this.map.addInteraction(this.drawInteraction);
    // this.drawInteraction.on('drawstart', (evt) => {
    //     this.drawstart(evt)
    // });
    // //绘图结束事件回调
    // this.drawInteraction.on('drawend', (evt) => {
    //     this.drawingEnd(evt)
    // });
    // let center = []
    let points = [];
    let geo = "";
    this.map.getViewport().onmousedown = (evt) => {
      evt.stopPropagation();
      // console.log('单击', evt)
      let interactions = this.map.getInteractions().array_;
      for (let key in interactions) {
        if (interactions[key] instanceof this.mapApi.InteractionDragPan)
          interactions[key].setActive(false);
      }

      this.drawstartPro(evt);
      this.addExtentLayerPro(evt);
      let that = this;
      that.touchOnlyEvt = that.map.on("pointermove", (evt) => {
        that.touchOnlyExtentPro(evt, that, (param) => {
          geo = param.geo;
          points = param.points;
        });
      });
      // center = this.map.getCoordinateFromPixel([evt.clientX, evt.clientY])
    };
    this.map.getViewport().onmouseup = () => {
      // console.log('弹起', evt)
      this.map.getViewport().onmouseover = "";
      this.map.getViewport().onmousedown = "";
      this.map.getViewport().onmouseup = "";
      let interactions = this.map.getInteractions().array_;
      for (let key in interactions) {
        if (interactions[key] instanceof this.mapApi.InteractionDragPan)
          interactions[key].setActive(true);
      }
      this.drawingEndPro({
        type: "Polygon",
        points: points,
        geo: geo,
      });
      this.removeLayerExtentPro();
    };
  }
  addExtentLayerPro(evt) {
    if (!this.extentLayer) {
      let source = new this.mapApi.VectorSource();
      this.extentLayer = new this.mapApi.VectorLayer({
        id: "extentLayer",
        source: source,
      });
      this.insertAtLayer(99, this.extentLayer);
    }
    this.fromCoordExtent = this.map.getCoordinateFromPixel([
      evt.clientX,
      evt.clientY,
    ]);
    let feature = new this.mapApi.Feature({
      geometry: new this.mapApi.Point(this.fromCoordExtent),
    });
    this.extentLayer.getSource().addFeature(feature);
  }
  touchOnlyExtentPro(evt, that, callback) {
    if (that.extentLayer) that.extentLayer.getSource().clear();
    let features = [];
    // 起点
    let fromCoordExtent = that.fromCoordExtent;
    // let fromCoordFeature = new that.mapApi.Feature({
    //     geometry: new that.mapApi.Point(fromCoordExtent),
    // });
    // features.push(fromCoordFeature)
    // 终点
    let toCoord = that.map.getCoordinateFromPixel(evt.pixel);
    // let toCoordFeature = new that.mapApi.Feature({
    //     geometry: new that.mapApi.Point(toCoord),
    // });
    // features.push(toCoordFeature)
    // 矩形
    const _coords = [
      fromCoordExtent,
      [toCoord[0], fromCoordExtent[1]],
      toCoord,
      [fromCoordExtent[0], toCoord[1], fromCoordExtent],
      fromCoordExtent,
    ];
    const extentGeo = new that.mapApi.Polygon([_coords]);
    let extentFeature = new that.mapApi.Feature({
      geometry: extentGeo,
    });
    features.push(extentFeature);
    that.extentLayer.getSource().addFeatures(features);
    if (callback)
      callback({
        points: extentGeo.getCoordinates(),
        geo: extentGeo,
      });
  }
  removeLayerExtentPro() {
    if (this.extentLayer) {
      this.extentLayer.getSource().clear();
      this.extentLayer.dispose();
      this.map.removeLayer(this.extentLayer);
    }
    if (this.touchOnlyEvt) this.mapApi.unByKey(this.touchOnlyEvt);
  }
  //默认绘制开始
  drawstart() {
    if (this.updateTool) {
      this.source.clear();
      //同类工具条当且只保留一个要素
      this.getLayer(this.id.split(" ")[1]).map((v) => {
        if (v.get("id") !== this.id) {
          v.getSource().clear();
          v.dispose();
          this.map.removeLayer(v);
        }
      });
    }
  }
  drawstartPro() {
    if (this.updateTool) {
      this.source.clear();
      //同类工具条当且只保留一个要素
      this.getLayer(this.id.split(" ")[1]).map((v) => {
        if (v.get("id") !== this.id) {
          v.getSource().clear();
          v.dispose();
          this.map.removeLayer(v);
        }
      });
    }
  }
  //默认绘制结束
  drawingEnd(evt) {
    // evt.feature.setId(Math.random())
    let geo = evt.feature.getGeometry();
    let type = geo.getType(); //获取类型
    //根据不同的类型执行不同的操作
    const handle = {
      Circle: () => {
        //获取中心点和半径
        let center = geo.getCenter();
        let radius = geo.getRadius();
        this.setCallData(this.rootType + "_Circle", {
          center: center,
          radius: radius,
          geo: geo,
        });
      },
      Polygon: () => {
        //获取坐标点
        let points = geo.getCoordinates();
        this.setCallData(this.rootType + "_Polygon", {
          points: points,
          geo: geo,
        });
      },
      LineString: () => {
        let points = geo.getCoordinates();
        this.setCallData(this.rootType + "_LineString", {
          points: points,
          geo: geo,
        });
      },
      Point: () => {
        let points = geo.getCoordinates();
        this.setCallData(this.rootType + "_Point", {
          points: points,
          geo: geo,
        });
      },
    };
    if (handle[type]) {
      handle[type]();
      evt.feature.setStyle(this.selectedStyle);
    }
    if (this.singleTool) this.removeMessureListener();
    setTimeout(() => {
      if (this.identifyTool) this.source.clear();
    });
  }
  drawingEndPro(param) {
    //根据不同的类型执行不同的操作
    console.log(param);
    const handle = {
      Circle: () => {
        //获取中心点和半径
        this.setCallData(this.rootType + "_Circle", {
          center: param.center,
          radius: param.radius,
          geo: param.geo,
        });
      },
      Polygon: () => {
        //获取坐标点
        this.setCallData(this.rootType + "_Polygon", {
          points: param.points,
          geo: param.geo,
        });
      },
      LineString: () => {
        this.setCallData(this.rootType + "_LineString", {
          points: param.points,
          geo: param.geo,
        });
      },
      Point: () => {
        this.setCallData(this.rootType + "_Point", {
          points: param.points,
          geo: param.geo,
        });
      },
    };
    if (handle[param.type]) {
      handle[param.type]();
    }
    if (this.singleTool) this.removeMessureListener();
    setTimeout(() => {
      if (this.identifyTool) this.source.clear();
    });
  }

  //测量---
  drawingMessure() {
    this.drawInteraction = new this.mapApi.Draw({
      source: this.source, //设置要素源，绘制结束后将绘制的要素添加到临时图层
      type: "LineString", //绘制的类型
    });
    this.map.addInteraction(this.drawInteraction);

    this.drawPointerMove = this.map.on("pointermove", (evt) => {
      this.pointerMoveHandler(evt);
    });
    this.drawPointerOut = this.map
      .getViewport()
      .addEventListener("mouseout", () => {
        this.helpTooltipElement.classList.add("hidden");
      });

    this.terminaOverlId = Math.random();
    this.createMeasureTooltip();
    this.createHelpTooltip();
    this.helpMsg = this.startLineMsg;
    this.drawStarter = this.drawInteraction.on(
      "drawstart",
      (evt) => {
        this.sketch = evt.feature;
        let tooltipCoord = evt.coordinate;
        this.drawListener = this.sketch.getGeometry().on("change", (evt) => {
          let geom = evt.target;
          let output;
          if (geom instanceof this.mapApi.LineString) {
            output = this.formatLength(geom);
            tooltipCoord = geom.getLastCoordinate();
          }
          this.measureTooltipElement.innerHTML = output;
          this.measureTooltip.setPosition(tooltipCoord);
        });

        let drawgeom = evt.feature.getGeometry();
        this.drawSingleClick = this.map.on("singleclick", (evt) => {
          if (drawgeom instanceof this.mapApi.LineString) {
            if (drawgeom.flatCoordinates.length > 4) {
              let point1 = drawgeom.flatCoordinates.slice(
                drawgeom.flatCoordinates.length - 6,
                drawgeom.flatCoordinates.length - 4
              );
              let point2 = drawgeom.flatCoordinates.slice(
                drawgeom.flatCoordinates.length - 4,
                drawgeom.flatCoordinates.length - 2
              );
              let lineString = new this.mapApi.LineString([point1, point2]);
              let output = this.formatLength(lineString);
              this.createMiddleTooltip(output, evt.coordinate);
            }
          }
        });
      },
      this
    );

    this.drawInteraction.on("drawend", (evt) => {
      let drawgeom = evt.feature.getGeometry();
      if (drawgeom instanceof this.mapApi.LineString) {
        if (drawgeom.flatCoordinates.length > 4) {
          let point1 = drawgeom.flatCoordinates.slice(
            drawgeom.flatCoordinates.length - 4,
            drawgeom.flatCoordinates.length - 2
          );
          let point2 = drawgeom.flatCoordinates.slice(
            drawgeom.flatCoordinates.length - 2,
            drawgeom.flatCoordinates.length
          );
          let lineString = new this.mapApi.LineString([point1, point2]);
          let output = this.formatLength(lineString);
          this.createMiddleTooltip(output, point2);
        }
      } else if (drawgeom instanceof this.mapApi.Point) {
        this.measureTooltipElement.innerHTML =
          "经度：" +
          drawgeom.flatCoordinates[0].toFixed(3) +
          ";纬度：" +
          drawgeom.flatCoordinates[1].toFixed(3);
        this.measureTooltip.setPosition(drawgeom.flatCoordinates);
      }
      this.measureTooltipElement.className = "tooltipMap tooltipMap-static";
      this.measureTooltip.setOffset([0, -7]);

      this.terminaOverlId = "";

      this.setCallData(this.rootType, {
        points: drawgeom.getCoordinates(),
        geo: drawgeom,
      });
      //结束绘制
      this.removeMessureListener();
      if (!this.singleTool) this.drawingMessure();
    });
  }
  createMeasureTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(
        this.measureTooltipElement
      );
    }
    this.measureTooltipElement = document.createElement("div");
    this.measureTooltipElement.className = "tooltipMap tooltipMap-measure";
    this.measureTooltip = new this.mapApi.Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: "bottom-center",
    });
    this.map.addOverlay(this.measureTooltip);
    this.setOverlayId(this.measureTooltip);
  }
  createHelpTooltip() {
    if (this.helpTooltipElement) {
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
    }
    this.helpTooltipElement = document.createElement("div");
    this.helpTooltipElement.className = "tooltipMap hidden";
    this.helpTooltip = new this.mapApi.Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: "center-left",
    });
    this.map.addOverlay(this.helpTooltip);
    this.setOverlayId(this.helpTooltip);
  }
  createMiddleTooltip(output, coordinate) {
    this.middleTooltipElement = document.createElement("div");
    this.middleTooltipElement.className = "tooltipMap tooltipMap-middle";
    this.middleTooltipElement.innerHTML = output;
    this.middleTooltip = new this.mapApi.Overlay({
      element: this.middleTooltipElement,
      offset: [0, 0],
      positioning: "top-center",
    });
    this.map.addOverlay(this.middleTooltip);
    this.middleTooltip.setPosition(coordinate);
    this.setOverlayId(this.middleTooltip);
  }
  //设置唯一overlay标识
  setOverlayId(overlay) {
    overlay.type = this.toolTip + this.terminaOverlId;
  }
  formatLength(line) {
    let length = this.mapApi.getLength(line, {
      projection: this.map.getView().getProjection(),
    });
    let output;
    if (length > 100) {
      output =
        (Math.round((length / 1000) * 100) / 100 / 1.852).toFixed(2) +
        " " +
        "海里";
    } else {
      output = Math.round(length * 100) / 100 + " " + "m";
    }
    return output;
  }
  pointerMoveHandler(evt) {
    if (evt.dragging) {
      return;
    }
    if (this.sketch) {
      let geom = this.sketch.getGeometry();
      if (geom instanceof this.mapApi.LineString) {
        this.helpMsg = this.continueLineMsg;
      }
    }
    if (this.measureType === "Point") {
      this.helpTooltipElement.innerHTML =
        "点击添加标注，当前经度：" +
        evt.coordinate[0].toFixed(3) +
        ";当前纬度：" +
        evt.coordinate[1].toFixed(3);
    } else {
      this.helpTooltipElement.innerHTML = this.helpMsg;
    }

    this.helpTooltip.setPosition(evt.coordinate);

    this.helpTooltipElement.classList.remove("hidden");
  }
  //传值方式绘制----------------------end--------------------

  //移除当前工具所有
  removeLayer() {
    //清空当前工具数据
    this.source.clear();
    this.drawLayer.dispose();
    //移除当前工具图层
    this.map.removeLayer(this.drawLayer);
    //移除测量监听
    this.removeMessureListener();
    //移除测量相关标记图层
    this.removeMessureOverlay();
  }

  //移除测量相关标记图层
  removeMessureOverlay(isFull) {
    let overlays = this.map.getOverlays().array_;
    // 当前测量工具唯一清除
    if (isFull) {
      for (let i = overlays.length - 1; i >= 0; i--) {
        if (overlays[i].type === this.toolTip + this.terminaOverlId) {
          this.map.removeOverlay(overlays[i]);
        }
      }
    }
    //当前测量工具所有清除
    else {
      for (let i = overlays.length - 1; i >= 0; i--) {
        if (overlays[i].type && overlays[i].type.indexOf(this.toolTip) > -1) {
          this.map.removeOverlay(overlays[i]);
        }
      }
    }
  }

  //移除掉绘制工具相关的事件以及交互
  removeMessureListener() {
    this.mapApi.unByKey(this.drawListener);
    this.mapApi.unByKey(this.drawStarter);
    this.mapApi.unByKey(this.drawPointerMove);
    this.mapApi.unByKey(this.drawPointerOut);
    this.mapApi.unByKey(this.drawSingleClick);
    this.sketch = null;
    this.helpMsg = this.startLineMsg;
    this.measureTooltipElement = null;
    this.helpMsg = null;
    this.drawListener = null;
    this.drawStarter = null;
    this.drawPointerMove = null;
    this.drawPointerOut = null;
    this.drawSingleClick = null;
    this.deactiveDraw();
  }

  //移除掉工具交互
  deactiveDraw() {
    this.map.removeInteraction(this.drawInteraction);
    this.removeDirectionLayer();
  }
}
export default Draw;
