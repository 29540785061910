//容器
<template>
  <div>
    <map-base ref="mapCom" :map="map" />
    <map-time ref="mapTime" :map="map" />
    <map-typhoon ref="typhoon" :map="map" />
    <!-- <wind v-if="wind" ref="wind" :map="map" /> -->
    <weather ref="weather" :map="map" />
    <map-other ref="other" :map="map" />
  </div>
</template>
<script>
import MapTime from "./time/index.vue";
import MapTyphoon from "./typhoon/map-typhoon";
import weather from "./weather/index.vue";
import MapOther from "./other/index.vue";
import SeaWeather_Distribute from "./js/SeaWeather_Distribute";
import SeaWeather_Particle from "./js/SeaWeather_Particle";
import SeaWeather_GridValue from "./js/SeaWeather_GridValue";
import SeaWeather_GridAngle from "./js/SeaWeather_GridAngle";
export default {
  name: "MapWeather",
  props: ["map"],
  components: {
    MapTime,
    MapTyphoon,
    weather,
    MapOther,
  },
  data() {
    return {
      weatherFun: null,
      weather_select: null,
      baseLayerLength: 0,
      mapWeather: this.$store.getters.mapWeather,
      mapOther: this.$store.getters.mapOther,
      mapTime: this.$store.getters.mapTime,
      baseUrl: "/mapWeather/" + "data/globalData/",
      type_select: null,
      time: new Date().getTime(),
      weatherParam: null,
      weatherData: null,
      isOther: null,
    };
  },
  provide() {
    return {
      dealTimeLine: this.dealTimeLine,
      SeaWeather_Distribute: SeaWeather_Distribute,
      SeaWeather_Particle: SeaWeather_Particle,
      SeaWeather_GridValue: SeaWeather_GridValue,
      SeaWeather_GridAngle: SeaWeather_GridAngle,
    };
  },
  computed: {},

  mounted() {
    this.initLayer();
  },
  watch: {
    "mapWeather.selected": {
      handler(v) {
        if (v) {
          setTimeout(() => {
            this.clearWeather();
            let type = v.type ? v.type : this.type_select;
            if (v.checked) this.dealWeather(type);
          }, 0);
        }
      },
      deep: true,
      immediate: true,
    },
    "mapTime.selected": {
      handler(v) {
        if (v) {
          if (
            v.type === 1 &&
            v.callType === 2 &&
            v.moduleType === "mapWeather"
          ) {
            console.log("启动时间轴", v.data.endTime);
            this.addWeather(v.data.endTime);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "mapOther.selected": {
      handler(v) {
        if (v) {
          this.clearWeather();
          this.isOther = true;
          let type = v.type ? v.type : this.type_select;
          if (v.checked) this.dealWeather(type);
        }
      },
      deep: true,
    },
  },
  methods: {
    // 初始化图层参数
    initLayer() {
      this.baseLayerLength = parseInt(
        this.$refs.mapCom.getbaseMapLayer().length
      );
      this.$refs.typhoon.init(this.baseLayerLength);
    },
    singleClick(evt) {
      let array = [];
      this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        array.push([feature, layer ? layer.get("id") : ""]);
        if (array.length === 1) {
          let feature = array[0][0];
          let layerId = array[0][1];
          this.$store.dispatch(
            "map/mapTyphoon/setSelected",
            Object.assign({
              type: 4,
              data: { feature: feature, layerId: layerId },
            })
          );
        }
      });
      let showSeaWeatherInfoWin = true;
      this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (
          feature &&
          layer &&
          layer.get("id") &&
          layer.get("id") !== "_gridValue"
        )
          showSeaWeatherInfoWin = false;
      });
      if (showSeaWeatherInfoWin) this.addSeaWeatherInfoWin(evt.coordinate);
    },
    touchOnly(feature, layerId) {
      this.$refs.seaWeather.touchOnly(feature, layerId);
    },
    moveStartEvt(evt) {
      this.$refs.seaWeather.moveStartEvt(evt);
    },
    moveEndEvt(evt) {
      this.$refs.seaWeather.moveEndEvt(evt);
    },
    dealTimeLine(checked) {
      this.$store.dispatch("map/mapTime/setSelected", {
        type: 1,
        callType: 1,
        moduleType: "mapWeather",
        data: {
          startTime: new Date().getTime(),
          endTime: new Date().getTime() + 3 * 24 * 60 * 60 * 1000,
          checked: checked,
          autoPlay: true,
        },
      });
    },
    dealWeather(value) {
      this.$nextTick(() => {
        this.type_select = value;
        // this[value] = true;
        this.$refs.weather.getShowWeather({
          type: this.type_select,
          checked: true,
        });
        console.log("选中气象类型", this.type_select);
        this.dealTimeLine(true);
      });
    },
    async addWeather(time) {
      if (!time) return;
      this.initLayer();
      this.time = time;
      // this.weather_select = this.$refs[this.type_select];
      this.weather_select = this.$refs.weather.bindWeather;
      const dealWeatherParam = async () => {
        const resolution = this.weather_select.resolution;
        const center = this.weather_select.center;
        const path =
          this.baseUrl +
          this.type_select +
          "/" +
          this.$parseTime(this.time, "{y}{m}{d}{h}")
            .replace(/-/g, "")
            .replace(/ /g, "") +
          "_" +
          this.type_select +
          "_" +
          resolution;
        let param = await this.getImageParam(path + ".json");
        param.xScale = Number(resolution);
        param.yScale = Number(resolution);
        param.maxLon = Number(param.Lonmax);
        param.minLon = Number(param.Lonmin);
        param.maxLat = Number(param.Latmax);
        param.minLat = Number(param.Latmin);
        param.maxValueR = Number(param.RMax);
        param.minValueR = Number(param.RMin);
        param.maxValueG = Number(param.GMax);
        param.minValueG = Number(param.GMin);
        param.lngs = [];
        param.lats = [];
        param.center = center;
        param.imageUrl = path + ".png";
        param.type = this.type_select;
        this.weatherParam = param;
        return param;
      };
      const dealWeatherData = async (param) => {
        let weatherData = [];
        const imageData = await this.getImageData(param);
        const values = imageData[0];
        const width = imageData[1];
        const height = imageData[2];
        const posValue = imageData[3];
        const types = imageData[4];
        if (posValue) {
          this.weatherParam.posValue = posValue;
          //最大最小值获取
          this.weatherParam.posValue.map((v) => {
            v.map((vv) => {
              if (!this.weatherParam.maxValue)
                this.weatherParam.maxValue = vv.value;
              if (!this.weatherParam.minValue)
                this.weatherParam.minValue = vv.value;
              if (vv.value > this.weatherParam.maxValue)
                this.weatherParam.maxValue = vv.value;
              if (vv.value < this.weatherParam.minValue)
                this.weatherParam.minValue = vv.value;
            });
          });
          const header = {
            dx: (param.maxLon - param.minLon) / width,
            dy: (param.maxLat - param.minLat) / height,
            nx: width,
            ny: height,
            lo1: param.minLon,
            la1: param.maxLat,
            la2: 0,
            maxLon: param.maxLon,
            minLat: param.minLat,
            // feature: _coords[1]
          };
          values.map((v, k) => {
            let json = {
              header: JSON.parse(
                JSON.stringify(Object.assign(header, { type: types[k] }))
              ),
              data: v,
            };
            weatherData.push(json);
          });
          // console.log("weatherData", weatherData);
          this.weatherData = weatherData;
          return weatherData;
        }
      };
      console.log("读取数据", this.type_select);
      if (!this.isOther) {
        this.weather_select.param = await dealWeatherParam();
        this.weather_select.data = await dealWeatherData(
          this.weather_select.param
        );
        this.weather_select.baseLayerLength = this.baseLayerLength;
        // await this.getWeaterPointData(this.weather_select.param.center);
        this.$refs.weather.addLayer();
      } else {
        const data = await this.getImageParam(
          "/mapWeather/ocean/" + this.type_select + ".json"
        );
        this.$refs.other.addData(data);
      }
    },
    getImageData(param) {
      return new Promise((resolve) => {
        let imageValue = [];
        let all = {};
        all.ImgData = new Image();
        all.ImgData.crossOrigin = "Anonymous";
        all.ImgData.src = param.imageUrl;
        all.ImgData.onload = function () {
          let imgCanvas = document.createElement("canvas");
          imgCanvas.width = all.ImgData.width;
          imgCanvas.height = all.ImgData.height;

          const imageContext = imgCanvas.getContext("2d");
          imageContext.drawImage(all.ImgData, 0, 0);
          let imagedata = imageContext.getImageData(
            0,
            0,
            all.ImgData.width,
            all.ImgData.height
          ).data;
          let valuesR = [],
            valuesG = [],
            values = [],
            types = [],
            n = null,
            R = null,
            G = null,
            B = null,
            valueR = null,
            valueG = null,
            computeWindUV = null,
            uv_value = null;
          for (let i = 0; i < all.ImgData.height; i++) {
            imageValue[i] = new Array();
            for (let j = 0; j < all.ImgData.width; j++) {
              n = i * 4 * all.ImgData.width + j * 4;
              R = imagedata[n];
              G = imagedata[n + 1];
              B = imagedata[n + 2];

              switch (param.type) {
                case "wind":
                case "current":
                  if (R == 0 && G == 0) {
                    imageValue[i][j] = 0;
                    valuesR.push(0);
                    valuesG.push(0);
                    continue;
                  }
                  valueR =
                    param.minValueR +
                    (R * (param.maxValueR - param.minValueR)) / 255;
                  valueG =
                    param.minValueG +
                    (G * (param.maxValueG - param.minValueG)) / 255;
                  valuesR.push(valueR);
                  valuesG.push(valueG);
                  computeWindUV = (u, v) => {
                    var value = Math.sqrt(u * u + v * v);
                    var angle = Math.acos(u / value);
                    if (v < 0) {
                      angle = -angle;
                    }
                    angle = Math.round((angle / Math.PI) * 180 + 90);
                    if (angle < 0) {
                      angle = angle + 360;
                    }
                    angle = Math.round(360 - angle);
                    value = value.toFixed(2);
                    return {
                      value: parseFloat(value),
                      angle: angle,
                    };
                  };
                  uv_value = computeWindUV(valueR, valueG);
                  imageValue[i][j] = {
                    value: uv_value.value,
                    angle: uv_value.angle,
                    uv: [valueR, valueG],
                  };
                  break;
                case "wave":
                  if (B == 255) {
                    imageValue[i][j] = 0;
                    valuesR.push(0);
                    continue;
                  }
                  valueR =
                    param.minValueR +
                    (R * (param.maxValueR - param.minValueR)) / 255;
                  valuesR.push(valueR);

                  imageValue[i][j] = {
                    value: valueR,
                  };
                  break;
              }
            }
          }
          valuesR.length !== 0 ? values.push(valuesR) : "";
          valuesG.length !== 0 ? values.push(valuesG) : "";
          switch (param.type) {
            case "wind":
              types.push("windU");
              types.push("windV");
              break;
            case "current":
              types.push("currentU");
              types.push("currentV");
              break;
            case "wave":
              types.push("waveU");
              types.push("waveV");
              break;
          }
          resolve([
            values,
            all.ImgData.width,
            all.ImgData.height,
            imageValue,
            types,
          ]);
        };
      });
    },
    getImageParam(url) {
      return new Promise((resolve) => {
        this.$axios({
          method: "get",
          url: url,
        }).then((result) => {
          resolve(result);
        });
      });
    },
    clearWeather() {
      this.dealTimeLine(false);
      // this[this.type_select] = false;
      this.$refs.weather.getShowWeather({
        type: this.type_select,
        checked: false,
      });
      this.$refs.other.clear();
      this.type_select = null;
      this.$refs.mapCom.removeSeaWeatherInfo();
      this.weatherParam = null;
      this.weatherData = null;
      this.isOther = false;
    },
    // 判断数据是否加载成功
    getWeaterPointData(coordinate) {
      if (
        this.weatherParam &&
        this.weatherParam &&
        this.weatherParam.posValue
      ) {
        const data = this.getWeatherValue(coordinate)[2];
        if (data) {
          return { value: data };
        } else {
          return null;
        }
      } else {
        return new Promise((resolve) => {
          let time = new Date().getTime();
          const fun = () => {
            let timeOut = setTimeout(() => {
              if (
                this.weatherParam &&
                this.weatherParam &&
                this.weatherParam.posValue
              ) {
                let data = this.getWeatherValue(coordinate)[2];
                clearTimeout(timeOut);
                resolve({ value: data });
              } else {
                if (new Date().getTime() - time > 5 * 1000)
                  clearTimeout(timeOut);
                else fun();
              }
            });
          };
          fun();
        });
      }
    },
    // 获取点位信息
    getWeatherValue(coordinate) {
      let isValue = function isValue(x) {
        return x !== null && x !== undefined;
      };
      let interpolate = (x, y, g00, g10, g01, g11) => {
        let rx = 1 - x;
        let ry = 1 - y;
        return g00 * rx * ry + g10 * x * ry + g01 * rx * y + g11 * x * y;
      };
      const λ = coordinate[0];
      const φ = coordinate[1];
      const λ0 = this.weatherParam.minLon;
      const φ0 = this.weatherParam.maxLat;
      const maxLon = this.weatherParam.maxLon;
      const minLat = this.weatherParam.minLat;
      const Δλ = this.weatherParam.xScale;
      const Δφ = this.weatherParam.yScale;
      if (λ < λ0 || φ0 < φ || λ > maxLon || φ < minLat) {
        return -1;
      }
      let grid = this.weatherParam.posValue;
      let i = (λ - λ0) / Δλ;
      let j = (φ0 - φ) / Δφ;
      let fi = Math.floor(i),
        ci = fi + 1;
      let fj = Math.floor(j),
        cj = fj + 1;
      let row = grid[fj];
      // console.log(row);
      if (row) {
        let g00 = row[fi].value;
        let g10 = row[ci].value;
        if (ci === row.length) g10 = row[row.length - 1].value;
        row = grid[cj];
        if (cj === grid.length) row = grid[grid.length - 1];
        if (isValue(g00) && isValue(g10)) {
          let g01 = row[fi].value;
          let g11 = row[ci].value;
          if (ci === row.length) g11 = row[row.length - 1].value;

          if (isValue(g01) && isValue(g11)) {
            return [
              fj,
              fi,
              interpolate(i - fi, j - fj, g00, g10, g01, g11),
              grid[fj][fi].angle,
            ];
          }
        }
      }
    },
    // 信息窗口
    addSeaWeatherInfoWin(coordinate) {
      if (!this.weather_select) return;
      this.$refs.mapCom.removeLightMarkerLayer();
      this.$refs.mapCom.removeSeaWeatherInfo();
      const time = this.$timestampTostr(this.time);
      const weatherValue = this.getWeatherValue(coordinate);
      const data = weatherValue[2];
      const lat = weatherValue[0];
      const lon = weatherValue[1];
      const angle = weatherValue[3];
      const pos =
        (coordinate[0] < 180
          ? coordinate[0].toFixed(4) + "°E"
          : (360 - coordinate[0]).toFixed(4) + "°W") +
        " ," +
        (coordinate[1] > 0
          ? coordinate[1].toFixed(4) + "°N"
          : -coordinate[1].toFixed(4) + "°S");
      if (!data) return;
      this.$refs.weather.infoWin({
        data: data,
        lat: lat,
        lon: lon,
        angle: angle,
        time: time,
        pos: pos,
        coordinate: coordinate,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.seaWeather {
  z-index: 99;
  width: 100%;
  height: auto;
  width: auto;
}

input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 1rem;
  position: relative;
  white-space: nowrap;
}
</style>
