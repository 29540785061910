import request from "@/utils/request";
//台风路径列表
export function getTyphoonList(query) {
  return request({
    url: "/mapWeather//api/getTyphoonList.json",
    method: "get",
    params: query,
  });
}
//台风轨迹
export function getTyphoonRoute(query) {
  return request({
    url: "/mapWeather//api/getTyphoonRoute.json",
    method: "get",
    params: query,
  });
}
