export default {
  CreateInfoWindow(data, title, buttonOption, uniqueKey, callBack) {
    let fontColor = "#FF0000";
    if (!data && data.length === 0) return;
    else if (data.length === 1) {
      data = data[0];
      let appendHtml = "";
      appendHtml += '<div class="collapseEle" style="padding-top:10px;">';
      for (let v in data) {
        if (v === "fontColor") fontColor = data[v];
        if (
          v !== "name" &&
          v !== "id" &&
          v !== "bxtId" &&
          v !== "sbdId" &&
          v !== "dragId" &&
          v !== "aliasTitle" &&
          v !== "fontColor"
        )
          appendHtml +=
            '<div class="map_col_6" style="margin-bottom:5px">' +
            '<div class="map_label">' +
            v +
            ":</div>" +
            '<div class="map_value" >' +
            (data[v] ? data[v] : "") +
            "</div>" +
            "</div>";
      }
      let buttonHtml = "";
      if (buttonOption instanceof Array) {
        buttonOption.map((v) => {
          buttonHtml += v.show
            ? '<button class="buttonContent" style="background-color:rgba(45,138,233,1);color:white;padding:3px 6px;font-size:0.7rem;margin-right:2px">' +
              v.name +
              "</button>"
            : "";
        });
        let eleInterval = setInterval(() => {
          let ele = document.querySelector("#buttonInfoWin" + uniqueKey);
          if (ele) {
            clearInterval(eleInterval);
            for (let i = 0; i < ele.children.length; i++) {
              const item = buttonOption.find(
                (v) => ele.children[i].innerText === v.name && v.show
              );
              callBack(item, ele.children[i]);
              const eventType = item.type ? item.type : "click";
              if (item.fun)
                ele.children[i].addEventListener(eventType, () => {
                  item.fun();
                });
            }
          }
        });
      }
      appendHtml +=
        '<div class="buttonInfoWin" id="buttonInfoWin' +
        uniqueKey +
        '">' +
        buttonHtml +
        "</div>";
      appendHtml += "</div>";
      // #FF0000
      // #8A8A8A
      return (
        '<div class="map_info_container dragForbit" style="background:#EDEDED;border-radius:unset;box-shadow:none;padding:15px;font-weight:400;color:' +
        fontColor +
        ";border:1px solid " +
        fontColor +
        ';">' +
        '<div class="map_headdiv" style="font-weight:bold;padding:0px">' +
        '<span class="map_headtitle">' +
        title +
        "</span>" +
        '<div class="map_collapse collapseInfoWin' +
        uniqueKey +
        '">收放</div>' +
        '<img class="map_closeSpan closeInfoWin' +
        uniqueKey +
        ' " src="data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDRTA1RUUzQkRCODUxMUVBOEE5NUFDRDNEMzVFQzJEQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDRTA1RUUzQ0RCODUxMUVBOEE5NUFDRDNEMzVFQzJEQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkNFMDVFRTM5REI4NTExRUE4QTk1QUNEM0QzNUVDMkRCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNFMDVFRTNBREI4NTExRUE4QTk1QUNEM0QzNUVDMkRCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+1YvihgAAAitJREFUeNrEl0svA1EUx08rbIR620iUSNgRj9hL1N7C4yMUKYkPILGTIDTUR/BY2KuFtQhhRzzCxqI0Wo9Nm4bz1zMyJp25d6qtf/JPmrlnzm/uzLm353qGjj5JQ+XsEfYwe4Ddzq6RsQT7jn3CPmQfsNOqhB4FGMnn2FPsRtLTM3uTvSYPlVNehwQT7Ev2ggso1CD3XEoObXAZO8LeZjdT/mqWHBHJ6QhGwB47SIVTUHKWOYHxXUap8ELOdTvwOHuGiqdpYfwCo3rDVHyFjWVogLFkmkoAbhLWN7hC1qmj+mqJfOX24xhDjIbAqgA4oFqn/XVES91Eqz38nnLAcW2lJxujAQcrYIAddfNG9PBB5K/MAsxw/F7ma22V2Zjbd61ZjwDcq4pK8M47f050b4EbMwUUY4hJpLXAvdirY7pbogHyCwjyu4dCT5ixTzfaOvM8od+16KV/EsBJ3WDrq763KTgNJQG+zgeK15ur4DR1DfCpa+hF9pvaVbuGzgCOqqI6qohazdBU7oJDDGI1FMVywpb5yK53ihzk0atX++rFltlVTXQcVy8ldgtmjOffUEUjodOSSaa1oCQdScpragBiJVhFT8L6+VtENxgqAThkdJ7mDWRX2tJiCbl37HquWfZ+EaD7ktu22cuwx9hbBYRuSc6Mqq/OSJcw+ceCi0mOKStUdZLA9+hkL7LjLoBxuafT/E3dnp2sh7aA6dBmNDkvpkNbVJxSJfwSYAADb65XjeoV0wAAAABJRU5ErkJggg==" />' +
        "</div>" +
        appendHtml +
        // '<div class="buttonInfoWin" id="buttonInfoWin' + uniqueKey + '">' + buttonHtml + '</div>' +
        "</div>"
      );
    }
  },
};
