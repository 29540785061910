export default {
  install(Vue) {
    /**
     * 设配适配
     * @returns
     */
    Vue.prototype.$isMobile = function isMobile() {
      return /android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i.test(
        navigator.userAgent
      );
    };
    Vue.prototype.$parseTime = function (time, cFormat) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string") {
          if (/^[0-9]+$/.test(time)) {
            // support "1548221490638"
            time = parseInt(time);
          } else {
            // support safari
            // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            time = time.replace(new RegExp(/-/gm), "/");
          }
        }

        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
      });
      return time_str;
    };
    Vue.prototype.$getTimeFormat = function (
      timestamp,
      format = "yyyy-mm-dd hh:mm:ss"
    ) {
      var date = new Date(timestamp); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear();
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      const dateJson = {
        "yyyy-mm-dd hh:mm:ss":
          Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s,
        "yyyy-mm-dd": Y + "-" + M + "-" + D,
        "yyyy/mm/dd": Y + "/" + M + "/" + D,
        yyyymmdd: Y + M + D,
        yyyy年mm月dd日: Y + "年" + M + "月" + D + "日",
        mm月dd日: M + "月" + D + "日",
        hh时: h + "时",
        "yyyy-mm-dd hh": Y + "-" + M + "-" + D + " " + h,
      };
      return dateJson[format];
    };
    Vue.prototype.$getDataLevel = function (data) {
      let level = 0;
      let json = {};
      let levelMax = 0;
      const fun = (dParent, dI) => {
        let d = dParent[dI];
        if (!json[d.type + level]) {
          level++;
          json[d.type + level] = true;
        }
        if (d.data) {
          for (let m = 0; m < d.data.length; m++) {
            if (d.data[m].data) fun(d.data, m);
            else {
              if (levelMax < level) levelMax = level;
            }
          }
        }
      };
      let i = 0;
      while (i < data.length) {
        fun(data, i);
        i++;
      }
      return levelMax;
    };
    Vue.prototype.$getNowDate = function (format, day = 0) {
      var date = new Date();
      var targetday_milliseconds = date.getTime() + 1000 * 60 * 60 * 24 * day;
      date.setTime(targetday_milliseconds); //注意，这行是关键代码
      var Y = date.getFullYear();
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + "" : date.getDate() + "";
      var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      const dateJson = {
        "yyyy-mm-dd hh:mm:ss":
          Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s,
        "yyyy-mm-dd": Y + "-" + M + "-" + D,
        "yyyy/mm/dd": Y + "/" + M + "/" + D,
        yyyymmdd: Y + M + D,
        yyyy年mm月dd日: Y + "年" + M + "月" + D + "日",
        mm月dd日: M + "月" + D + "日",
        hh时: h + "时",
        "yyyy-mm-dd hh": Y + "-" + M + "-" + D + " " + h,
      };
      return dateJson[format];
    };
    /**
     * /<summary>将度转换成为度分秒</summary>
     * @param {*} value
     */
    Vue.prototype.$formatDegree = function (value) {
      value = Math.abs(value);
      var v1 = Math.floor(value); // 度
      var v2 = Math.floor((value - v1) * 60); // 分
      var v3 = Math.round(((value - v1) * 3600) % 60); // 秒
      return v1 + "°" + v2 + "'" + v3 + '"';
    };
    /**
     * <summary>将度分秒转换成为度</summary>
     * @param {*} value
     */
    Vue.prototype.$formatRadius = function (value) {
      var v1 = value.split("°")[0];
      var v2 = value.split("°")[1].split("'")[0];
      var v3 = value.split("°")[1].split("'")[1].split('"')[0];
      var v4 = parseFloat(v2) / 60 + parseFloat(v3) / 3600;
      return parseFloat(v1) + v4;
    };
    /**
     * 将时间戳转换成字符串日期
     */
    Vue.prototype.$timestampTostr = function (
      timestamp,
      format = "yyyy-mm-dd hh:mm:ss"
    ) {
      var date = new Date(timestamp); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear();
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + "" : date.getDate() + "";
      var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      const dateJson = {
        "yyyy-mm-dd hh:mm:ss":
          Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s,
        "yyyy-mm-dd": Y + "-" + M + "-" + D,
        "yyyy/mm/dd": Y + "/" + M + "/" + D,
        yyyymmdd: Y + M + D,
        yyyy年mm月dd日: Y + "年" + M + "月" + D + "日",
        mm月dd日: M + "月" + D + "日",
        hh时: h + "时",
        "yyyy-mm-dd hh": Y + "-" + M + "-" + D + " " + h,
        "yyyy/mm": Y + "/" + M,
        yyyy: Y,
      };
      return dateJson[format];
    };
    Vue.prototype.$getDateArr = function (startTime, endTime) {
      startTime = new Date(startTime);
      endTime = new Date(endTime);
      var date_all = [];
      var i = 0;
      while (endTime.getTime() - startTime.getTime() >= 0) {
        var year = startTime.getFullYear();
        var month = startTime.getMonth() + 1;
        var day = startTime.getDate();
        day = day < 10 ? "0" + day : day;
        date_all[i] = year + "-" + month + "-" + day;
        startTime.setDate(startTime.getDate() + 1);
        i += 1;
      }
      return date_all;
    };
  },
};
