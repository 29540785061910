
const state = {

  isVisible: false,//台风弹窗
  menuIndex: 0//功能模块索引
};
const mutations = {
  SET_VISIBLE(state) {
    state.isVisible = !state.isVisible;
    // state.isVisible ? state.isVisible = false : state.isVisible = true;

  },
  SET_INDEX(state, param) {
    state.menuIndex = param
  }
};
const actions = {
  setIndex(context, param) {
    context.commit("SET_INDEX", param);
  },

  setVisible(context) {
    context.commit("SET_VISIBLE")
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
