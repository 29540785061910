<template>
  <div>
    <map-base ref="mapCom" :map="map" />
  </div>
</template>
<script>
export default {
  name: "WeatherOther",
  props: ["map", ""],
  components: {},
  inject: [],
  data() {
    return {
      layerId: "weather_other",
    };
  },
  watch: {},
  mounted() {
    console.log(this.layerId + "Create");
  },
  methods: {
    async addData(res) {
      if (res) {
        this.clear();
        const data = res[0];
        const range = data.range;
        const imgUrl = data.url;
        let layer = new this.mapApi.imageLayer({
          id: this.layerId,
          source: new this.mapApi.ImageStaticSource({
            name: this.layerId,
            // url: require(`${imgUrl}`),
            url: imgUrl,
            projection: "EPSG:4326",
            imageExtent: [range[0][1], range[0][0], range[1][1], range[1][0]],
          }),
        });
        this.$refs.mapCom.insertAtLayer(10, layer);
      }
    },
    clear() {
      let layer = this.$refs.mapCom.getLayer(this.layerId, 1);
      console.log(11, layer, this.layerId);
      if (layer) this.map.removeLayer(layer);
    },
  },
  beforeDestroy() {
    console.log(this.layerId + "Destroy");
    this.clear();
  },
};
</script>
<style lang="scss" scoped></style>
