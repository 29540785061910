const state = {
  selected: {
    type: null, //底图类型 imageMap:影像;htMap:海图;vectorMap:矢量
    change: null,
  },
};
const mutations = {
  SET_SELECTED: (state, param) => {
    setTimeout(() => {
      for (let v in state.selected)
        if (param[v] !== undefined) state.selected[v] = param[v];
      state.selected["change"] = !state.selected["change"];
    });
  },
};
const actions = {
  setSelected(context, param) {
    context.commit("SET_SELECTED", param);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
