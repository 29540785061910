const state = {
  selected: {
    type: null, //气象类型  wind:风;current:流场;wave：海浪;
    checked: false,
    data: null,
    change: null,
  },
};
const mutations = {
  SET_SELECTED: (state, param) => {
    setTimeout(() => {
      for (let v in state.selected) {
        if (param[v] !== undefined) state.selected[v] = param[v];
        else state.selected[v] = null;
      }
      state.selected["change"] = !state.selected["change"];
    });
  },
};
const actions = {
  setSelected(context, param) {
    context.commit("SET_SELECTED", param);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
