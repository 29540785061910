<template>
  <div class="mapTestPage">
    <map-base ref="mapCom" :map="map" />
  </div>
</template>

<script>
export default {
  name: "MapTestPage",
  components: {},
  props: ["map"],
  data() {
    return {
      layerId: "mapTest",
    };
  },
  inject: [],
  watch: {},
  onLoad() {},
  mounted() {
    this.mapTest();
  },
  methods: {
    mapTest() {
      let layer = this.$refs.mapCom.createLayer("test");
      this.map.addLayer(layer);
      const fun = (data) => {
        let features = new this.mapApi.WKT().readFeatures(data);
        // if (!data) return;
        // let features = new this.mapApi.GeoJSON().readFeatures(data);
        let str = [];
        features.map((v) => {
          str.push({
            coorSring: new this.mapApi.WKT().writeFeatures([v]),
            name: v.get("name"),
          });
          v.setStyle(
            new this.mapApi.Style({
              fill: new this.mapApi.Fill({
                color: "rgba(255,255,0,0.5)",
              }),
              stroke: new this.mapApi.Stroke({
                color: "purple",
                width: 2,
              }),
              text: new this.mapApi.Text({
                font: "bold 20px sans-serif",
                offsetX: 50,
                offsetY: 0,
                scale: 1,
                text: v.get("name") ? v.get("name") : v.get("NAME"),
                fill: new this.mapApi.Fill({ color: "purple" }),
                textBaseline: "bottom",
                stroke: new this.mapApi.Stroke({
                  // 线样式
                  color: "#fff",
                  width: 1,
                }),
              }),
            })
          );
          layer.getSource().addFeature(v);
        });
        console.log("str", str);
      };
      // fun(require("./data/新2海里.json"));
      // fun(require("./data/2.5海里.json"));
      // fun(require("./data/渔港范围2.5.json"));
      // fun(require("./data/northSouthEntrance.json"));
      // fun(require("./data/AAA.json"));
      fun();
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped></style>
