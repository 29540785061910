//数据静态页

const mapId = "ygh-map";
// 系统的地图相关访问url
const mapUrl = {
  1: "http://58.59.43.176:8001/gisdata/", // 数据地址
  2: "http://58.59.43.124:8003/",
  3: "http://192.168.0.35:8080/",
  4: "http://192.168.0.59:8082/yinggehai/",
  5: "http://192.168.0.124:8001/static/seamap/all/", //所有 //'http://192.168.0.124:8001/static/seamap/std/ 标准
};
const changeZoom = 11; //聚类级别
const clusterDistance = 100; //聚类距离
const heatBlur = 10; //热力图blur
const heatRadius = 5; //热力图radius
const layer = {
  0: {
    layerId: "bd",
    rightContext: true,
  },
  1: {
    layerId: "ais",
    rightContext: true,
  },
  2: {
    layerId: "comb",
    rightContext: true,
  },
  6: {
    layerId: "seaWeatherInfo",
  },
  7: {
    layerId: "mapInfowinContainer",
  },
  8: {
    layerId: "typhoon",
  },
  9: {
    layerId: "service",
    rightContext: true,
  },
};
const keys = {
  longitude: "经度",
  latitude: "经度",
  lon_lat_error: "经纬度输入有误",
  position_for: "定位专属",
  maxInfoCount: "超出设定数目,默认位置加载",
  endTime_to_sartTime: "开始时间大于于结束时间,请核查",
  right_click_context: "右键单击样板",
  areaPlayback_fixed: "当前处于区域回放模块,请退出再行选择",
  extentFilter_fixed: "当前处于拉框搜索模块,请退出再行选择",
  mapSwitch_null: "尚未列入地图数据_",
  name: "名称",
  geoLayer: "地理图层",
  shipName: "船名",
  time: "时间",
  speed: "航速",
  course: "航向",
  dragId: "dragId",
};
const infoWinPosition = {
  countX: 1,
  countY: 6,
  height: 0,
  infoNormalObj: null,
  pixel: {
    0: "", //居中
    1: "", //左上角
    2: "", //右上角
    3: "", //右下角
    4: "", //左下角
  },
};
export default {
  mapId: mapId,
  mapUrl: mapUrl,
  changeZoom: changeZoom,
  clusterDistance: clusterDistance,
  heatBlur: heatBlur,
  heatRadius: heatRadius,
  layer: layer,
  infoWinPosition: infoWinPosition,
  keys: keys,
};
