const state = {
  selected: {
    type: null, //类型：1、时间轴 2、时间选择控件
    callType: null, //数据传递类型 1、传递 2、回调
    data: null, // 时间数据
    showData: {},
    moduleType: null, // 模块类型
    change: null,
    showId: null,
  },
  dataRecord: {},
};
const mutations = {
  SET_SELECTED: (state, param) => {
    setTimeout(() => {
      for (let v in state.selected)
        if (param[v] !== undefined) state.selected[v] = param[v];
      state.selected["change"] = !state.selected["change"];
    });
  },
  REMOVE_DATARECORD: (state, param) => {
    setTimeout(() => {
      state.dataRecord = param.dataRecord;
    });
  },
};
const actions = {
  setSelected(context, param) {
    context.commit("SET_SELECTED", param);
  },
  removeDataRedord(context, param) {
    context.commit("REMOVE_DATARECORD", param);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
