<template>
  <div>
    <map-base ref="mapCom" :map="map" />
    <typhoon-list ref="tl" :map="map" />
    <typhoon-evt
      :map="map"
      @drawTyphoon="drawTyphoon"
      @clearTyphoon="clearTyphoon"
      @clearAllTyphoon="clearAllTyphoon"
      @typhoonCircle="typhoonCircle"
      @listShow="listShow"
    />
  </div>
</template>
<script>
import TyphoonList from "./typhoonList";
import TyphoonEvt from "./typhoonEvt";
export default {
  name: "MapTyphoon",
  props: ["map"],
  components: { TyphoonList, TyphoonEvt },
  data() {
    return {
      typhoonInterval: [], //台风轨迹定时器
      typhoonRotationInterval: [], //台风历史轨迹结束风圈事件
      layerIndex: new Map([
        ["typhoonCircle", 0],
        ["typhoonEnd", 0],
        ["typhoonRoute", 0],
        ["typhoonForecastRoute", 0],
        ["typhoonCenter", 0],
        ["typhoonForecastCenter", 0],
      ]),
    };
  },
  mounted() {},
  methods: {
    // 初始化配置
    init(layerIndex) {
      let i = 0;
      for (let data of this.layerIndex) {
        this.layerIndex.set(data[0], i + layerIndex);
        i++;
      }
      this.hidding = document.getElementById("hidding");
    },
    listShow() {
      this.$refs.tl.showPop();
    },
    /**
     * 风圈绘制
     */
    typhoonCircle(data) {
      this.$refs.mapCom.removeLayer(data.TYPHOON_ID + "typhoonCircle");
      this.$refs.mapCom.insertAtLayer(
        this.layerIndex.get("typhoonCircle"),
        this.$refs.mapCom.createLayer(data.TYPHOON_ID + "typhoonCircle")
      );

      let circleArray = [];
      if (data.level < 10) {
        circleArray.push({
          radius: data.radius7,
          level: data.level,
          // fillColor: "rgba(0,255,0,0.3)",
          fillColor: "rgba(186,191,187,0.5)",
          strokeColor: "rgba(0,255,0,0.3)",
        });
      } else if (data.level < 12) {
        circleArray.push({
          radius: data.radius7,
          level: data.level,
          // fillColor: "rgba(0,255,0,0.3)",
          // fillColor: "rgba(101,65,45,0.3)",
          fillColor: "rgba(186,191,187,0.5)",
          strokeColor: "rgba(0,255,0,0.3)",
        });
        circleArray.push({
          radius: data.radius10,
          level: data.level,
          // fillColor: "rgba(255,255,0,0.3)",
          // fillColor: "rgba(101,65,45,0.3)",
          fillColor: "rgba(186,191,187,0.5)",
          strokeColor: "rgba(255,255,0,0.3)",
        });
      } else {
        circleArray.push({
          radius: data.radius7,
          level: data.level,
          // fillColor: "rgba(0,255,0,0.3)",
          // fillColor: "rgba(101,65,45,0.3)",
          fillColor: "rgba(186,191,187,0.5)",
          strokeColor: "rgba(0,255,0,0.3)",
        });
        circleArray.push({
          radius: data.radius10,
          level: data.level,
          // fillColor: "rgba(255,255,0,0.3)",
          // fillColor: "rgba(101,65,45,0.3)",
          fillColor: "rgba(186,191,187,0.5)",
          strokeColor: "rgba(255,255,0,0.3)",
        });
        circleArray.push({
          radius: data.radius12,
          level: data.level,
          // fillColor: "rgba(255,0,0,0.3)",
          // fillColor: "rgba(101,65,45,0.3)",
          fillColor: "rgba(186,191,187,0.5)",
          strokeColor: "rgba(255,0,0,0.3)",
        });
      }
      let _interval = 6;
      circleArray.map((v) => {
        let _coords = [[]];
        for (let i = 0; i < 360 / _interval; i++) {
          let _r = 0;
          let _ang = i * _interval;
          if (_ang > 0 && _ang <= 90) {
            _r = v.radius.NE;
          } else if (_ang > 90 && _ang <= 180) {
            _r = v.radius.NW;
          } else if (_ang > 180 && _ang <= 270) {
            _r = v.radius.SW;
          } else {
            _r = v.radius.SE;
          }
          let x = data.centerX + _r * Math.cos((_ang * 3.14) / 180);
          let y = data.centerY + _r * Math.sin((_ang * 3.14) / 180);
          _coords[0].push([x, y]);
        }
        _coords[0].push([_coords[0][0][0], _coords[0][0][1]]);
        let feature = new this.mapApi.Feature({
          geometry: new this.mapApi.Polygon(_coords),
        });
        feature.setStyle(
          new this.mapApi.Style({
            fill: new this.mapApi.Fill({
              color: v.fillColor,
            }),
            stroke: new this.mapApi.Stroke({
              color: v.strokeColor,
              width: 2,
            }),
          })
        );
        this.$refs.mapCom
          .getLayer(data.TYPHOON_ID + "typhoonCircle")
          .getSource()
          .addFeature(feature);
      });
    },
    /**
    /**
     * 历史轨迹样式
     */
    typhoonRoute(
      data,
      typhoonCenterArray,
      typhoonRouteArray,
      historyRoute,
      callback
    ) {
      let lineFeature;
      let feature = new this.mapApi.Feature({
        geometry: new this.mapApi.Point([data.centerX, data.centerY]),
      });
      typhoonRouteArray.push([data.centerX, data.centerY]);
      if (typhoonRouteArray.length > 1) {
        lineFeature = new this.mapApi.Feature(
          new this.mapApi.LineString([
            [
              typhoonRouteArray[typhoonRouteArray.length - 2][0],
              typhoonRouteArray[typhoonRouteArray.length - 2][1],
            ],
            [
              typhoonRouteArray[typhoonRouteArray.length - 1][0],
              typhoonRouteArray[typhoonRouteArray.length - 1][1],
            ],
          ])
        );
      } else {
        lineFeature = new this.mapApi.Feature(
          new this.mapApi.LineString([
            [typhoonRouteArray[0][0], typhoonRouteArray[0][1]],
            [typhoonRouteArray[0][0], typhoonRouteArray[0][1]],
          ])
        );
      }
      let imageColor;
      imageColor = this.getImageColor(data.speed);
      lineFeature.setStyle(
        new this.mapApi.Style({
          stroke: new this.mapApi.Stroke({
            color: imageColor,
            width: 2,
          }),
        })
      );
      feature.setStyle([
        new this.mapApi.Style({
          image: new this.mapApi.Circle({
            radius: 10,
            fill: new this.mapApi.Fill({
              color: "rgba(255,255,255,0.005)",
            }),
            // stroke: new this.mapApi.Stroke({
            //   color: "rgba(255,255,255,0.1)",
            //   width: 1,
            // }),
          }),
        }),
        new this.mapApi.Style({
          image: new this.mapApi.Circle({
            radius: data.centerRadius,
            fill: new this.mapApi.Fill({
              color: imageColor,
            }),
            stroke: new this.mapApi.Stroke({
              color: "rgba(0,0,0,1)",
              width: 1,
            }),
          }),
        }),
      ]);
      feature.setProperties(data);
      this.$refs.mapCom
        .getLayer(historyRoute[0].TYPHOON_ID + "typhoonRoute")
        .getSource()
        .addFeature(lineFeature);
      this.$refs.mapCom
        .getLayer(historyRoute[0].TYPHOON_ID + "typhoonCenter")
        .getSource()
        .addFeature(feature);
      callback(feature, lineFeature);
      // //历史轨迹刷新位置
      // this.map.getView().setCenter(feature.getGeometry().flatCoordinates);
    },
    /**
     * 预测轨迹
     */
    forecastRoute(forecastRouteData, id) {
      this.$refs.mapCom.insertAtLayer(
        this.layerIndex.get("typhoonForecastRoute"),
        this.$refs.mapCom.createLayer(id + "typhoonForecastRoute")
      );
      this.$refs.mapCom.insertAtLayer(
        this.layerIndex.get("typhoonForecastCenter"),
        this.$refs.mapCom.createLayer(id + "typhoonForecastCenter")
      );
      forecastRouteData.map((v) => {
        let lineFeature;
        let typhoonForecastRouteArray = [];
        v.map((vv, kk) => {
          let data = {
            name: vv.CHN_NAME,
            engName: vv.ENG_NAME,
            time: vv.RQSJ,
            countryName: vv.FORCAST_COUNTRY_NAME,
            centerX: parseFloat(vv.JD),
            centerY: parseFloat(vv.WD),
            PA: vv.CONTER_PA,
            centerRadius: 3.5,
            level: parseInt(vv.POWER),
            speed: parseInt(vv.CENTER_WIND),
            moveSpeed: parseInt(vv.MOVE_SPEED),
            TYPHOON_ID: vv.TYPHOON_ID,
          };
          let imageColor, lineColor;
          imageColor = this.getImageColor(data.speed);
          let feature;
          if (!(kk === 0)) {
            feature = new this.mapApi.Feature({
              geometry: new this.mapApi.Point([data.centerX, data.centerY]),
            });
            feature.setStyle([
              new this.mapApi.Style({
                image: new this.mapApi.Circle({
                  radius: 10,
                  fill: new this.mapApi.Fill({
                    color: "rgba(255,255,255,0.1)",
                  }),
                  stroke: new this.mapApi.Stroke({
                    color: "rgba(255,255,255,0.1)",
                    width: 1,
                  }),
                }),
              }),
              new this.mapApi.Style({
                image: new this.mapApi.Circle({
                  radius: data.centerRadius,
                  fill: new this.mapApi.Fill({
                    color: imageColor,
                  }),
                  stroke: new this.mapApi.Stroke({
                    color: "rgba(0,0,0,1)",
                    width: 1,
                  }),
                }),
              }),
            ]);
            feature.setProperties(data);
            this.$refs.mapCom
              .getLayer(id + "typhoonForecastCenter")
              .getSource()
              .addFeature(feature);
            // //预测轨迹中心点跟踪位置
            // this.map.getView().setCenter(feature.getGeometry().flatCoordinates);
          }
          typhoonForecastRouteArray.push([data.centerX, data.centerY]);
          if (typhoonForecastRouteArray.length > 1) {
            lineFeature = new this.mapApi.Feature(
              new this.mapApi.LineString([
                [
                  typhoonForecastRouteArray[
                    typhoonForecastRouteArray.length - 2
                  ][0],
                  typhoonForecastRouteArray[
                    typhoonForecastRouteArray.length - 2
                  ][1],
                ],
                [
                  typhoonForecastRouteArray[
                    typhoonForecastRouteArray.length - 1
                  ][0],
                  typhoonForecastRouteArray[
                    typhoonForecastRouteArray.length - 1
                  ][1],
                ],
              ])
            );
          } else {
            lineFeature = new this.mapApi.Feature(
              new this.mapApi.LineString([
                [
                  typhoonForecastRouteArray[0][0],
                  typhoonForecastRouteArray[0][1],
                ],
                [
                  typhoonForecastRouteArray[0][0],
                  typhoonForecastRouteArray[0][1],
                ],
              ])
            );
          }
          switch (data.countryName) {
            case "日本":
              lineColor = "#43FF4B";
              break;
            case "美国":
              lineColor = "#40DDFF";
              break;
            case "中国":
              lineColor = "#FF0012";
              break;
            case "中国香港":
              lineColor = "#FF40F5";
              break;
            case "中国台湾":
              lineColor = "#FFA040";
              break;
            case "韩国":
              lineColor = "#000000";
              break;
          }
          lineFeature.setStyle(
            new this.mapApi.Style({
              stroke: new this.mapApi.Stroke({
                color: lineColor,
                lineDash: [1, 2, 3, 4, 5],
                width: 2,
              }),
            })
          );
          this.$refs.mapCom
            .getLayer(id + "typhoonForecastRoute")
            .getSource()
            .addFeature(lineFeature);
        });
      });
      //预测轨迹绘制完刷新位置
      // this.map.getView().setCenter(
      //   this.$refs.mapCom
      //     .getLayer(id + "typhoonForecastRoute")
      //     .getSource()
      //     .getFeatures()[0]
      //     .getGeometry().flatCoordinates
      // );
      // this.map.getView().setZoom(4);
      this.hidding.style.display = "none";
    },
    /**
     * 绘制台风
     */
    drawTyphoon(typhoonData) {
      if (!typhoonData.rows) return;
      let coords = [];
      typhoonData.rows.HistoryRoute.map((v) => {
        coords.push({ longitude: v.JD, latitude: v.WD });
      });
      typhoonData.rows.ForecastRoute.map((v) => {
        v.map((vv) => {
          coords.push({ longitude: vv.JD, latitude: vv.WD });
        });
      });
      const fun = () => {
        this.hidding.style.display = "block";
        let intervalIndex = -1;
        let typhoonCenterArray = [];
        let typhoonRouteArray = [];
        //路径图层每一个台风始终一个
        if (
          !this.$refs.mapCom.getLayer(
            typhoonData.rows.HistoryRoute[0].TYPHOON_ID + "typhoonEnd"
          )
        ) {
          this.$refs.mapCom.insertAtLayer(
            this.layerIndex.get("typhoonEnd"),
            this.$refs.mapCom.createLayer(
              typhoonData.rows.HistoryRoute[0].TYPHOON_ID + "typhoonEnd"
            )
          );
        }
        if (
          !this.$refs.mapCom.getLayer(
            typhoonData.rows.HistoryRoute[0].TYPHOON_ID + "typhoonRoute"
          )
        ) {
          this.$refs.mapCom.insertAtLayer(
            this.layerIndex.get("typhoonRoute"),
            this.$refs.mapCom.createLayer(
              typhoonData.rows.HistoryRoute[0].TYPHOON_ID + "typhoonRoute"
            )
          );
        }
        if (
          !this.$refs.mapCom.getLayer(
            typhoonData.rows.HistoryRoute[0].TYPHOON_ID + "typhoonCenter"
          )
        ) {
          this.$refs.mapCom.insertAtLayer(
            this.layerIndex.get("typhoonCenter"),
            this.$refs.mapCom.createLayer(
              typhoonData.rows.HistoryRoute[0].TYPHOON_ID + "typhoonCenter"
            )
          );
        }
        // //台风初始位置
        // this.map
        //   .getView()
        //   .setCenter([
        //     typhoonData.rows.HistoryRoute[0].JD,
        //     typhoonData.rows.HistoryRoute[0].WD,
        //   ]);
        // this.map.getView().setZoom(4);
        let interValTime = 100;
        if (typhoonData.rows.HistoryRoute.length <= 20) {
          interValTime = 200;
        }
        let count1 = 0; //共直线节点计数
        let count2 = 0; //非共直线节点计数
        let count3 = 0; //参与绘制的所有节点计数
        let interval = setInterval(() => {
          // intervalIndex++; //全部加载点位时执行
          const filterPoint = () => {
            const Angle = (startx, starty, endx, endy) => {
              var tan = 0;
              if (endx == startx) {
                tan = (Math.atan(0) * 180) / Math.PI;
              } else {
                tan =
                  (Math.atan(Math.abs((endy - starty) / (endx - startx))) *
                    180) /
                  Math.PI;
              }

              if (endx >= startx && endy >= starty) {
                //第一象限
                return -tan;
              } else if (endx > startx && endy < starty) {
                //第四象限
                return tan;
              } else if (endx < startx && endy > starty) {
                //第二象限
                return tan - 180;
              } else {
                return 180 - tan; //第三象限
              }
            };
            if (intervalIndex < 0) {
              //第一个点直接添加.索引值加1
              count2++;
              intervalIndex++;
            } else if (
              intervalIndex >= 0 &&
              intervalIndex < typhoonData.rows.HistoryRoute.length - 1
            ) {
              //第二个点开始判断是否共直线
              intervalIndex++;
              let lastLoc = "";
              // const preLoc = typhoonRouteArray[typhoonRouteArray.length - 1];
              const preLoc = [
                Number(typhoonData.rows.HistoryRoute[intervalIndex - 1].WD),
                Number(typhoonData.rows.HistoryRoute[intervalIndex - 1].JD),
              ];
              const curentLoc = [
                Number(typhoonData.rows.HistoryRoute[intervalIndex].WD),
                Number(typhoonData.rows.HistoryRoute[intervalIndex].JD),
              ];
              const angle1 = Angle(
                preLoc[1],
                preLoc[0],
                curentLoc[1],
                curentLoc[0]
              );
              let angle2 = "";
              //过滤掉共直线点位
              const getNode = (index) => {
                if (!typhoonData.rows.HistoryRoute[index + 1]) {
                  //不存在下一个点时
                  intervalIndex = index;
                } else {
                  lastLoc = [
                    Number(typhoonData.rows.HistoryRoute[index + 1].WD),
                    Number(typhoonData.rows.HistoryRoute[index + 1].JD),
                  ];
                  angle2 = Angle(preLoc[1], preLoc[0], lastLoc[1], lastLoc[0]);
                  if (angle1 === angle2) {
                    count1++;
                    getNode(++index);
                  } else {
                    count2++;
                    intervalIndex = index;
                  }
                }
              };
              getNode(intervalIndex);
              // console.log(intervalIndex);
            } else if (
              intervalIndex >=
              typhoonData.rows.HistoryRoute.length - 1
            ) {
              count2++;
            }
          };
          //过滤点位时执行-----------------------------------------------------------
          filterPoint();
          if (intervalIndex <= typhoonData.rows.HistoryRoute.length - 1) {
            count3++; //过滤点位时执行
            let historyRoute = typhoonData.rows.HistoryRoute[intervalIndex];
            let data = {
              name: historyRoute.CHN_NAME,
              engName: historyRoute.ENG_NAME,
              time: historyRoute.RQSJ,
              PA: historyRoute.CONTER_PA,
              direction: historyRoute.MOVE_DIRECT,
              sevenLevel:
                (
                  historyRoute.RADIUS7_QUAD_NE +
                  "   " +
                  historyRoute.RADIUS7_QUAD_SE +
                  "   " +
                  historyRoute.RADIUS7_QUAD_SW +
                  "   " +
                  historyRoute.RADIUS7_QUAD_NW
                ).replace(/null+/g, "-") + "(KM)",
              tenLevel:
                (
                  historyRoute.RADIUS10_QUAD_NE +
                  "   " +
                  historyRoute.RADIUS10_QUAD_SE +
                  "   " +
                  historyRoute.RADIUS10_QUAD_SW +
                  "   " +
                  historyRoute.RADIUS10_QUAD_NW
                ).replace(/null+/g, "-") + "(KM)",
              twelveLevel:
                (
                  historyRoute.RADIUS12_QUAD_NE +
                  "   " +
                  historyRoute.RADIUS12_QUAD_SE +
                  "   " +
                  historyRoute.RADIUS12_QUAD_SW +
                  "   " +
                  historyRoute.RADIUS12_QUAD_NW
                ).replace(/null+/g, "-") + "(KM)",
              centerX: parseFloat(historyRoute.JD),
              centerY: parseFloat(historyRoute.WD),
              centerRadius: 3.5,
              level: parseInt(historyRoute.POWER),
              speed: parseInt(historyRoute.CENTER_WIND),
              moveSpeed: parseInt(historyRoute.MOVE_SPEED),
              moveDirect: historyRoute.MOVE_DIRECT,
              TYPHOON_ID: historyRoute.TYPHOON_ID,
              radius7: {
                SE: parseFloat(historyRoute.RADIUS7_QUAD_SE) / 100, //千米
                NE: parseFloat(historyRoute.RADIUS7_QUAD_NE) / 100,
                NW: parseFloat(historyRoute.RADIUS7_QUAD_NW) / 100,
                SW: parseFloat(historyRoute.RADIUS7_QUAD_SW) / 100,
              },
              radius10: {
                SE: parseFloat(historyRoute.RADIUS10_QUAD_SE) / 100, //千米
                NE: parseFloat(historyRoute.RADIUS10_QUAD_NE) / 100,
                NW: parseFloat(historyRoute.RADIUS10_QUAD_NW) / 100,
                SW: parseFloat(historyRoute.RADIUS10_QUAD_SW) / 100,
              },
              radius12: {
                SE: parseFloat(historyRoute.RADIUS12_QUAD_SE) / 100, //千米
                NE: parseFloat(historyRoute.RADIUS12_QUAD_NE) / 100,
                NW: parseFloat(historyRoute.RADIUS12_QUAD_NW) / 100,
                SW: parseFloat(historyRoute.RADIUS12_QUAD_SW) / 100,
              },
            };
            //历史轨迹
            this.typhoonCircle(data);
            this.typhoonRoute(
              data,
              typhoonCenterArray,
              typhoonRouteArray,
              typhoonData.rows.HistoryRoute,
              () => {
                if (
                  intervalIndex ===
                  typhoonData.rows.HistoryRoute.length - 1
                ) {
                  intervalIndex++; //过滤点位时执行
                  this.addTyphoonFlashGiff(data, typhoonData.rows.HistoryRoute);
                }
              }
            );
          } else {
            console.log(count1);
            console.log(count2);
            console.log(count3);
            let index = this.indexof(
              this.typhoonInterval,
              typhoonData.rows.HistoryRoute[intervalIndex - 1].TYPHOON_ID
            );
            if (index > -1) {
              //清空当前轨迹的定时器
              clearInterval(this.typhoonInterval[index].interval);
              this.typhoonInterval.splice(index, 1);
              //预测轨迹
              this.forecastRoute(
                typhoonData.rows.ForecastRoute,
                typhoonData.rows.HistoryRoute[intervalIndex - 1].TYPHOON_ID
              );
            }
          }
        }, interValTime);
        this.typhoonInterval.push({
          id: typhoonData.rows.HistoryRoute[0].TYPHOON_ID,
          interval: interval,
        });
      };
      this.$refs.mapCom.fitExtent(coords, () => {
        fun();
      });
    },
    indexof(data, id) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) return i;
      }
      return -1;
    },
    /**
     * 清空选中的台风
     */
    clearTyphoon(id) {
      this.hidding.style.display = "none";
      if (!id) return;
      //清台风路径定时器
      let intervalIndex = this.indexof(this.typhoonInterval, id);
      if (intervalIndex > -1) {
        clearInterval(this.typhoonInterval[intervalIndex].interval);
        this.typhoonInterval.splice(intervalIndex, 1);
      }
      //清空台风转动图标定时器
      let rotationIntervalIndex = this.indexof(
        this.typhoonRotationInterval,
        id
      );
      if (rotationIntervalIndex > -1) {
        this.map.un(
          "postcompose",
          this.typhoonRotationInterval[rotationIntervalIndex].interval
        );
        this.typhoonRotationInterval.splice(rotationIntervalIndex, 1);
      }
      //清空图层
      this.$refs.mapCom.removeLayer(id + "typhoonCircle");
      this.$refs.mapCom.removeLayer(id + "typhoonEnd");
      this.$refs.mapCom.removeLayer(id + "typhoonRoute");
      this.$refs.mapCom.removeLayer(id + "typhoonCenter");
      this.$refs.mapCom.removeLayer(id + "typhoonForecastRoute");
      this.$refs.mapCom.removeLayer(id + "typhoonForecastCenter");
    },
    /**
     * 清空所以有台风图层
     */
    clearAllTyphoon() {
      this.hidding.style.display = "none";
      //清台风路径定时器
      for (let i = this.typhoonInterval.length - 1; i >= 0; i--) {
        clearInterval(this.typhoonInterval[i].interval);
        this.typhoonInterval.splice(i, 1);
      }
      //清空台风转动图标定时器
      for (let i = this.typhoonRotationInterval.length - 1; i >= 0; i--) {
        this.map.un("postcompose", this.typhoonRotationInterval[i].interval);
        this.typhoonRotationInterval.splice(i, 1);
      }
      //清空图层
      let array = this.map.getLayers().array_.map((v) => {
        return v;
      });
      array.forEach((layer) => {
        if (
          layer.get("id") &&
          (layer.get("id").indexOf("typhoonCircle") > -1 ||
            layer.get("id").indexOf("typhoonEnd") > -1 ||
            layer.get("id").indexOf("typhoonRoute") > -1 ||
            layer.get("id").indexOf("typhoonCenter") > -1 ||
            layer.get("id").indexOf("typhoonForecastRoute") > -1 ||
            layer.get("id").indexOf("typhoonForecastCenter") > -1)
        ) {
          this.map.removeLayer(layer);
        }
      });
    },
    /**
     * 添加台风旋转图标
     */
    addTyphoonFlashGiff(data, historyRoute) {
      let typhoonEndFeature = new this.mapApi.Feature({
        geometry: new this.mapApi.Point([data.centerX, data.centerY]),
      });
      typhoonEndFeature.setStyle(
        new this.mapApi.Style({
          image: new this.mapApi.Icon({
            src: require("@/assets/image/map/typhoon/Typhoon.gif"),
            scale: 2,
            rotation: 0,
          }),
        })
      );
      this.$refs.mapCom
        .getLayer(historyRoute[0].TYPHOON_ID + "typhoonEnd")
        .getSource()
        .addFeature(typhoonEndFeature);
      let m = 0;
      //开始地图渲染
      let moveFeature = () => {
        m++;
        // m = m++ >= 360 ? 0 : m;
        typhoonEndFeature.getStyle().setImage(
          new this.mapApi.Icon({
            src: require("@/assets/image/map/typhoon/Typhoon.gif"),
            scale: 1,
            rotation: -m * 0.1,
          })
        );
        this.map.render();
        this.$refs.mapCom
          .getLayer(historyRoute[0].TYPHOON_ID + "typhoonEnd")
          .getSource()
          .changed();
      };
      this.map.on("postcompose", moveFeature);
      this.typhoonRotationInterval.push({
        id: data.TYPHOON_ID,
        interval: moveFeature,
      });
      this.map.render();
    },
    /**
     * 设置轨迹点颜色
     */
    getImageColor(speed) {
      let imageColor;
      if (speed >= 10.8 && speed <= 17.15) {
        imageColor = "rgba(23, 253, 25,1)";
      } else if (speed >= 17.2 && speed <= 24.4) {
        imageColor = "rgba(16, 109, 254,1)";
      } else if (speed >= 24.5 && speed <= 32.6) {
        imageColor = "rgba(244, 245, 26,1)";
      } else if (speed >= 32.7 && speed <= 41.4) {
        imageColor = "rgba(244, 175, 28,1)";
      } else if (speed >= 41.5 && speed <= 50.9) {
        imageColor = "rgba(232, 123, 247,1)";
      } else if (speed >= 51 && speed <= 161.2) {
        imageColor = "rgba(244, 20, 27,1)";
      } else {
        imageColor = "rgba(255, 255, 255,1)";
      }
      return imageColor;
    },
  },
  beforeDestroy() {
    this.clearAllTyphoon();
  },
};
</script>
<style>
#typhoonEndGif {
  width: auto;
  height: auto;
}
#typhoonEndGif img {
  width: 50px;
  height: 50px;
}
</style>
